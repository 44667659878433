import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Summary from "../components/Home/Models/Summary";
import { getCountryListAPI } from "../services/commonServices";

const SummaryContainer = () => {
  const [countryList, setCountryList] = useState([]);

  const navigate = useNavigate();
  const goBack = () => {
    navigate({
      pathname: "/",
    });
  };

  useEffect(() => {
    try {
      getCountryListAPI().then((res) => {
        if (res?.status === 200) {
          setCountryList(res.data);
        }
      });
    } catch (error) {}
  }, []);

  return (
    <>
      <section>
        <div className="container-fluid">
          <button
            type="button"
            className="btn-close trade_table_close"
            onClick={goBack}
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            ×
          </button>
          <div className="row">
            <div className="col-lg-12 ">
              <Summary countryList={countryList} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SummaryContainer;
