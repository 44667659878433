import React from "react";

const SmallColumn = ({
  showPortfolioName,
  currencyName,
  portfolioCurrency,
  countHold,
  portfolioCreatedDate,
  closeCountHold,
  mainTab,
}) => {
  return (
    <div
      className="summary-header-h"
      style={{
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid #cbd0dd",
        borderRadius: "10px",
        padding: "15px 20px",
        width: "100%",
        marginTop: "10px",
        // position: "absolute",
        // top: "50%",
        //zIndex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "40%",
        }}
      >
        <h6
          style={{
            color: "grey",
            fontSize: "15px",
            marginBottom: "0px",
          }}
        >
          Porfolio
        </h6>
        <h5 style={{ color: "#000" }}>{showPortfolioName}</h5>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "40%",
        }}
      >
        <h6
          style={{
            color: "grey",
            fontSize: "15px",
            marginBottom: "0px",
          }}
        >
          Currency
        </h6>
        <h5 style={{ color: "#000" }}>
          {portfolioCurrency}({currencyName})
        </h5>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "40%",
        }}
      >
        <h6
          style={{
            color: "grey",
            fontSize: "15px",
            marginBottom: "0px",
          }}
        >
          No. of Stocks
          <span
            style={{
              fontSize: "12px",
            }}
          >
            ({mainTab === "open" ? "open" : "close"})
          </span>
        </h6>
        <h5 style={{ color: "#000" }}>
          {mainTab === "open" ? countHold || 0 : closeCountHold || 0}
        </h5>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "25%",
        }}
      >
        <h6
          style={{
            color: "grey",
            fontSize: "15px",
            marginBottom: "0px",
          }}
        >
          Created
        </h6>
        <h5 style={{ color: "#000" }}>{portfolioCreatedDate}</h5>
      </div>
    </div>
  );
};

export default SmallColumn;
