// import React, { useEffect } from "react";
// import AnyChart from "anychart-react";

// const BarChart = ({ data, currency, id }) => {
//   console.log("ch data", data);
//   const chartdata = data.map((item, index) => {
//     const color =
//       Math.abs(item[1]) >= 0.0000001
//         ? item[1] > 0
//           ? "#ff8038" // Color for positive values
//           : "#243a69" // Color for negative values
//         : "#ff8038"; // Default color for values close to zero

//     return {
//       x: `${item[0]}-${index + 1}`,
//       stockName: `${item[0]}`,
//       value: item[1],
//       fill: color,
//     };
//   });

//   const chartSettings = {
//     type: "bar", // Specify bar chart type
//     data: chartdata, // The data passed to the component
//     height: "500px", // Set chart height
//     width: "100%", // Chart width (optional)
//     barWidth: "100px",

//     labels: {
//       position: "center", // Position the labels inside the bars (centered)
//       format: "{%stockName}", // Display the value inside the bar
//       fontColor: "#fff", // Font color for the labels
//       fontSize: 12, // Font size for the labels
      
//     },
//     // Customize other chart settings like axes
//     xAxis: {
//       title: "Stock",
//       width: "5%", // Title for the X axis
//       labels: {
//         fontSize: 10, // Font size for X axis labels
//         fontColor: "#fff", // Font color for X axis labels
//       },
//     },
//     yAxis: {
//       title: "Profit/Loss",
//       width: "20%", // Title for the Y axis
//       labels: {
//         fontSize: 10, // Font size for Y axis labels
//         fontColor: "#fff", // Font color for Y axis labels
//       },
//     },
//     tooltip: {
//       format: `Stockname: {%stockName}\nProfit/Loss: ${currency}{%value}`, // Format for the tooltip
//     },
//     series: [
//       {
//         data: chartdata,
//         // Use the chartdata directly
//         fill: function () {
//           return this.fill || "#ff8038"; // Use the fill property from chartdata for each bar
//         },
//       },
//     ],
//     barGroupPadding: 0.2, // Reduce space between groups of bars (range: 0-1)
//     barPadding: 0.5,
//   };

//   return (
//     <div>
//       <AnyChart id={id} {...chartSettings} />
//     </div>
//   );
// };

// export default BarChart;

import React, { useEffect } from "react";
import AnyChart from "anychart-react";

const BarChart = ({ data, currency, id }) => {
  console.log("ch data", data);

  const chartdata = data.map((item, index) => {
    const color =
      Math.abs(item[1]) >= 0.0000001
        ? item[1] > 0
          ? "#ff8038" // Color for positive values
          : "#243a69" // Color for negative values
        : "#ff8038"; // Default color for values close to zero

    return {
      x: `${item[0]}-${index + 1}`,
      stockName: `${item[0]}`,
      value: item[1],
      fill: color,
    };
  });

const chartSettings = {
  type: "column", // Change to column chart type for vertical bars
  data: chartdata, // The data passed to the component
  height: "500px", // Set chart height
  width: "100%", // Chart width (optional)
  barWidth: "100px", // Adjust bar width

  title: {
    text: "Company Profit Dynamic in Regions by Year", // Chart title
    fontSize: 16, // Title font size
    fontColor: "#fff", // Title font color
  },

  labels: {
    position: "outside", // Position the labels outside (above) the bars
    fontColor: "#000", // Font color for the labels
    fontSize: 12, // Font size for the labels
    anchor: "middle", // Center the label horizontally over the bar
    offsetX: 0, // Align the label in the center horizontally
    offsetY: -3, // Position the label above the bar (negative value to move it upwards)
    format: `{%stockName}\n${currency}{%Value}{numDecimals:2}`,
  },

  xAxis: {
    title: "Stock",
    labels: {
      fontSize: 10, // Font size for X axis labels
      fontColor: "#000", // Font color for X axis labels
    },
  },

  yAxis: {
    title: "Profit/Loss",
    labels: {
      fontSize: 10, // Font size for Y axis labels
      fontColor: "#000", // Font color for Y axis labels
      format: currency+"{%Value}", // Format the Y axis labels with 'k'
    },
    // Add a reference line (a line at y=0) to separate positive and negative values
    axisCrosses: "auto",
    referenceLines: [
      {
        value: 0, // Line at 0 to separate positive and negative
        stroke: {
          color: "#000", // Color of the line
          thickness: 2, // Line thickness
          dash: "solid", // Line style (solid, dashed, etc.)
        },
        label: {
          text: "0", // Optional label for the line
          fontSize: 12,
          fontColor: "#000",
        },
      },
    ],
  },

  tooltip: {
    format: `Stockname: {%stockName}\nProfit/Loss: ${currency}{%value}`, // Format for the tooltip
  },

  series: [
    {
      data: chartdata,
      fill: function () {
        return this.fill || "#ff8038"; // Use the fill property from chartdata for each bar
      },
    },
  ],

  legend: {
    enabled: false,
    fontSize: 13,
    padding: [0, 0, 20, 0],
  },

  xGrid: {
    stroke: "#ddd",
    drawLastLine: false,
  },

  animation: {
    enabled: true, // Enable animation
  },

  barGroupPadding: 0.2, // Reduce space between groups of bars (range: 0-1)
  barPadding: 0.5, // Padding between individual bars
};

  return (
    <div>
      <AnyChart id={id} {...chartSettings} />
    </div>
  );
};

export default BarChart;

