import React, { useEffect, useState } from "react";
import { getWatchList, removeWatchList } from "../../../services/TradeService";
import moment from "moment/moment";
import TradeModel from "../../Trade/Model/TradeModel";
import {
  deletePortfoloio,
  getPortfoloio,
} from "../../../services/PortfolioService";
import CreatePortfolio from "./CreatePortfolio";
import DeleteConfirmation from "../../commonComponent/DeleteConfirmation";
import { deleteStrategy } from "../../../services/StrategyService";

const PortFolio = ({
  runAgainDashBoard,
  setRunAgainDashBoard,
  setRunPortFolioAPI,
  providerList,
  userType,
}) => {
  const [portfolioList, setPortfolioList] = useState([]);
  const [watchListRun, setWatchListRun] = useState(true);
  const [portFolioData, setPortFolioData] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState("");
  const [deleteConfirmPort, setDeleteConfirmPort] = useState("");
  const getPortFolioList = () => {
    if (userType.userId !== undefined && userType?.userId.length > 0) {
      try {
        getPortfoloio(userType.userId).then((res) => {
          setWatchListRun(false);
          if (res?.status === 200) {
            setPortfolioList(res.data);
          }
        });
      } catch (error) {}
    }
  };

  useEffect(() => {}, [deleteConfirmPort]);
  useEffect(() => {
    getPortFolioList();
  }, [watchListRun == true && watchListRun, userType]);
  const handleEdit = (val) => {
    setPortFolioData(val);
    setShowEdit(true);
  };
  const handleShowCreatePortFolio = () => {
    setShowEdit(false);
    setPortFolioData("");
  };
  const deletePortFolio = async () => {
    try {
      await deletePortfoloio(deleteConfirmPort).then((res) => {
        if (res?.status === 200) {
          getPortFolioList();
          setDeleteConfirmPort("");
        }
      });
    } catch (error) {}
  };
  const deleteConfirm = async () => {
    debugger
    if (deleteConfirmPort !== "" && deleteConfirmPort !== undefined) {
     await deletePortFolio();
    }
  };
  const handleDeletePortfolio = (val) => {
    setDeleteMsg("delete this Portfolio");
    console.log("dfsf", val)
    setDeleteConfirmPort(val);
  };
  const deleteModel = document.getElementById("deleteModel");
  const marketsOverview = document.getElementById("MarketsOverview");

  // Ensure both elements exist
  if (deleteModel && marketsOverview) {
    // Get the parent of MarketsOverview and insert deleteModel before it
    marketsOverview.parentNode.insertBefore(deleteModel, marketsOverview);
  }
  return (
    <>
      <div
        className="modal fade show backdrop-1"
        data-bs-backdrop="static"
        id="portfolioTable"
        tabindex="-1"
        aria-labelledby="exampleModalLgLabel"
        style={{ display: "none" }}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-lg  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-0 border-bottom-0">
              <h5 className="modal-title h4" id="exampleModalLgLabel">
                Portfolio
              </h5>
              <button
                type="button"
                className="btn-close btn-close-provider"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                ×
              </button>
            </div>
            <div className="col-lg-4 ms-auto ">
              <button
                className="btn bt-light fullc-or portButton  ms-auto d-block"
                onClick={handleShowCreatePortFolio}
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#portfolio"
              >
                {" "}
                <span>
                  {" "}
                  <i className="bi bi-plus-lg"></i>{" "}
                </span>{" "}
                Create Portfolio{" "}
              </button>
            </div>
            <div className="modal-body blackout-bx">
              <div class="table-responsive">
                <table class="table table-input1 table-bordered mt-3 text-center">
                  <thead>
                    <tr>
                      <th scope="col" className="color-grey">
                        Date
                      </th>
                      <th scope="col" className="color-grey">
                        Portfolio Name
                      </th>
                      <th scope="col" className="color-grey">
                        Country
                      </th>
                      <th scope="col" className="color-grey">
                        Currency
                      </th>
                      <th scope="col" className="color-grey">
                        Edit
                      </th>
                      <th scope="col" className="color-grey">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {portfolioList.map((val, ind) => {
                      return (
                        <tr key={ind} className="white_text">
                          <th scope="row">
                            {moment(val.createdDate).format("DD MMM YYYY")}{" "}
                          </th>
                          <td>{val.portfolioName}</td>
                          {/* <td>{val.provider.providerName}</td> */}
                          <td>{val.country}</td>
                          <td>{val.currency}</td>
                          {/* <td><button class="btn padding-btn-dtd open-btn w-100" title='Open' data-bs-toggle="modal" data-bs-target="#StockView" onClick={() => setTradeCode(val.stockCode)}> <img src="assets/image/folder.png" class="img-fluid" alt='' /> </button> </td> */}
                          <td>
                            <button
                              class="btn padding-btn-dtd delete-btn w-100"
                              data-bs-toggle="modal"
                              data-bs-target="#portfolio"
                              title="Edit"
                              onClick={() => handleEdit(val)}
                            >
                              {" "}
                              <img
                                src="assets/image/edit1.png"
                                class="img-fluid"
                                alt=""
                              />{" "}
                            </button>{" "}
                          </td>
                          <td>
                            <button
                              class="btn padding-btn-dtd delete-admin"
                              title="Delete"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteModel"
                              onClick={() => handleDeletePortfolio(val)}
                            >
                              {" "}
                              <i class="bi bi-trash3-fill"></i>{" "}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmation type={deleteMsg} deleteConfirm={deleteConfirm} />
      <CreatePortfolio
        runAgainDashBoard={runAgainDashBoard}
        setRunAgainDashBoard={setRunAgainDashBoard}
        userType={userType}
        providerList={providerList}
        setRunPortFolioAPI={setRunPortFolioAPI}
        setWatchListRun={setWatchListRun}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        portFolioData={portFolioData}
      />
    </>
  );
};

export default PortFolio;
