import React, { useState, useEffect } from 'react'
import moment from 'moment/moment'
import Pagination from '../commonComponent/Pagination'
import { getPortfolioReturn, getPortfolioStats, getTransactionListFinancialAPI } from '../../services/ManageAccountServices'
import { getSearchStockDetails } from '../../services/HomeService'
import {
  AddWatchList,
  GetInvestmentReturnMonthly,
  GetInvestmentReturnWeekly,
  getGainerLooser,
  getInflationRate,
  getOpentradeList,
  getOpentradeListFinancelYear,
  getWatchList,
  removeWatchList,
} from "../../services/TradeService";
import { BiUpArrow, BiDownArrow } from 'react-icons/bi';
import TradeModel from '../Trade/Model/TradeModel'
import { BiChevronUp } from "react-icons/bi";
import { AiOutlineGlobal } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { FaChartArea } from "react-icons/fa";
import { HiPresentationChartLine } from "react-icons/hi";
import { BsFillPieChartFill } from "react-icons/bs";
import { GiCutDiamond } from "react-icons/gi";
import { DiGoogleAnalytics } from "react-icons/di";
import { Link, useNavigate } from 'react-router-dom'
import { GetCurrentBalabce } from '../../services/ProviderServices'
import CountryCurrencyList from '../commonComponent/CountryCurrencyList'
import { getExchangeRateAPI } from '../../services/commonServices'

const PortfolioTable = ({ countryList, portfololioList, runbalanceTranAPI, runAgainDashBoard, setGetOpenToggleId, userType, setOpenTradeRun, selectedUserData }) => {
    const [pagination, setPagination] = useState({ currentPage: 1, pageNumber: 1, pageSize: 10, totalPages: 0 })
    const { pageNumber, pageSize, currentPage, totalPages } = pagination // Total number of pages in your data set
    const [transactionList, setTransactionList] = useState([])
    const [openTradeList, setOpenTradeList] = useState([])
    const [paginationTrade, setPaginationTrade] = useState({ currentPageTrade: 1, pageNumberTrade: 1, pageSizeTrade: 10, totalPagesTrade: 0 })
    const { currentPageTrade, pageNumberTrade, pageSizeTrade, totalPagesTrade } = paginationTrade
    const [showHideTran, setShowHideTran] = useState(false)
    const [currencySymbol, setCurrencySymbol] = useState({ symbol: '', code: '' })
    const [gainerList, setGainerList] = useState([])
    const [looserList, setLooserList] = useState([])
    const [watchList, setWatchList] = useState([])
    const [tradeCode, setTradeCode] = useState('')
    const [startingBalance, setstartingBalance] = useState([])
    const [currentBalance, setCurrentBalance] = useState([])
    const [sharperatio, setSharpeRatio] = useState(0)
    const [tradereturnvalue, setTradeReturnValue] = useState({
        initalvalue: 0,
        currentvalue: 0
    })
    const [portfolioreturn, setPortfolioReturn] = useState('')
    const [inflationrate, setInflationRate] = useState('')
    const [standardDeviation, setStandardDeviation] = useState('')
    const [balanceStats, setBalanceStats] = useState({
        totalDeposit: 0,
        totalWithdrawal: 0
    })
    const { totalDeposit, totalWithdrawal } = balanceStats
    const [deposit, setDeposit] = useState([])
    const [withdrawl, setWithdrawl] = useState([]);
    const navigate = useNavigate()
// console.log(selectedUserData)
    const handlePageChange = (newPage) => {
        setPagination({ ...pagination, currentPage: currentPage + newPage })
        // Perform data fetching or other actions when page changes
    };
    const handlePageChangeTrade = (newPage) => {
        setPaginationTrade({ ...paginationTrade, currentPageTrade: currentPageTrade + newPage })
        // Perform data fetching or other actions when page changes
    };
    let symbol;// localStorage.getItem('currencySymbol');
    
    if (localStorage.getItem('currencySymbol')) {
        symbol = JSON.parse(localStorage.getItem('currencySymbol'))
        //setCurrencySymbol(symbol)
    }
    useEffect(() => {

        setCurrencySymbol(symbol)
    }, [symbol?.code])
    useEffect(() => {
        let nm = document.getElementById('open-Trade-click')

        setGetOpenToggleId(nm)
    }, [])
    useEffect(() => {
        let data = {
            // providerId: selectedUserData.providerId, 
            portfolioId: selectedUserData.portFolioId,
            id: userType?.userId,
            pageNumber: currentPageTrade,
            pageSize: pageSizeTrade,
            dateFrom: selectedUserData.dateFrom,
            dateTo: selectedUserData.dateTo
        }
        if (data.id !== undefined && data.portfolioId !== undefined) {
            try {
                getOpentradeListFinancelYear(data).then((res) => {
                    if (res?.status === 200) {

                        setOpenTradeList(res.data.data)
                        setPaginationTrade({ ...paginationTrade, totalPagesTrade: res.data.totalPages })
                        let data = res.data
                        // let pft = 0
                        // let bs = 0
                        // let bcfd = 0, lev 

                        // lev = (bcfd / bs) * 100
                        // setShowData({ ...showData, profit: pft.toFixed(2), balanceinShares: bs, balanceinCFD: bcfd, leverage: lev })


                    } else {
                        // setShowData({ ...showData, balanceinShares: 0, balanceinCFD: 0 })
                    }
                })
            } catch (error) {

            }
        }

    }, [currentPageTrade, runAgainDashBoard, userType, selectedUserData.providerId, runAgainDashBoard, selectedUserData])

    useEffect(() => {
        let paramData = {
            PortfolioId: selectedUserData.portFolioId,
            id: userType?.userId,
            pageNumber: currentPage,
            pageSize: pageSize,
            dateFrom: selectedUserData.dateFrom,
            dateTo: selectedUserData.dateTo
        }
        if (paramData.id !== undefined && paramData.PortfolioId !== undefined) {
            try {
                getTransactionListFinancialAPI(paramData).then((res) => {
                    if (res?.status === 200) {

                        setTransactionList(res.data.data)
                        setPagination({ ...pagination, totalPages: res.data.totalPages })
                    }
                })
            } catch (error) {

            }
        }


    }, [currentPage, userType, runbalanceTranAPI, selectedUserData.portFolioId, runAgainDashBoard, selectedUserData])
    useEffect(() => {
        try {
            getGainerLooser().then((res) => {
                if (res?.status === 200) {
                    setLooserList(res?.data?.top_losers || [])
                    setGainerList(res?.data?.top_gainers || [])
                }
            })
        } catch (error) {

        }
    }, [])
    let width = window.innerWidth;
    const handleNavigaterecords = () => {
        navigate('/Records', {
            state: {
                userType,
                selectedUserData,
                currencySymbol,
                pageSize,
                currentPage,


            },
        });
    };
    const getcurrentBalance = () => {
        let cData = {
            portfolioId: selectedUserData.portFolioId,
            userId: userType?.userId
        }
        if (cData.userId !== undefined && cData.portfolioId !== undefined) {
           
            try {
                GetCurrentBalabce(cData).then((res) => {
                    if (res?.status === 200) {
                        setCurrentBalance(res.data.currentBalance)
                    }
                })
            } catch (error) {

            }
        }
    }

    // const PortfolioReturnA = () => {

    //     let cData = {
    //         PortfolioId: selectedUserData.portFolioId,
    //         UserId: userType?.userId,
    //     }

    //     if (cData.UserId !== undefined && cData.PortfolioId !== undefined) {
    //         try {
    //             getPortfolioReturn(cData).then((res) => {
    //                 if (res?.status === 200) {
    //                     // setBrokerData(res.data)
    //                     setstartingBalance(res.data.startingBalance)
    //                 }
    //             })
    //         } catch (error) {

    //         }

    //         try {
    //             getPortfolioStats(cData).then((res) => {
    //                 if (res?.status === 200) {
    //                     setBalanceStats(res.data)
    //                 }
    //             })
    //         } catch (error) {

    //         }
    //     }
    // }

    // useEffect(() => {

    //     PortfolioReturnA()
    // }, [selectedUserData.portFolioId, userType])
    useEffect(() => {
        // getopentrade()
        getcurrentBalance()
        // getInflation()
    }, [selectedUserData.portFolioId, userType])


     useEffect(() => {
       depositwithdrawl();
     }, [selectedUserData.portFolioId, userType]);

 const depositwithdrawl = async () => {
    debugger
   let cData = {
     PortfolioId: selectedUserData.portFolioId,
     UserId: userType?.userId,
   };

   if (cData.UserId !== undefined && cData.PortfolioId !== undefined) {
     try {
       console.log("cData", cData);
       await getPortfolioStats(cData).then((res) => {
         if (res?.status === 200) {
           setDeposit(res.data.totalDeposit);
           setWithdrawl(res.data.totalWithdrawal);
         }
       });
     } catch (error) {}
   } else {
     setDeposit(0);
     setWithdrawl(0);
   }
 };
    
    // const getInflation = () => {
    //     try {
    //         getInflationRate().then((res) => {
    //             if (res?.status === 200) {
    //                 setInflationRate(res.data.data[0].value)
    //                 // setBrokerData(res.data)
    //                 // setstartingBalance(res.data.startingBalance)

    //             }
    //         })
    //     } catch (error) {

    //     }
    // }


    // let Returnvalue = {
    //     initalvalue: 0,
    //     currentvalue: 0
    // }
    // const addProfitLoass = (tradeListData, getopendate) => {
    //     let n = 0
    //     let pdata = []
    //     let stockNamesArray = []
    //     // e.stockCode
    //     if (tradeListData != undefined && tradeListData.length > 0) {
    //         for (let i = 0; i < tradeListData.length; i++) {
    //             if (tradeListData[i].short !== '') {
    //                 n = n + 1
    //                 let selectedStockCountry = countryList.filter((val) => val.key.toUpperCase() == tradeListData[i].country.toUpperCase())
    //                 stockNamesArray.push(tradeListData[i].stockCode)
    //                 // let StockeCUrrData = CountryCurrencyList.filter((value) => value.code == stockCurrency[1].country)
    //                 let stockPortCountry = portfololioList.filter((val) => val.portfolioId == tradeListData[i].portfolioId)
    //                 let stockCurrency = tradeListData[i].stockCode.split('/')
    //                 let symboleData = CountryCurrencyList.filter((value) => value.code == stockCurrency[1])

    //                 try {
    //                     getSearchStockDetails(tradeListData[i].stockCode).then((respons) => {

    //                         if (selectedStockCountry[0]?.value == stockPortCountry[0]?.currency) {
    //                             const exPrice = 1


    //                             let aus = tradeListData[i].stockCode.includes(".AX")
    //                             if (aus) {
    //                                 const price = respons.data["close"];
    //                                 let openTradeListing = tradeListData[i]
    //                                 // debugger
    //                                 let plValue = (tradeListData[i]?.valueInShares - ((price * exPrice) * tradeListData[i]?.quantity || 0))
    //                                 pdata.push(tradeListData[i]?.valueInShares)
    //                                 Returnvalue = {
    //                                     initalvalue: Number(tradeListData[i]?.valueInShares) + Number(Returnvalue.initalvalue),
    //                                     currentvalue: Number(tradeListData[i]?.valueInShares) + Number(Returnvalue.currentvalue) + Number(plValue)
    //                                 }
                                    
    //                                 // openTradeListing.profitLoss = Number(plValue).toFixed(3)
    //                                 // openTradeListing.stockCountrySymbol = symboleData[0].symbol_native
    //                                 // openTradeListing.portfoliocurrencycode = stockPortCountry[0].currencySymbol
    //                                 // openTradeListing.stockName = tradeListData[i].stockCode
    //                                 // openTradeListing.cPrice = (bidPrice)
    //                                 // openTradeListing.entryPrice = (tradeListData[i].entryPrice)
    //                                 // pdata.push({ ...openTradeListing })
    //                             } else {
    //                                 const price = respons.data["Global Quote"]["05. price"];
    //                                 pdata.push(tradeListData[i]?.valueInShares)
    //                                 let openTradeListing = tradeListData[i]
    //                                 let plValue = (((price * exPrice) * tradeListData[i]?.quantity || 0) - tradeListData[i]?.valueInShares)
    //                                 Returnvalue = {
    //                                     initalvalue: Number(tradeListData[i]?.valueInShares) + Number(Returnvalue.initalvalue),
    //                                     currentvalue: Number(tradeListData[i]?.valueInShares) + Number(Returnvalue.currentvalue) + Number(plValue)
    //                                 }

    //                                 // openTradeListing.profitLoss = Number(plValue).toFixed(3)
    //                                 // openTradeListing.stockCountrySymbol = symboleData[0].symbol_native
    //                                 // openTradeListing.portfoliocurrencycode = stockPortCountry[0].currencySymbol
    //                                 // openTradeListing.stockName = tradeListData[i].stockCode
    //                                 // openTradeListing.cPrice = (askPrice)
    //                                 // openTradeListing.entryPrice = (tradeListData[i].entryPrice)
    //                                 // pdata.push({ ...openTradeListing })
    //                             }
    //                             console.log(Returnvalue)
    //                             if (pdata.length == tradeListData.length) {

    //                                 InvestmentReturn(stockNamesArray, getopendate, Returnvalue, pdata)
    //                             }
    //                             setTradeReturnValue({ ...Returnvalue })

    //                             // pdata.sort((date1, date2) => date2.transactionNo - date1.transactionNo);

    //                         } else {
    //                             try {
    //                                 getExchangeRateAPI(selectedStockCountry[0]?.value, stockPortCountry[0]?.currency).then((res) => {
    //                                     const exPrice = res?.data["Realtime Currency Exchange Rate"]?.["5. Exchange Rate"];

    //                                     if (exPrice != undefined) {
    //                                         let aus = tradeListData[i].stockCode.includes(".AX")
    //                                         if (aus) {
    //                                             const price = respons.data["close"];
    //                                             let openTradeListing = tradeListData[i]
    //                                             // debugger
    //                                             let plValue = (tradeListData[i]?.valueInShares - ((price * exPrice) * tradeListData[i]?.quantity || 0))
    //                                             pdata.push(tradeListData[i]?.valueInShares)
    //                                             Returnvalue = {
    //                                                 initalvalue: Number(tradeListData[i]?.valueInShares) + Number(Returnvalue.initalvalue),
    //                                                 currentvalue: Number(tradeListData[i]?.valueInShares) + Number(Returnvalue.currentvalue) + Number(plValue)
    //                                             }
    //                                             // openTradeListing.profitLoss = Number(plValue).toFixed(3)
    //                                             // openTradeListing.stockCountrySymbol = symboleData[0].symbol_native
    //                                             // openTradeListing.portfoliocurrencycode = stockPortCountry[0].currencySymbol
    //                                             // openTradeListing.stockName = tradeListData[i].stockCode
    //                                             // openTradeListing.cPrice = (bidPrice)
    //                                             // openTradeListing.entryPrice = (tradeListData[i].entryPrice)
    //                                             // pdata.push({ ...openTradeListing })
    //                                         } else {
    //                                             const price = respons.data["Global Quote"]["05. price"];
    //                                             pdata.push(tradeListData[i]?.valueInShares)
    //                                             let openTradeListing = tradeListData[i]
    //                                             let plValue = (((price * exPrice) * tradeListData[i]?.quantity || 0) - tradeListData[i]?.valueInShares)
    //                                             Returnvalue = {
    //                                                 initalvalue: Number(tradeListData[i]?.valueInShares) + Number(Returnvalue.initalvalue),
    //                                                 currentvalue: Number(tradeListData[i]?.valueInShares) + Number(Returnvalue.currentvalue) + Number(plValue)
    //                                             }

    //                                             // openTradeListing.profitLoss = Number(plValue).toFixed(3)
    //                                             // openTradeListing.stockCountrySymbol = symboleData[0].symbol_native
    //                                             // openTradeListing.portfoliocurrencycode = stockPortCountry[0].currencySymbol
    //                                             // openTradeListing.stockName = tradeListData[i].stockCode
    //                                             // openTradeListing.cPrice = (askPrice)
    //                                             // openTradeListing.entryPrice = (tradeListData[i].entryPrice)
    //                                             // pdata.push({ ...openTradeListing })
    //                                         }
    //                                         console.log(Returnvalue)
    //                                         if (pdata.length == tradeListData.length) {

    //                                             InvestmentReturn(stockNamesArray, getopendate, Returnvalue, pdata)
    //                                         }
    //                                         setTradeReturnValue({ ...Returnvalue })
    //                                     } else {
    //                                         // let openTradeListing = tradeListData[i]
    //                                         // openTradeListing.profitLoss = 'NA'
    //                                         // openTradeListing.stockCountrySymbol = stockPortCountry[0]?.currencySymbol
    //                                         // openTradeListing.stockName = tradeListData[i].stockCode
    //                                         // openTradeListing.cPrice = 'NA'
    //                                         // pdata.push({ ...openTradeListing })
    //                                     }
    //                                     // pdata.sort((date1, date2) => date2.transactionNo - date1.transactionNo);

    //                                 })
    //                             } catch (error) {

    //                             }
    //                         }



    //                     })
    //                 } catch (error) {

    //                 }


    //             } else {
    //                 let openTradeListing = tradeListData[i]

    //                 openTradeListing.profitLoss = 'NA'
    //                 openTradeListing.stockCountrySymbol = 'NA'
    //                 openTradeListing.stockName = tradeListData[i].stockCode
    //                 openTradeListing.cPrice = 'NA'

    //                 pdata.sort((date1, date2) => date2.transactionNo - date1.transactionNo);

    //             }


    //         }
    //     } else {
    //         // dispatch({ type: "COMPLETE", data: [] });
    //     }

    // }
    // const InvestmentReturn = (stockNamesArray, getopendate, Returnvalue, pdata) => {
    //     console.log(Returnvalue)
    //     try {
    //         stockNamesArray.forEach((StockCode) => {

    //             let fromCurrency = StockCode.split('.')[0]
    //             const currentDate = new Date();
    //             const providedDateTime = new Date(getopendate);
    //             const differenceInMilliseconds = currentDate - providedDateTime;
    //             const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));


    //             if (differenceInDays <= 30) {
    //                 GetInvestmentReturnWeekly(fromCurrency).then((res) => {
    //                     // debugger
                        
    //                     const currentDate = providedDateTime;
    //                     // const EndDate = new Date('2022-12-30');
    //                     let startingDate = new Date(currentDate);
    //                     startingDate.setDate(startingDate.getDate() - 28); // Go back 4 weeks from the ending date
    //                     // let endDate =startingDate.setDate(startingDate.getDate() - 28);


    //                     let EndingDateString = currentDate.toISOString().split('T')[0];
    //                     // const startingDateString = EndDate.toISOString().split('T')[0];
    //                     let startingDateString = startingDate.toISOString().split('T')[0];



    //                     if (res?.status === 200) {
    //                         const timeSeries = res?.data['Weekly Time Series'];
    //                         console.log(timeSeries)



    //                         if (timeSeries) {
    //                             // const currentDateForAPI = new Date(providedDateTime);

    //                             let EndingClosePrice = timeSeries[EndingDateString];
    //                             let StartingClosePrice = timeSeries[startingDateString];

    //                             while (!EndingClosePrice && currentDate >= providedDateTime) {
    //                                 currentDate.setDate(currentDate.getDate() + 1);
    //                                 const nextDate = currentDate.toISOString().split('T')[0];
    //                                 EndingClosePrice = timeSeries[nextDate];
    //                                 if (EndingClosePrice) {
    //                                     EndingDateString = nextDate; // Update the EndingDateString when found
                             
    //                                 }
    //                             }

    //                             // If data for the exact starting date is not available, find the nearest previous date with data
    //                             while (!StartingClosePrice && startingDate >= startingDate) {
                             
    //                                 startingDate.setDate(startingDate.getDate() - 1);
    //                                 const previousDate = startingDate.toISOString().split('T')[0];
    //                                 StartingClosePrice = timeSeries[previousDate];

    //                                 if (StartingClosePrice) {
    //                                     startingDateString = previousDate;
    //                                 }
    //                             }

    //                            const filteredData = {};
    //                             // const currentDate = new Date();
    //                             // const providedDateTime = new Date(getopendate);

    //                             for (const date in timeSeries) {
    //                                 //   const currentDateISO = currentDate.toISOString().split('T')[0];
    //                                 //   const providedDateTimeISO = providedDateTime.toISOString().split('T')[0];

    //                                 if (date >= startingDateString && date <= EndingDateString) {
    //                                     filteredData[date] = timeSeries[date];
    //                                 }
    //                             }
    //                             debugger
    //                             const datesArray = Object.keys(filteredData);
    //                             datesArray.sort();
    //                             const priceDifferences = [];
    //                             const datePairs = [];
    //                             const pricepercentage = [];
    //                             for (let i = 0; i < datesArray.length - 1; i++) {
    //                                 // debugger
    //                                 const currentWeekClose = parseFloat(filteredData[datesArray[i]]['4. close']);
    //                                 const nextWeekClose = parseFloat(filteredData[datesArray[i + 1]]['4. close']);

    //                                 const difference = nextWeekClose - currentWeekClose;

    //                                 // let  deff={
    //                                 //         diff:difference,
    //                                 //         pdiff:
    //                                 // //     }

    //                                 // priceDifferences.push(deff);
    //                                 priceDifferences.push(difference);
    //                                 pricepercentage.push((filteredData[datesArray[i + 1]]['1. open'] * difference) / 100)
    //                                     ;

    //                                 const pair = `${datesArray[i + 1]} - ${datesArray[i]}`;
    //                                 datePairs.push(pair);
    //                             }


    //                             let total = 0;
    //                             for (let i = 0; i < pricepercentage.length; i++) {
    //                                 total += pricepercentage[i];
    //                             }

    //                             const average = total / pricepercentage.length;
    //                             let x = 0
    //                             for (let i = 0; i < pricepercentage.length; i++) {
    //                                 let n = ((pricepercentage[i] - average) ** 2) / pricepercentage.length
    //                                 x += n
    //                             }
    //                             setStandardDeviation(x)
    //                             portfolioPerformance(Returnvalue, x)
    //                             // let Closeprices = []

    //                             // const transformedData = [];
    //                             // for (const date in timeSeries) {
    //                             //     if (timeSeries.hasOwnProperty(date)) {
    //                             //         const record = timeSeries[date];
    //                             //         transformedData.push({
    //                             //             x: date,
    //                             //             open: parseFloat(record["1. open"]),
    //                             //             high: parseFloat(record["2. high"]),
    //                             //             low: parseFloat(record["3. low"]),
    //                             //             close: parseFloat(record["4. close"]), 
    //                             //             volume: parseFloat(record["5. volume"]),
    //                             //             value: parseFloat(record["4. close"])
    //                             //         });
    //                             //     }
    //                             // }
    //                             // Sort the data by date
    //                             // transformedData.sort((a, b) => new Date(a.x) - new Date(b.x));

    //                             // for (let i = 0; i <= 5; i++) {
    //                             //     let newDate = new Date(transformedData[i].x)
    //                             //     debugger
    //                             //     // if(newDate.getTime()<=endDate &&newDate.getTime()>=startingDate.getTime() ){

    //                             //         // }
    //                             //     providedDateTime.setDate(providedDateTime.getDate() - 7);
    //                             // }

    //                             if (EndingClosePrice && StartingClosePrice) {
    //                                 const closeEndPrice = EndingClosePrice['4. close'];
    //                                 const closeStartPrice = StartingClosePrice['4. close'];
                                    
    //                                 //   let returnofinvestment =
    //                             } else {
    //                                 console.log("Error: No data found for the specified starting date.");
    //                             }
    //                         } else {

    //                         }

    //                     }
    //                 }).catch((error) => {
    //                     console.error(`Error fetching weekly data for ${fromCurrency}:`, error);
    //                 });
    //             }
    //             else {
    //                 GetInvestmentReturnMonthly(fromCurrency).then((res) => {
    //                     // debugger

    //                     const currentDate = providedDateTime;
    //                     // const EndDate = new Date('2022-12-30');
    //                     let startingDate = new Date(currentDate);
    //                     startingDate.setDate(startingDate.getDate() - 28); // Go back 4 weeks from the ending date
    //                     // let endDate =startingDate.setDate(startingDate.getDate() - 28);


    //                     let EndingDateString = currentDate.toISOString().split('T')[0];
    //                     // const startingDateString = EndDate.toISOString().split('T')[0];
    //                     let startingDateString = startingDate.toISOString().split('T')[0];



    //                     if (res?.status === 200) {
    //                         const timeSeries = res?.data['Monthly Time Series'];




    //                         if (timeSeries) {
    //                             // const currentDateForAPI = new Date(providedDateTime);

    //                             let EndingClosePrice = timeSeries[EndingDateString];
    //                             let StartingClosePrice = timeSeries[startingDateString];

    //                             while (!EndingClosePrice && currentDate >= providedDateTime) {
    //                                 currentDate.setDate(currentDate.getDate() + 1);
    //                                 const nextDate = currentDate.toISOString().split('T')[0];
                           
    //                                 EndingClosePrice = timeSeries[nextDate];
    //                                 if (EndingClosePrice) {
    //                                     EndingDateString = nextDate; // Update the EndingDateString when found
                                       
    //                                 }
    //                             }

    //                             // If data for the exact starting date is not available, find the nearest previous date with data
    //                             while (!StartingClosePrice && startingDate >= startingDate) {
                                
    //                                 startingDate.setDate(startingDate.getDate() - 1);
    //                                 const previousDate = startingDate.toISOString().split('T')[0];
    //                                 StartingClosePrice = timeSeries[previousDate];


    //                                 if (StartingClosePrice) {
    //                                     startingDateString = previousDate;

    //                                 }
    //                             }

    //                             const filteredData = {};


    //                             for (const date in timeSeries) {


    //                                 if (date >= startingDateString && date <= EndingDateString) {
    //                                     filteredData[date] = timeSeries[date];
    //                                 }
    //                             }


    //                             const datesArray = Object.keys(filteredData);
    //                             datesArray.sort();
    //                             const priceDifferences = [];
    //                             const datePairs = [];
    //                             const pricepercentage = [];
    //                             for (let i = 0; i < datesArray.length - 1; i++) {

    //                                 const currentWeekClose = parseFloat(filteredData[datesArray[i]]['4. close']);
    //                                 const nextWeekClose = parseFloat(filteredData[datesArray[i + 1]]['4. close']);

    //                                 const difference = nextWeekClose - currentWeekClose;


    //                                 priceDifferences.push(difference);
    //                                 pricepercentage.push((filteredData[datesArray[i + 1]]['1. open'] * difference) / 100)
    //                                     ;

    //                                 const pair = `${datesArray[i + 1]} - ${datesArray[i]}`;
    //                                 datePairs.push(pair);
    //                             }



    //                             let total = 0;
    //                             for (let i = 0; i < pricepercentage.length; i++) {
    //                                 total += pricepercentage[i];
    //                             }

    //                             const average = total / pricepercentage.length;

    //                             let x = 0
    //                             for (let i = 0; i < pricepercentage.length; i++) {
    //                                 let n = ((pricepercentage[i] - average) ** 2) / pricepercentage.length
    //                                 x += n
    //                             }
    //                             setStandardDeviation(x)
    //                             portfolioPerformance(Returnvalue, x)

    //                             if (EndingClosePrice && StartingClosePrice) {
    //                                 const closeEndPrice = EndingClosePrice['4. close'];
    //                                 const closeStartPrice = StartingClosePrice['4. close'];
    //                                 // console.log(closeStartPrice, closeEndPrice, "closeStartPrice ,closeEndPrice");

    //                                 //   let returnofinvestment =
    //                             } else {

    //                             }
    //                         } else {
    //                         }

    //                     }
    //                 }).catch((error) => {
    //                     console.error(`Error fetching weekly data for ${fromCurrency}:`, error)
    //                 });
    //             }






    //         });
    //     } catch (error) {
    //         console.error('Error in InvestmentReturn function:', error);
    //     }
    // };

    // const getopentrade = () => {
    //     let cData = {
    //         portfolioId: selectedUserData.portFolioId,
    //         pageNumber: 1,
    //         pageSize: 500,
    //         id: userType?.userId,
    //     }
    //     try {
    //         getOpentradeList(cData).then((res) => {
    //             if (res?.status === 200) {
    //                 const transactions = res.data.data;

    //                 const opendate = transactions[0]?.openDate

    //                 // const splitOpenDate = opendate?.split('T')
    //                 // const getopendate = splitOpenDate[0]
    //                 if (opendate) {
    //                     const splitOpenDate = opendate?.split('T')
    //                     const getopendate = splitOpenDate[0]

    //                     addProfitLoass(res.data.data, getopendate)

    //                 }
    //                 else {
    //                 }
    //             }
    //         })
    //     } catch (error) {

    //     }
    // }


    // const portfolioPerformance = (Returnvalue, x) => {
    //     // debugger

    //     let pfReturn = Number((Returnvalue.currentvalue - Returnvalue.initalvalue) / Returnvalue.initalvalue * 100);

 
    //     setPortfolioReturn(pfReturn)
    //     const nominalRate = 0.02;
    //     const inflationRate = Number(inflationrate);
    //     const riskFreeRate = Number(Number(1 + nominalRate) / Number(1 + inflationRate));

    //     // let performance =Number(( portfolioreturn -  riskFreeRate)/standardDeviation)
    //     let performance = Number((portfolioreturn - riskFreeRate))
    //     const std = (x.toString()).split('e')[0]
    //     let perfdivide = Number(performance / (Number(std)))
    //     const Ratio = (perfdivide).toFixed(2)
    //     setSharpeRatio(Ratio)
    //     // js interview questions


    // }
    return (
      <>
        <div className="Portfolio-tablesec">
          {/* <!--------Portfolio-tablesec----> */}
          {/* <div className="row mt-4">
                    <div className="col-lg-6 col-sm-6">
                        <Link to={'/stock-screener'}><button className="btn bt-drk admin-btn-main" type="button" >Stock Screener</button></Link>
                    </div>

                    <div className="col-lg-6 col-sm-5 ms-auto d-block text-end">
                        {
                            userType.role === 'admin' ?
                                <Link to={'/admin'}><button className="btn bt-drk admin-btn-main" type="button" >Admin</button></Link>
                                :
                                <h6 className="btn bt-drk admin-btn-main" data-bs-toggle="modal" data-bs-target="#createStrategy">Strategy </h6>
                        }
                    </div>
                </div> */}

          <section class="discount_add mt-2">
            <div class="container">
              <div class="row">
                <div class="col-sm-12 col-md-4 col-xl-4">
                  <div class="card mb-3 widget-chart">
                    <div class="widget-chart-content">
                      <div class="icon-wrapper rounded">
                        <div class="icon-wrapper-bg bg-warning"></div>
                        <BsFillPieChartFill />
                      </div>
                      <div class="widget-numbers">
                        <span>{deposit ? deposit : 0}</span>
                      </div>
                      <div class="widget-subheading fsize-1 pt-2 opacity-10 text-warning font-weight-bold">
                        Cash Deposits
                      </div>
                      {/* <div class="widget-description opacity-8">
                                            <span class="text-danger pr-1">
                                                <BiChevronDown />
                                                <span class="pl-1">54.1% </span>
                                            </span>
                                            Down last 30 days
                                        </div> */}
                    </div>
                    <div class="widget-chart-wrapper">
                      <div
                        id="dashboard-sparklines-simple-1"
                        tyle={{ minHeight: "121px" }}
                      >
                        <div
                          id="apexchartspzuy8j6a"
                          class="apexcharts-canvas apexchartspzuy8j6a"
                          tyle={{ width: "502px", height: "121px" }}
                        >
                          <svg
                            viewBox="0 0 10 10"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="10" height="10">
                              <animate
                                attributeName="rx"
                                values="0;5;0"
                                dur="10s"
                                repeatCount="indefinite"
                              />
                            </rect>
                          </svg>
                          <div class="apexcharts-legend"></div>
                        </div>
                      </div>
                      <div class="resize-triggers">
                        <div class="expand-trigger">
                          <div
                            style={{ width: "502px", height: "121px" }}
                          ></div>
                        </div>
                        <div class="contract-trigger"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4 col-xl-4">
                  <div class="card mb-3 widget-chart">
                    <div class="widget-chart-content">
                      <div class="icon-wrapper rounded">
                        <div class="icon-wrapper-bg bg-danger"></div>
                        <DiGoogleAnalytics />
                      </div>
                      <div class="widget-numbers">
                        <span>{sharperatio}</span>
                      </div>
                      <div class="widget-subheading fsize-1 pt-2 opacity-10 text-warning font-weight-bold">
                        Sharpe Ratio
                      </div>
                      <div class="widget-description opacity-8">
                        {/* <span className={sharperatio < 1 ? 'redText' : sharperatio >= 1 && sharperatio < 2 ? 'orangeText' : sharperatio >= 2 && sharperatio <= 3 ? 'greenText' : ''} >
                                                {sharperatio < 1 ? 'Bad' : sharperatio >= 1 && sharperatio < 2 ? 'Good' : sharperatio >= 2 && sharperatio <= 3 ? 'Very Good' : ''} </span> */}

                        {/* <span class="text-info pl-1">
                                                <BiChevronDown />
                                                <span class="pl-1"> good </span>
                                            </span> */}
                      </div>
                    </div>
                    <div class="widget-chart-wrapper">
                      <div
                        id="dashboard-sparklines-simple-1"
                        tyle={{ minHeight: "121px" }}
                      >
                        <div
                          id="apexchartspzuy8j6a"
                          class="apexcharts-canvas apexchartspzuy8j6a"
                          tyle={{ width: "502px", height: "121px" }}
                        >
                          <svg
                            viewBox="0 0 10 10"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="10" height="10">
                              <animate
                                attributeName="rx"
                                values="0;5;0"
                                dur="10s"
                                repeatCount="indefinite"
                              />
                            </rect>
                          </svg>
                          <div class="apexcharts-legend"></div>
                        </div>
                      </div>
                      <div class="resize-triggers">
                        <div class="expand-trigger">
                          <div
                            style={{ width: "502px", height: "121px" }}
                          ></div>
                        </div>
                        <div class="contract-trigger"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4 col-xl-4">
                  <div class="card mb-3 widget-chart">
                    <div class="widget-chart-content">
                      <div class="icon-wrapper rounded">
                        <div class="icon-wrapper-bg bg-info"></div>
                        <GiCutDiamond />
                      </div>
                      <div class="widget-numbers text-danger">
                        <span>{withdrawl ? withdrawl : 0}</span>
                      </div>
                      <div class="widget-subheading fsize-1 pt-2 opacity-10 text-warning font-weight-bold">
                        Withdrawals
                      </div>
                      {/* <div class="widget-description opacity-8">
                                            Down by
                                            <span class="text-success pl-1">
                                                <BiChevronUp />
                                                <span class="pl-1">21.8% </span>
                                            </span>
                                        </div> */}
                    </div>
                    <div class="widget-chart-wrapper">
                      <div
                        id="dashboard-sparklines-simple-1"
                        tyle={{ minHeight: "121px" }}
                      >
                        <div
                          id="apexchartspzuy8j6a"
                          class="apexcharts-canvas apexchartspzuy8j6a"
                          tyle={{ width: "502px", height: "121px" }}
                        >
                          <svg
                            viewBox="0 0 10 10"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="10" height="10">
                              <animate
                                attributeName="rx"
                                values="0;5;0"
                                dur="10s"
                                repeatCount="indefinite"
                              />
                            </rect>
                          </svg>
                          <div class="apexcharts-legend"></div>
                        </div>
                      </div>
                      <div class="resize-triggers">
                        <div class="expand-trigger">
                          <div
                            style={{ width: "502px", height: "121px" }}
                          ></div>
                        </div>
                        <div class="contract-trigger"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-center mbg-3 mt-3 mb-3">
                  <button
                    class="btn-wide btn-pill btn-shadow fsize-1 btn btn-focus btn-lg"
                    onClick={handleNavigaterecords}
                  >
                    <span class="mr-2 opacity-7 Report_btn">
                      <AiOutlineGlobal />
                    </span>
                    View Complete Report
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <!--------Portfolio-tablesec----> */}
        {/* <div className="row">
                <div className="col-lg-6  orc-table openttrade_transtion text-center" style={{ "overflow-x": "auto" }}>
                    <h5>Top Gainers</h5>
                    <div className="table-responsive mb-5">
                        <table className="table border" id='accordionExample'>
                            <thead className="table-dark " id="headingOne">
                                <tr>
                                    <th scope="col"  >Stock Name</th>
                                    <th scope="col" >price</th>
                                    <th scope="col" >Change</th>
                                    <th scope="col" >% Gain</th>
                                </tr>
                            </thead>
                            <tbody id="collapseOne" className="accordion-collapse collapse show gainer_loser_font" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                {
                                    gainerList?.map((val, ind) => {
                                        return (
                                            <tr key={ind}>
                                                <td className='show_stock_overview' onClick={()=>setTradeCode(val.ticker)} data-bs-toggle="modal" title='View Chart' data-bs-target="#StockView" >&nbsp;{val.ticker}</td>
                                                <td>&nbsp;{val.price}</td>
                                                <td>&nbsp;{val.change_amount}</td>
                                                <td className='green' >&nbsp;{val.change_percentage}</td>
                                                
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-lg-6  orc-table openttrade_transtion text-center" style={{ "overflow-x": "auto" }}>
                    <h5>Top Losers</h5>
                    <div className="table-responsive mb-5">
                        <table className="table border" id='accordionExample'>
                            <thead className="table-dark " id="headingOne">
                                <tr>
                                    <th scope="col" >Stock Name</th>
                                    <th scope="col" >price</th>
                                    <th scope="col" >Change</th>
                                    <th scope="col"  >% Loss</th>
                                </tr>
                            </thead>
                            <tbody id="collapseOne" className="accordion-collapse collapse show gainer_loser_font" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                {
                                    looserList?.map((val, ind) => {
                                        return (
                                            <tr key={ind}>
                                                <td className='show_stock_overview' data-bs-toggle="modal" title='View Chart' data-bs-target="#StockView" onClick={()=>setTradeCode(val.ticker)} >&nbsp;{val.ticker}</td>
                                                <td>&nbsp;{val.price}</td>
                                                <td>&nbsp;{val.change_amount}</td>
                                                <td className='red' >&nbsp;{val.change_percentage}</td>
                                              
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
        {/* <div className="row">
                <div className="col-lg-6 col-sm-6">
                    <h3>Transaction Record</h3>
                </div>

            </div>

            <div className="table-bx mt-4">
                <div className="row">
                    <div className="col-lg-12  orc-table openttrade_transtion text-center" style={{ "overflow-x": "auto" }}>
                        <div className="table-responsive">
                            <table className="table border" id='accordionExample1'>
                                <thead className="table-dark" id="headingOne"
                                >
                                    <tr >
                                        <th scope="col" className="col-2">Transaction ld</th>
                                        <th scope="col" className="col-2">Type</th>
                                        <th scope="col" className="col-1">Date</th>
                                        <th scope="col" className="col-2">Amount <span className='currency_code'>({currencySymbol?.code})</span></th>
                                        <th scope="col" className="col-3">Running Transaction <span className='currency_code'>({currencySymbol?.code})</span></th>
                                        <th scope="col" className="col-2">Comments</th>
                                        <th scope="col" className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"></th>

                                    </tr>
                                </thead>
                                <tbody id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">

                                    {
                                        transactionList.map((val, ind) => {
                                            return (
                                                <tr key={ind} className="accordion-body" >
                                                    <th>{val.transactionNo}</th>
                                                    <td>{val.type}</td>
                                                    <td>{moment(val.transDate).format('DD MMM YYYY')}</td>
                                                    <td>{currencySymbol?.symbol} {val.amount} </td>
                                                    <td>{currencySymbol?.symbol} {val.runningBalance} </td>
                                                    <td>{val.comments}</td>
                                                    <td></td>
                                                </tr>
                                            )
                                        })
                                    }





                                </tbody>
                            </table>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                    setPagination={setPagination}
                                    pageNumber={pageNumber}
                                    pageSize={pageSize}
                                    pagination={pagination}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-lg-6 col-sm-6">
                        <h3>Trade Record</h3>
                    </div>

                </div>


                <div className="row">
                    <div className="col-lg-12  orc-table openttrade_transtion text-center" style={{ "overflow-x": "auto" }}>
                        <div className="table-responsive mb-5">
                            <table className="table border" id='accordionExample'>
                                <thead className="table-dark " id="headingOne">
                                    <tr>
                                        <th scope="col" >Name</th>
                                        <th scope="col" >Product</th>
                                        <th scope="col" >Open/Close</th>
                                        <th scope="col" >Long/Short</th>
                                        <th scope="col" >Date</th>
                                        <th scope="col" >Quantity</th>
                                        <th scope="col" >Buy Price <span className='currency_code'>({currencySymbol?.code})</span></th>
                                      
                                        <th scope="col" >Margin</th>
                                        <th scope="col" className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" ></th>

                                    </tr>
                                </thead>
                                <tbody id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    {
                                        openTradeList?.map((val, ind) => {
                                            return (
                                                <tr key={ind}>
                                                    <th >&nbsp;{val.stockName}</th>
                                                    <td>&nbsp;{val.stockCode}</td>
                                                    <td>&nbsp;{val.isOpen ? 'Open' : 'Close'}</td>
                                                    <td>&nbsp;{val.short == "false" ? 'Long' : 'Short'}</td>
                                                    <td>&nbsp;{moment(val.openDate).format('DD MMM YYYY')}</td>
                                                    <td>&nbsp;{val.qty}</td>
                                                    <td>&nbsp;{currencySymbol?.symbol} {val.transactionPrice} </td>
                                                  
                                                    <td>&nbsp;{val.margin} %</td>
                                                    <td></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">

                                <Pagination
                                    currentPage={currentPageTrade}
                                    totalPages={totalPagesTrade}
                                    onPageChange={handlePageChangeTrade}
                                    setPagination={setPaginationTrade}
                                    pageNumber={pageNumberTrade}
                                    pageSize={pageSizeTrade}
                                    pagination={setPaginationTrade}
                                />
                            </div>
                        </div>
                    </div>
                </div>
               

            </div> */}
        <TradeModel tradeCode={tradeCode} />
      </>
    );
}

export default PortfolioTable