import React, { useEffect, useRef, useState } from "react";
import {
  GetStockStats,
  getDevident,
  getStockDetails,
  getStockIncomeStatement,
  getStockOverView,
} from "../../../../services/TradeService";
import { createChart, ColorType } from "lightweight-charts";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import LineChart from "../charts/LineChart";
import BarChart from "../charts/BarChart";
import { Button } from "react-bootstrap";

const StockOverview = (props) => {
  const [ChartData, setChartData] = useState([]);
  const [changeChart, setChangeChart] = useState(true);
  const [dividendData, setDividenddata] = useState([]);
  const [stockDate, setStockdate] = useState("W");
  const [stockOverViewData, setStockOverViewData] = useState();
  const { stockData, data, tradeCode, dataBar } = props;
  const [allData, setAllData] = useState({ open: 0, high: 0 });
	const { open, high } = allData;
	

	const [datas, setDatas] = useState(0);
  const [codeName, setCodeName] = useState("");

  const callLocal = async () => {
    //  const localAskPrice = JSON.parse(localStorage?.getItem("askPrice"));
    //  const localBidPrice = JSON.parse(localStorage?.getItem("bidPrice"));
    const localName = JSON.parse(localStorage?.getItem("tradeCode"));
    const localData = JSON.parse(localStorage.getItem("Data"));
    setCodeName(localName || null);
    setDatas(localData || 0);
    //  setLocalAskPrice(localAskPrice || 0);
    //  setLocalBidPrice(localBidPrice || 0);
  };

  useEffect(() => {
    // Call local prices initially
    callLocal();

    // Set up an interval to check for updates
    const interval = setInterval(callLocal, 1000); // Check every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  let tradeSymbol;
  let tradeDataArray;
  if (tradeCode) {
    let n = tradeCode.split(".");

    if (n[n.length - 1] == "AX") {
      tradeSymbol = "ASX:" + n[0];
    } else {
      tradeSymbol = n[0];
    }
  }
  // chart fun

  // allData handle
  const averageData = () => {
    let a = 0;
    for (let i = 0; i < stockData?.length; i++) {
      if (a < stockData[i]?.high) {
        a = stockData[i]?.high;
      }
    }
    setAllData({ ...allData, high: a });
  };
  useEffect(() => {
    averageData();
    // setAllData({ ...allData, open: stockData[stockData?.length - 1]?.open })
  }, [stockData]);

  const handleSelectDate = (n) => {
    setStockdate(n);
  };
//   useEffect(() => {
//     let params = {
//       tradeCode: tradeCode,
//       interval: "1Y",
//     };
//     if (tradeCode !== undefined && tradeCode !== "") {
//       try {
//         getStockOverView(tradeCode).then((res) => {
//           if (res?.status === 200) {
//             // console.log(res)
//             setStockOverViewData(res?.data || []);
//             // setDividenddata(res?.data||[])
//           }
//         });
//       } catch (error) {}
//     }
//   }, [tradeCode]);
  return (
    <>
      <div
        class="tab-pane fade show active"
        id="pills-overview"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div class="overview-sec">
          {/* <div class="form-check form-switch mb-3">
						<input class="form-check-input toggle-chart" onClick={() => changeChart === true ? setChangeChart(false) : setChangeChart(true)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
						<label class="form-check-label" for="flexSwitchCheckDefault">{changeChart === true ?'Switch to Bar Chart':'Switch to Bar Chart'}</label>
					</div> */}
          {/* <Button onClick={() => changeChart === true ? setChangeChart(false) : setChangeChart(true)}>change</Button> */}
          <div class="col-sm-12">
            <div>
              {/* {
								changeChart === true ? <LineChart data={data} /> : <BarChart dataBar={dataBar} />
							} */}
              {/* <BarChart/> */}
              <LineChart tradeCode={tradeSymbol} stockDate={stockDate} />
              {/* <img src="assets/image/grp.jpg" class="img-fluid"/> */}
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="data-min-bx">
            <div className="row white_text">
              <h4 className="col-3" style={{ "align-content": "center" }}>
                Performance
              </h4>

              <h5 className="col-4" style={{ "padding-top": "8px" }}>
                <img
                  src="assets/image/trading-view.png"
                  alt="img"
                  style={{
                    width: "35px",
                    marginRight: "3px",
                    marginBottom: "5px",
                  }}
                />
                Charts by:{" "}
                <Link
                  to={`https://www.tradingview.com/`}
                  target="_blank"
                  className="candle-chart-link"
                >
                  tradingview
                </Link>
              </h5>
              <div class="row text-center col-4">
                <div class="col-sm-12 " style={{marginTop: '10px'}}>
                  <ul
                    class="slec-dta-ch"
                    style={{ top: "auto", bottom: "auto" }}
                  >
                    <li
                      className={stockDate === "60" && "day-active white"}
                      onClick={() => handleSelectDate("60")}
                    >
                      <Link
                        className={stockDate === "60" && "day-active white"}
                      >
                        1H
                      </Link>
                    </li>
                    <li
                      className={stockDate === "D" && "day-active white"}
                      onClick={() => handleSelectDate("D")}
                    >
                      <Link className={stockDate === "D" && "day-active white"}>
                        1D
                      </Link>
                    </li>
                    <li
                      className={stockDate === "W" && "day-active white"}
                      onClick={() => handleSelectDate("W")}
                    >
                      <Link className={stockDate === "W" && "day-active white"}>
                        1W
                      </Link>
                    </li>
                    <li
                      className={stockDate === "1M" && "day-active white"}
                      onClick={() => handleSelectDate("1M")}
                    >
                      <Link
                        className={stockDate === "1M" && "day-active white"}
                      >
                        1M
                      </Link>
                    </li>
                    <li
                      className={stockDate === "12M" && "day-active white"}
                      onClick={() => handleSelectDate("12M")}
                    >
                      <Link
                        className={stockDate === "12M" && "day-active white"}
                      >
                        1Y
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <div class="pr-mbx mt-4 mb-4 white_text">
                  <h5>Open Price</h5>
                  <span>
                    {stockData?.regularMarketOpen?.raw ||
                    stockData[0]?.open ||
                    datas?.regularMarketOpen?.raw ||
                    datas[0]?.open
                      ? stockData?.regularMarketOpen?.raw?.toFixed(2) ||
                        datas?.regularMarketOpen?.raw?.toFixed(2) ||
                        stockData[0]?.open ||
                        datas[0]?.open
                      : 0}
                  </span>
                  <span class="pin-x">
                    <i class="bi bi-tag"></i>
                  </span>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="pr-mbx mt-4 mb-4 white_text">
                  <h5>Close Price</h5>
                  <span>
                    {stockData?.regularMarketPreviousClose?.raw ||
                    stockData[0]?.close ||
                    datas?.regularMarketPreviousClose?.raw ||
                    datas[0]?.close
                      ? stockData?.regularMarketPreviousClose?.raw?.toFixed(
                          2
                        ) ||
                        stockData[0]?.close ||
                        datas?.regularMarketPreviousClose?.raw?.toFixed(2) ||
                        datas[0]?.close
                      : 0}
                  </span>
                  <span class="pin-x">
                    <i class="bi bi-tag"></i>
                  </span>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="pr-mbx mt-4 mb-4 white_text">
                  <h5>High Price</h5>
                  <span>
                    {stockData?.regularMarketDayHigh?.raw ||
                    stockData[0]?.high ||
                    datas?.regularMarketDayHigh?.raw ||
                    datas[0]?.high
                      ? stockData?.regularMarketDayHigh?.raw?.toFixed(2) ||
                        stockData[0]?.high ||
                        datas?.regularMarketDayHigh?.raw?.toFixed(2) ||
                        datas[0]?.high
                      : 0}
                  </span>
                  <span class="pin-x">
                    <i class="bi bi-tag"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="modal-header p-0 border-bottom-0">
              <h5 className="modal-title h4" id="exampleModalLgLabel">
                Dividend
              </h5>
            </div>
            <div style={{ overflowX: "auto" }}>
              <table class="table table-input1 table-bordered mt-3 text-center white_text">
                <thead>
                  <tr>
                    <th scope="col">Cash Dividend</th>
                    <th scope="col">Dividend/Share</th>
                    <th scope="col">Ex Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      {stockData?.dividendYield?.fmt ||
                      datas?.dividendYield?.fmt
                        ? stockData?.dividendYield?.fmt ||
                          datas?.dividendYield?.fmt
                        : "N/A"}{" "}
                    </td>
                    <td>
                      {stockData?.dividendRate?.fmt || datas?.dividendRate?.fmt
                        ? stockData?.dividendRate?.fmt ||
                          datas?.dividendRate?.fmt
                        : "N/A"}
                    </td>
                    <td>
                      {stockData?.dividendDate?.longFmt ||
                      datas?.dividendDate?.longFmt
                        ? stockData?.dividendDate?.longFmt?.split("T")[0] ||
                          datas?.dividendDate?.longFmt?.split("T")[0]
                        : "N/A"}
                    </td>
                    {/* <td> {stockOverViewData?.DividendYield} </td>
                  <td>{stockOverViewData?.DividendPerShare}</td>
                  <td>{stockOverViewData?.DividendDate}</td> */}
                  </tr>
                  {/* {
									dividendData?.map((val, ind) => {
										return (
											<tr key={ind}>
												<td>Final</td>
												<td>$ {val.amount}</td>
												<td>{moment(val.exDate).format('DD MMM YYYY')}</td>
											</tr>
										)
									})
								} */}
                </tbody>
              </table>
            </div>
          </div>

          {/* <div class="col-sm-12">
					<div class="analyst-bx">

						<h4 class="mt-4 mb-3">Analyst Estimates</h4>

						<div class="row mb-4">
							<div class="col-sm-2 mt-4">
								<div class="apt-bx">
									<span>45%</span>
								</div>
							</div>

							<div class="col-sm-7 mb-4">
								<div class="row mt-4">
									<label for="inputEmail3"
										class="col-sm-1 col-form-label">Buy</label>
									<div class="col-sm-9 mt-3">
										<div class="progress">
											<div class="progress-bar" role="progressbar"
												style={{ "width": "90%;" }} aria-valuenow="25"
												aria-valuemin="0" aria-valuemax="100"></div>
										</div>
									</div>
									<label for="inputEmail3"
										class="col-sm-1 col-form-label text-end">43%</label>
								</div>

								<div class="row mt-4">
									<label for="inputEmail3"
										class="col-sm-1 col-form-label">Hold</label>
									<div class="col-sm-4 mt-3">
										<div class="progress">
											<div class="progress-bar" role="progressbar"
												style={{ "width": "14%;" }} aria-valuenow="25"
												aria-valuemin="0" aria-valuemax="100"></div>
										</div>
									</div>
									<label for="inputEmail3"
										class="col-sm-1 col-form-label text-end">14%</label>
								</div>


								<div class="row mt-4">
									<label for="inputEmail3"
										class="col-sm-1 col-form-label">Sell</label>
									<div class="col-sm-5 mt-3">
										<div class="progress">
											<div class="progress-bar" role="progressbar"
												style={{ "width": "25%;" }} aria-valuenow="25"
												aria-valuemin="0" aria-valuemax="100"></div>
										</div>
									</div>
									<label for="inputEmail3"
										class="col-sm-1 col-form-label text-end">43%</label>
								</div>

							</div>
						</div>


						<div class="row mt-4">
							<div class="col-sm-6">
								<div class="Fundamentals-bx">
									<h5 class="mb-3">Tata Power Co Ltd Key Indicators</h5>
									<div class="table-responsive">
										<div class="table-input1">
											<table class="table ">
												<tbody>
													<tr>
														<th scope="row">Market Cap</th>
														<td class="text-end">₹61,590Cr</td>
													</tr>
													<tr>
														<th scope="row">P/B Ratio</th>
														<td class="text-end">11.38 </td>
													</tr>
													<tr>
														<th scope="row">ROCE</th>
														<td class="text-end">2.75</td>
													</tr>

													<tr>
														<th scope="row">Dividend Yield</th>
														<td class="text-end">2.75</td>
													</tr>

													<tr>
														<th scope="row">Market Cap</th>
														<td class="text-end">2.75</td>
													</tr>
												</tbody>

											</table>
										</div>
									</div>
								</div>
							</div>

							<div class="col-sm-6">
								<div class="Fundamentals-bx">
									<h5 class="mb-3">Revenue Statement</h5>
									<div class="table-responsive">
										<div class="table-input1">
											<table class="table ">
												<tbody>
													<tr>
														<th scope="row">Market Cap</th>
														<td class="text-end">₹61,590Cr</td>
													</tr>
													<tr>
														<th scope="row">P/B Ratio</th>
														<td class="text-end">11.38 </td>
													</tr>
													<tr>
														<th scope="row">ROCE</th>
														<td class="text-end">2.75</td>
													</tr>

													<tr>
														<th scope="row">Dividend Yield</th>
														<td class="text-end">2.75</td>
													</tr>

													<tr>
														<th scope="row">Market Cap</th>
														<td class="text-end">2.75</td>
													</tr>
												</tbody>

											</table>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>


				<div class="col-sm-12 mt-4">

					<h4>Financials</h4>


					<div class="row mt-4">

						<div class="col-sm-12">
							<ul class="nav nav-justified nav-pills mb-3" id="pills-tab"
								role="tablist">
								<li class="nav-item" role="presentation">
									<button class="nav-link active" id="pills-revenue-tab"
										data-bs-toggle="pill" data-bs-target="#pills-revenue"
										type="button" role="tab" aria-controls="pills-revenue"
										aria-selected="true">Revenue</button>
								</li>
								<li class="nav-item" role="presentation">
									<button class="nav-link" id="pills-profile-tab"
										data-bs-toggle="pill" data-bs-target="#pills-profile"
										type="button" role="tab" aria-controls="pills-profile"
										aria-selected="false">Profit</button>
								</li>
								<li class="nav-item" role="presentation">
									<button class="nav-link" id="pills-contact-tab"
										data-bs-toggle="pill" data-bs-target="#pills-contact"
										type="button" role="tab" aria-controls="pills-contact"
										aria-selected="false">Net Worth</button>
								</li>
							</ul>

						</div>

					</div>



					<div class="col-sm-12 mt-3 mb-4">
						<div class="financials-bx">
							<div class="tab-content" id="pills-tabContent">
								<div class="tab-pane fade show active" id="pills-revenue"
									role="tabpanel" aria-labelledby="pills-revenue-tab">
									<div class="fincal-bx">
										<img src="assets/image/gp.png" class="img-fluid" />
									</div>
								</div>

								<div class="tab-pane fade" id="pills-profile" role="tabpanel"
									aria-labelledby="pills-profile-tab">
									<div class="fincal-bx" >
										<img src="assets/image/chect.png" class="img-fluid" />
									</div>
								</div>


								<div class="tab-pane fade" id="pills-contact" role="tabpanel"
									aria-labelledby="pills-contact-tab">
									<div class="fincal-bx">
										<img src="assets/image/chect-2.png" class="img-fluid" />
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>


				<div class="col-sm-12 mt-5">

					<h4>About Tata Power</h4>


					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
						imperdiet, nulla et dictum interdum, nisi lorem egestas vitae scel<span
							id="dots">...</span>erisque enim ligula venenatis dolor. Maecenas
						nisl est, ultrices nec congue eget, auctor vitae massa. Fusce luctus
						vestibulum augue ut aliquet. Nunc sagittis dictum nisi,<span id="more">
							sed ullamcorper ipsum dignissim ac. In at libero sed nunc venenatis
							imperdiet sed ornare turpis. Donec vitae dui eget tellus gravida
							venenatis. Integer fringilla congue eros non fermentum. Sed dapibus
							pulvinar nibh tempor porta.</span></p>
					<button onclick="myFunction()" id="myBtn" class="btn mb-3">Read
						more</button>

					<div class="row">
						<div class="col-sm-6">
							<div class="table-responsive">
								<div class="table-input1">
									<table class="table ">
										<tbody>
											<tr>
												<th scope="row">Parent Organisation</th>
												<td class="text-end">The Tata Power Company
													Limited</td>
											</tr>
											<tr>
												<th scope="row">Founded</th>
												<td class="text-end">1919</td>
											</tr>
										</tbody>

									</table>
								</div>
							</div>
						</div>


						<div class="col-sm-6">
							<div class="table-responsive">
								<div class="table-input1">
									<table class="table ">
										<tbody>
											<tr>
												<th scope="row">Parent Organisation</th>
												<td class="text-end">The Tata Power Company
													Limited</td>
											</tr>
											<tr>
												<th scope="row">Founded</th>
												<td class="text-end">1919</td>
											</tr>
										</tbody>

									</table>
								</div>
							</div>
						</div>

					</div>

				</div>


				<div class="col-sm-12 mt-3">

					<h4 class="mb-3">Shareholding Pattern</h4>

					<div class="row">
						<div class="col-sm-12 shareholding-bx">

							<ul class="slec-dta-ch sh-ul">
								<li><a href="">Dec '21</a></li>
								<li><a href="">Dec '21</a></li>
								<li><a href="">Dec '21</a></li>
								<li><a href="">Dec '21</a></li>
								<li><a href="">Dec '21</a></li>

							</ul>


							<div class="Fundamentals-bx mt-5">

								<div class="table-responsive">
									<div class="table-input1">
										<table class="table ">
											<tbody>

												<tr>
													<th scope="row">
														<div class="row mt-4">
															<label for="inputEmail3"
																class="col-sm-10 col-form-label">Promoters</label>
															<div class="col-sm-8 mt-3">
																<div class="progress">
																	<div class="progress-bar"
																		role="progressbar"
																		style={{ "width": "60%;" }}
																		aria-valuenow="25"
																		aria-valuemin="0"
																		aria-valuemax="100">
																	</div>
																</div>
															</div>
															<label for="inputEmail3"
																class="col-sm-2 col-form-label text-end">43%</label>
														</div>
													</th>

												</tr>

												<tr>
													<th scope="row">
														<div class="row mt-4">
															<label for="inputEmail3"
																class="col-sm-10 col-form-label">Retail
																And Others</label>
															<div class="col-sm-8 mt-3">
																<div class="progress">
																	<div class="progress-bar"
																		role="progressbar"
																		style={{ "width": "80%;" }}
																		aria-valuenow="25"
																		aria-valuemin="0"
																		aria-valuemax="100">
																	</div>
																</div>
															</div>
															<label for="inputEmail3"
																class="col-sm-2 col-form-label text-end">43%</label>
														</div>
													</th>

												</tr>

												<tr>
													<th scope="row">
														<div class="row mt-4">
															<label for="inputEmail3"
																class="col-sm-10 col-form-label">Other
																Domestic Institutions</label>
															<div class="col-sm-8 mt-3">
																<div class="progress">
																	<div class="progress-bar"
																		role="progressbar"
																		style={{ "width": "90%;" }}
																		aria-valuenow="25"
																		aria-valuemin="0"
																		aria-valuemax="100">
																	</div>
																</div>
															</div>
															<label for="inputEmail3"
																class="col-sm-2 col-form-label text-end">43%</label>
														</div>
													</th>

												</tr>


											</tbody>

										</table>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>


				<div class="col-sm-12 mt-4 mb-4">

					<h4>Top Mutual</h4>

					<div class="col-sm-12">
						<div class="Fundamentals-bx">
							<h5 class="mb-3">Revenue Statement</h5>
							<div class="table-responsive">
								<div class="table-input1">
									<table class="table ">
										<tbody>
											<tr>
												<th scope="row">Market Cap</th>
												<td class="text-end">₹61,590Cr</td>
											</tr>
											<tr>
												<th scope="row">P/B Ratio</th>
												<td class="text-end">11.38 </td>
											</tr>
											<tr>
												<th scope="row">ROCE</th>
												<td class="text-end">2.75</td>
											</tr>

											<tr>
												<th scope="row">Dividend Yield</th>
												<td class="text-end">2.75</td>
											</tr>

											<tr>
												<th scope="row">Market Cap</th>
												<td class="text-end">2.75</td>
											</tr>
										</tbody>

									</table>
								</div>
							</div>
						</div>
					</div>

				</div> */}
        </div>
      </div>
    </>
  );
};
export default StockOverview;
