import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { getStockList } from '../../../services/HomeService'
import { AsyncPaginate } from 'react-select-async-paginate';
import AsyncLoadingSelect from '../../commonComponent/AsyncLoadingSelect';
import { getOpentradeList, getStockDetails } from '../../../services/TradeService';
import {
  getSearchStockDetails,
  getRapidSearchStockDetails,
} from "../../../services/HomeService";
// import CreateTrade from './CreateTrade';
import { GetCurrentBalabce, GetProviderCummission, GetProviderstocks, GetStockByCountry } from '../../../services/ProviderServices';
import { getTransactionListAPI } from '../../../services/ManageAccountServices';
import CummissionDetails from '../../commonComponent/commonModels/CummissionDetails'
import { toast } from 'react-toastify';
import { Formik, Field, Form, useFormikContext } from 'formik'
import * as Yup from "yup";
import $ from 'jquery';
import { getCountryListAPI, getExchangeRateAPI, getRapidExchangeRateAPI } from '../../../services/commonServices';
import CountryCurrencyList from '../../commonComponent/CountryCurrencyList';


const TradeCalculater = ({ userType, countryList, setOpenTradeRun, calculatedData, calculatedTradeData, setCalculatedTradeData, portfololioList, setRunPortFolioAPI, providerList, setCalculatedData }) => {

    const [brokerList, setBrokerList] = useState(portfololioList)
    const [long, setLong] = useState(false)
    const [providerCummission, setProviderCummission] = useState([])
    const [accountData, setAccountData] = useState({ profit: 0, balanceinShares: 0, balanceinCFD: 0, leverage: 0 })
    const [selectedPort, setSelectedPort] = useState()
    const [balanceDetails, setBalanceDetails] = useState({
        broker: 0, currentBalance: 0,
        dipositBalanceTill: 0,
        id: 0, portfolioId: 0,
        tradeBalanceTill: 0,
        userId: 0,
        withdrawalBalanceTill: 0
    })

    const [profit, setProfit] = useState(0)
    const [stocksList, setStocksList] = useState([])
    const [quantity, setQuantity] = useState(0)//------------------------->
    const [erew, setErew] = useState('')//------------------------->
    const [prefillValue, setPrefillValue] = useState({ stockName: '', currentPrice: '' })
    const { stockName, currentPrice } = prefillValue
    const [selectedStock, setSelectedStock] = useState('')
    const [selectedStockr, setSelectedStockr] = useState('')
    const [checkStock, setCheckStock] = useState(false)
    const [showStockListDropDown, setShowStockListDropDown] = useState(false)
    const [exchangeData, setExchangeData] = useState({ entryChange: '', stopChange: '', targetChange: '' })
    const [allData, setAllData] = useState({reRender:0, riskPer: '', portFolioId: '', stockCode: '', providerName: '', providerId: '', cummission: 0, entry: '', share: 0, margin: '', risk: '', cfd: '', stopLoss: '', quantity, target: '', reward: 0, rewardPercent: 0 })
    const {reRender, riskPer, portFolioId, stockCode, margin, entry, share, providerId, risk, stopLoss, cfd, target, cummission, reward, rewardPercent } = allData
    const [stopLossTableData, setStopLossTableData] = useState({
        dValueATR: 0, dValue2ATR: 0, dValuePerCent: 1, dValueDollar: 0, dValuePrice: 0,
        dStopLossATR: 0, dStopLoss2ATR: 0, dStopLossPerCent: 0, dStopLossDollar: 0, dStopLossPrices: 0,
        dRiskDolATR: 0, dRiskDol2ATR: 0, dRiskDolPerCent: 0, dRiskDolDollar: 0, dRiskDolPrice: 0,
        dRiskPerATR: 0, dRiskPer2ATR: 0, dRiskPerPerCent: 0, dRiskPerDollar: 0, dRiskPerPrice: 0,
        dRRRATR: 0, dRRR2ATR: 0, dRRRPerCent: 0, dRRRDollar: 0, dRRRPrice: 0
    })
    const {
        dValueATR, dValue2ATR, dValuePerCent, dValueDollar, dValuePrice,
        dStopLossATR, dStopLoss2ATR, dStopLossPerCent, dStopLossDollar, dStopLossPrices,
        dRiskDolATR, dRiskDol2ATR, dRiskDolPerCent, dRiskDolDollar, dRiskDolPrice,
        dRiskPerATR, dRiskPer2ATR, dRiskPerPerCent, dRiskPerDollar, dRiskPerPrice,
        dRRRATR, dRRR2ATR, dRRRPerCent, dRRRDollar, dRRRPrice
    } = stopLossTableData
    const [stockListPaging, setStockListPaging] = useState({ PageNumber: 1, PageSize: 100, search: '' })
    const { PageNumber, PageSize, search } = stockListPaging
    const [error, setError] = useState({ target: true, stopLoss: true })
    const [currencyCodes, setCurrencyCodes] = useState({ stockCountryCode: '', portfolioCountryCode: '', stockCountrySymbol: '' })
    const { stockCountryCode, portfolioCountryCode, stockCountrySymbol } = currencyCodes
    const [exchangeRate, setExchangerate] = useState()
    const [loadStockData, setLoadStockData] = useState(false)
    const [popUpShow, setPopUpShow] = useState(null)

    const showToast = (message) => {
        if (popUpShow) {
            setTimeout(() => {
                const newToast = toast.error(message, {
                    autoClose: 3000, // Set the duration as needed
                });
                setPopUpShow(newToast);
            }, 500)
        } else {
            // If no active toast, show a new one
            const newToast = toast.error(message, {
                autoClose: 3000, // Set the duration as needed
            });
            setPopUpShow(newToast);
            // toast.dismiss(newToast); // Update the current toast ID
        }
    };



    // setting tradeData
    useEffect(() => {
        setBrokerList(portfololioList)
    }, [portfololioList])

    useEffect(() => {
        if (calculatedTradeData !== undefined) {
            setLong(calculatedTradeData.long)
            setSelectedStock({ stockCode: calculatedTradeData?.stockCode || '', })

            try {
                // getSearchStockDetails(calculatedTradeData?.stockCode).then((res) => {
                getRapidSearchStockDetails(calculatedTradeData?.stockCode).then((res) => {
                    if (res?.status === 200) {
                      // let aus = calculatedTradeData?.stockCode?.includes(".AX")
                      let aus =
                        calculatedTradeData?.stockCode?.includes("ASX:");

                      let selectedStockCountry = countryList.filter(
                        (val) =>
                          val.key.toUpperCase() ==
                          calculatedTradeData?.country?.toUpperCase()
                      );
                      let symbol = CountryCurrencyList.filter(
                        (val) => val.code == selectedStockCountry[0]?.value
                      );

                      setCurrencyCodes({
                        ...currencyCodes,
                        stockCountryCode: selectedStockCountry[0]?.value,
                        stockCountrySymbol: symbol[0]?.symbol_native,
                      });

                      if (aus) {
                        // const data = res.data["close"];
                        const data = res.data.quotes[0].price;

                        setPrefillValue({
                          ...prefillValue,
                          currentPrice: data,
                          stockName: calculatedTradeData?.stockCode,
                        });
                        setAllData({
                          ...allData,
                          portFolioId: "",
                          providerName: "",
                          providerId: calculatedTradeData?.providerId,
                          stockCode: calculatedTradeData?.stockCode || "",
                          cummission: 0,
                          entry: calculatedTradeData?.entry,
                          currentPrice: data,
                          country: calculatedTradeData?.country,
                          share: 0,
                          margin: calculatedTradeData?.margin,
                          risk: "",
                          cfd: "",
                          stopLoss: calculatedTradeData?.stopLoss,
                          quantity: calculatedTradeData?.quantity,
                          target: calculatedTradeData?.target,
                          reward: 0,
                          rewardPercent: 0,
                          tradeId: calculatedTradeData?.tradeId,
                          tradingSystemId: calculatedTradeData?.tradingSystemId,
                        });
                      } else {
                        const data = res?.data?.regularMarketPrice?.raw;
                        setPrefillValue({
                          ...prefillValue,
                          currentPrice: data,
                          stockName: calculatedTradeData?.stockCode,
                        });
                        setAllData({
                          ...allData,
                          portFolioId: "",
                          providerName: "",
                          providerId: calculatedTradeData?.providerId,
                          stockCode: calculatedTradeData?.stockCode || "",
                          cummission: 0,
                          entry: calculatedTradeData?.entry,
                          currentPrice: data,
                          country: calculatedTradeData?.country,
                          share: 0,
                          margin: calculatedTradeData?.margin,
                          risk: "",
                          cfd: "",
                          stopLoss: calculatedTradeData?.stopLoss,
                          quantity: calculatedTradeData?.quantity,
                          target: calculatedTradeData?.target,
                          reward: 0,
                          rewardPercent: 0,
                          tradeId: calculatedTradeData?.tradeId,
                          tradingSystemId: calculatedTradeData?.tradingSystemId,
                        });
                      }
                    }
                  }
                );
            } catch (error) {
            }

            // if (calculatedTradeData?.country != undefined) {
            //     let s = portfololioList.filter((val) => {
            //         // 
            //         if (val.country.toUpperCase() == calculatedTradeData?.country.toUpperCase()) {
            //             return val
            //         }
            //     })
            //     setBrokerList(s)
            //     if (s.length < 1) {
            //         showToast('Portfolio not Available for this trade!')
            //     }
            // } else {
            //     setBrokerList(portfololioList)
            // }
        }
    }, [calculatedTradeData])


    const twoDecimal = (value) => {

        var roundedNumber = value;
        var decimalIndex = roundedNumber.indexOf(".");
        if (decimalIndex !== -1) {
            var decimalPlaces = roundedNumber.length - decimalIndex - 1;
            if (decimalPlaces > 2) {
                roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
            }
        }
        return roundedNumber
    }

    const handleInput = (e) => {

        if (e.target.name == 'portFolioId') {
            //    debugger;


            // let filtered=brokerList.filter((val)=>val.portfolioId==Number(bId[1]))
            let selectedPortFolio = portfololioList.filter((val) => e.target.value == val.portfolioId)

            setSelectedPort(selectedPortFolio[0])
            setCurrencyCodes({ ...currencyCodes, portfolioCountryCode: selectedPortFolio[0]?.currency, stockCountryCode })
            let portVal = e.target.value
            if (calculatedTradeData !== undefined) {
                let selectedStockCountry = countryList.filter((val) => val.key.toUpperCase() == calculatedTradeData.country.toUpperCase())
  
                try {
                    // getExchangeRateAPI(selectedStockCountry[0]?.value, selectedPortFolio[0]?.currency).then((response) => {
                        getRapidExchangeRateAPI(selectedStockCountry[0]?.value, selectedPortFolio[0]?.currency).then((response) => {
                        if (response?.status === 200) {
                            // console.log(
                            //   "resp0",
                            //   response?.data?.rates?.[
                            //     selectedPortFolio[0]?.currency
                            //   ]
                            // );
                            let exchange =
                              response?.data?.rates?.[
                                selectedPortFolio[0]?.currency
                              ] || 1;
                            if (exchange) {

                                setExchangerate(exchange);
                            } else {
                                setExchangerate(1);
                            }
                            setAllData({
                                ...allData, [e.target.name]: portVal,
                                risk: '',
                                riskPer: '',
                                quantity: ''
                                // entry: Number(calculatedTradeData.entry * response.result).toFixed(2),
                                // stopLoss: Number(calculatedTradeData.stopLoss * response.result).toFixed(2),
                                // target: Number(calculatedTradeData.target * response.result).toFixed(2),
                            })
                        }
                    })
                } catch (error) {

                }


            } else if (stockCode.length > 0) {
                // stockCountryCode
                let selectedStockCountry = countryList.filter((val) => val.key.toUpperCase() == selectedStock.country.toUpperCase())
                try {
                    // getSearchStockDetails(selectedStock.stockCode).then((res) => {
                        getRapidSearchStockDetails(selectedStock.stockCode).then((res) => {
                          if (res?.status === 200) {
                              // let aus = selectedStock.stockCode.includes(".AX")
         
                              let aus = selectedStock.stockCode.includes("ASX:");
                            //   console.log(
                            //     "selectedPortFolio",
                            //     selectedPortFolio
                            //   );
                            //   debugger

                            try {
                                // getExchangeRateAPI(
                                  getRapidExchangeRateAPI(
                                selectedStockCountry[0]?.value,
                                selectedPortFolio[0]?.currency
                              ).then((response) => {
                                if (response?.status === 200) {
                                  let exchange =
                                    response?.data?.rates?.[
                                      selectedPortFolio[0]?.currency
                                    ] || 1;
                                  setExchangerate(exchange);
                                  if (aus) {
                                    // const price = res.data["close"];
                                    const price = res.data.quotes[0].price;

                                    setAllData({
                                      ...allData,
                                      [e.target.name]: portVal,
                                      entry: Number(price).toFixed(2),
                                      risk: "",
                                      riskPer: "",
                                      quantity: "",
                                    });
                                  } else if (res.data.length > 0) {
                                    const price = res?.data?.regularMarketPrice?.raw;
                                    setAllData({
                                      ...allData,
                                      [e.target.name]: portVal,
                                      entry: Number(price).toFixed(2),
                                      risk: "",
                                      riskPer: "",
                                      quantity: "",
                                    });
                                  }
                                }
                              });
                            } catch (error) {}
                          }
                        });
                } catch (error) {
                }
            } else {
                setSelectedPort(selectedPortFolio[0])
                setAllData({ ...allData, [e.target.name]: e.target.value })
            }

        } else if (e.target.name == "stock") {
            // debugger;
            setSelectedStock(JSON.parse(e.target.value))
            setSelectedStockr(e.target.value)
            setShowStockListDropDown(true)
        } else if (e.target.name === 'stopLoss') {
            // debugger;
            if ((e.target.value >= 0 || e.target.value == '.') && e.target.value != '') {
                if (portFolioId == '') {
                    showToast('Select portfolio first.')
                } else if (stockCode.length <= 0) {
                    showToast('Select stock code first.')
                } else if (entry == '' && entry <= 0) {
                    showToast('Enter first entry price.')
                } else {


                    let value = twoDecimal(e.target.value)
                    if (long === true) {
                        if (Number(entry) <= Number(e.target.value)) {
                            showToast('Set lower stop loss price than entry while long trading')
                        } else {
                            let n = e.target.value.split('.')
                            if (n.length == 2) {
                                if (n[0].length <= 10) {
                                    setAllData({ ...allData, [e.target.name]: value })
                                }
                            } else if (e.target.value.length <= 10) {

                                setAllData({ ...allData, [e.target.name]: value })
                            }
                        }
                    } else {
                        let n = e.target.value.split('.')
                        if (n.length == 2) {
                            if (n[0].length <= 10) {
                                setAllData({ ...allData, [e.target.name]: value })
                            }
                        } else if (e.target.value.length <= 10) {

                            setAllData({ ...allData, [e.target.name]: value })
                        }
                    }

                }
            } else if (e.target.value == '') {
                setAllData({ ...allData, [e.target.name]: e.target.value })
            }
        } else if (e.target.name == "entry") {

            if ((e.target.value >= 0 || e.target.value == '.') && e.target.value != '') {
                if (portFolioId == '') {
                    showToast('Select portfolio first.')
                } else if (stockCode.length <= 0) {
                    showToast('Select stock code first.')
                } else {

                    let value = twoDecimal(e.target.value)
                    let n = e.target.value.split('.')

                    if (n.length == 2) {
                        if (n[0].length <= 10) {
                            setAllData({ ...allData, [e.target.name]: value, target: '', stopLoss: '' })
                        }
                    } else if (e.target.value.length <= 10) {

                        setAllData({ ...allData, [e.target.name]: value, target: '', stopLoss: '' })
                    }


                }
            } else if (e.target.value == '') {
                setAllData({ ...allData, [e.target.name]: e.target.value, target: '', stopLoss: '' })
            }


        } else if (e.target.name == 'target') {

            if ((e.target.value >= 0 || e.target.value == '.') && e.target.value != '') {
                let value = twoDecimal(e.target.value)
                let n = e.target.value.split('.')
                if (n.length == 2) {
                    if (n[0].length <= 10) {
                        setAllData({ ...allData, [e.target.name]: value })
                    }
                } else if (e.target.value.length <= 10) {

                    setAllData({ ...allData, [e.target.name]: value })
                }

            } else if (e.target.value == '') {
                setAllData({ ...allData, [e.target.name]: e.target.value })
            }


        } else if (e.target.name == 'riskPer') {

// debugger;
            let n = Number(e.target.value)
            if ((e.target.value >= 0 && Number(e.target.value) <= 100 && e.target.value != '') || e.target.value == '.') {
                if (portFolioId == '') {
                    showToast('Select portfolio first.')
                } else {
                    var roundedNumber = e.target.value;
                    var decimalIndex = roundedNumber.indexOf(".");
                    if (decimalIndex !== -1) {
                        var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                        if (decimalPlaces > 2) {
                            roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
                        }
                    }
                    if (roundedNumber == '.') {
                        setAllData({ ...allData, [e.target.name]: roundedNumber, risk: '' })
                    } else {

                        let totalBal = (balanceDetails?.currentBalance)
                        let riskDol = totalBal * roundedNumber / 100
                        if (riskDol <= balanceDetails?.currentBalance + accountData.balanceinShares) {

                            setAllData({ ...allData, [e.target.name]: roundedNumber, risk: riskDol > 0 ? riskDol.toFixed(2) : riskDol })
                        }
                    }
                }
            } else if (Number(e.target.value) > 100) {

            } else if (e.target.value == '') {
                setAllData({ ...allData, [e.target.name]: e.target.value, risk: e.target.value })
            }
        } else if (e.target.name == 'risk') {
// debugger;
            if ((Number(e.target.value) >= 0 || e.target.value == '.')) {
                if (portFolioId == '') {
                    showToast('Select portfolio first.')
                } else {
                    if ((Number(e.target.value) >= 0 && Number(e.target.value) <= (balanceDetails?.currentBalance) && e.target.value != '') || e.target.value == '.') {


                        var roundedNumber = e.target.value;
                        var decimalIndex = roundedNumber.indexOf(".");
                        if (decimalIndex !== -1) {
                            var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                            if (decimalPlaces > 2) {
                                roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
                            }
                        }
                        let totalBal = balanceDetails?.currentBalance
                        let riskPers = (roundedNumber * 100) / totalBal

                        if (roundedNumber == '.') {
                            setAllData({ ...allData, [e.target.name]: roundedNumber, riskPer: '' })
                        } else {

                            setAllData({ ...allData, [e.target.name]: roundedNumber, riskPer: riskPers > 0 ? riskPers.toFixed(2) : riskPers })
                        }


                    } else if (Number(e.target.value) >= (balanceDetails?.currentBalance)) {
                    } else if (e.target.value == '') {
                        setAllData({ ...allData, [e.target.name]: e.target.value, riskPer: e.target.value })
                    }
                }
            }




        } else if (e.target.name == 'cfd') {
            
            let value = twoDecimal(e.target.value)
            let valLen = e.target.value.split('.')
            if ((Number(e.target.value) >= 0 || e.target.value == '.') && e.target.value !== '' && share > 0) {



                if (valLen.length == 2) {
                    if (valLen[0].length <= 10) {

                        let n = (Number(value) * 100) / share
                        let as = twoDecimal(n.toString())
                        if (value == '.') {
                            setAllData({ ...allData, [e.target.name]: value, margin: '' })
                        } else {

                            setAllData({ ...allData, [e.target.name]: value, margin: as })
                        }
                    }
                } else if (e.target.value.length <= 10) {
                    let n = (Number(value) * 100) / share
                    let as = twoDecimal(n.toString())
                    if (value == '.') {
                        setAllData({ ...allData, [e.target.name]: value, margin: '' })
                    } else {
                        setAllData({ ...allData, [e.target.name]: value, margin: as })
                    }
                }

            } else if ((Number(e.target.value) >= 0 || e.target.value == '.') && share <= 0 && e.target.value.length <= 10) {

                setAllData({ ...allData, [e.target.name]: value, margin: '' })

            } else if (e.target.value == '') {

                setAllData({ ...allData, [e.target.name]: e.target.value, margin: '' })
            }
            // cfd: (share * margin) / 100
        } else if (e.target.name == 'margin') {
            
            let value = twoDecimal(e.target.value)
            if ((Number(e.target.value) >= 0 && e.target.value !== '') || e.target.value == '.') {
                let valLen = e.target.value.split('.')

                if (valLen.length == 2) {
                    if (valLen[0].length <= 10) {
                        if (value == '.') {
                            setAllData({ ...allData, [e.target.name]: value, cfd: '' })
                        } else {

                            let n = (Number(value) * share) / 100
                            let as = twoDecimal(n.toString())
                            setAllData({ ...allData, [e.target.name]: value, cfd: as })
                        }
                    }
                } else if (e.target.value.length <= 10) {
                    if (value == '.') {
                        setAllData({ ...allData, [e.target.name]: value, cfd: '' })
                    } else {

                        let n = (Number(value) * share) / 100
                        let as = twoDecimal(n.toString())
                        setAllData({ ...allData, [e.target.name]: value, cfd: as })
                    }
                }

            } else if (e.target.value == '') {
                setAllData({ ...allData, [e.target.name]: e.target.value, cfd: '' })
            }

        }
        else {
            setAllData({ ...allData, [e.target.name]: e.target.value })
        }

    }




    // getting stock list of provider
    useEffect(() => {
        if (portFolioId) {
            let data = {
                PageNumber: PageNumber,
                PageSize: PageSize,
                search: search,
                country: selectedPort.country
            }
            if (data.country?.length > 0) {
                try {

                    GetStockByCountry(data).then((res) => {
                        if (res?.status === 200) {
                            setStocksList(res.data.data)
                        }
                    })
                } catch (error) {

                }
            }

            // try {
            //     GetProviderCummission(providerId).then((res) => {
            //         if (res?.status === 200) {
            //             setProviderCummission(res.data)
            //         }
            //     })
            // } catch (error) {

            // }

        }
    }, [portFolioId, search, PageSize])

    // getting cummission of provider
    useEffect(() => {
        if (providerId !== '' && providerId !== undefined) {
            try {
                GetProviderCummission(providerId).then((res) => {
                    if (res?.status === 200) {
                        setProviderCummission(res.data)
                    }
                })
            } catch (error) {

            }

        }
    }, [providerId])

    const getSearchStockData = (selectedStock) => {
        let selectedStockCountry = countryList.filter((val) => val.key.toUpperCase() == selectedStock.country.toUpperCase())
        let symbol = CountryCurrencyList.filter((val) => val.code == selectedStockCountry[0]?.value)

        setCurrencyCodes({ ...currencyCodes, stockCountryCode: selectedStockCountry[0]?.value, stockCountrySymbol: symbol[0]?.symbol_native })

        if (selectedStock !== '' && selectedStock.stockCode !== undefined) {

            // setPrefillValue({ ...prefillValue, stockName: '', currentPrice: '' })
            // setQuantity(0)
            // setErew(0)
            // setAllData({ ...allData, entry: 0, share: 0, margin: 0, risk: 0, cfd: 0, stopLoss: '', quantity: 0, target: '', reward: 0, rewardPercent: 0 })
            try {
                // debugger;
                // getSearchStockDetails(selectedStock.stockCode).then((res) => {
                getRapidSearchStockDetails(selectedStock.stockCode).then((res) => {
                    if (res?.status === 200) {
                      // let aus = selectedStock.stockCode.includes(".AX")
                      let aus = selectedStock.stockCode.includes("ASX:");

                      try {
                        //   getExchangeRateAPI(
                            getRapidExchangeRateAPI(
                          selectedStockCountry[0]?.value,
                          portfolioCountryCode
                        ).then((respons) => {
                            if (respons?.status === 200) {
                            //   console.log(
                            //     "resp2",
                            //     respons?.data?.rates?.[portfolioCountryCode]
                            //   );
                            let exchange =
                              respons?.data?.rates?.[portfolioCountryCode] || 1;
                            setExchangerate(exchange);
                            if (aus) {
                              // let data = res.data["close"];
                              let data = res.data.quotes[0].price;

                              const result = data
                                .toString()
                                .replace(/(\.[0-9]*[1-9])0+$/, "$1");
                              setPrefillValue({
                                ...prefillValue,
                                stockName: selectedStock.stockCode,
                                currentPrice: result,
                              });
                              setAllData({
                                ...allData,
                                stockCode: selectedStock.stockCode,
                                rewardPercent: 0,
                                margin: selectedStock?.margin,
                                stopLoss: "",
                                target: "",
                                share: 0,
                                cfd: 0,
                                country: selectedStock?.country,
                                entry: result,
                                providerId: selectedStock.providerId,
                              });
                              setQuantity(0);
                              setErew(0);
                            } else {
                              let data = res?.data?.regularMarketPrice?.raw;
                              const result = data
                                .toString()
                                .replace(/(\.[0-9]*[1-9])0+$/, "$1");
                              setPrefillValue({
                                ...prefillValue,
                                stockName: selectedStock.stockCode,
                                currentPrice: result,
                              });
                              setAllData({
                                ...allData,
                                stockCode: selectedStock.stockCode,
                                rewardPercent: 0,
                                margin: selectedStock?.margin,
                                stopLoss: "",
                                target: "",
                                share: 0,
                                cfd: 0,
                                country: selectedStock?.country,
                                entry: result,
                                providerId: selectedStock.providerId,
                              });
                              setQuantity(0);
                              setErew(0);
                            }
                          }
                        });
                      } catch (error) {}
                    }
                  }
                );
            } catch (error) {
            }
        }
        setLoadStockData(false)
    }
    // useEffect(() => {
    //     
    //     getSearchStockData(selectedStock)
    // }, [selectedStock.stockCode])

    // input long/short
    const handleInputRedio = (e) => {
        if (e.target.name === 'long') {
            setLong(true)
        } if (e.target.name === 'short') {
            setLong(false)
        }
        setError({ target: true, stopLoss: true })
        setAllData({ ...allData, stopLoss: '', target: '', reward: '', rewardPercent: '' })
    }
    // Handle price target

    const handleInputtarget = (e) => {

        

        if (Number(e.target.value) >= 0 || e.target.value == '.') {
            if (portFolioId == '') {
                showToast('Select portfolio first.')
            } else if (stockCode.length <= 0) {
                showToast('Select stock code first.')
            } else {
                if (entry !== '' && entry > 0) {
                    if (long === false) {
                        if (Number(entry) <= Number(e.target.value)) {
                            showToast('Set lower target price than entry while short trading')
                            // setAllData({ ...allData, target: '' })
                        } else {
                            handleInput(e)
                        }
                    } else {
                        handleInput(e)
                    }
                } else {
                    showToast('Enter first entry price.')
                }
            }
        } else {
            // setAllData({ ...allData, [e.target.name]: e.target.value })
        }

        // handleInput(e)
    }
    // calculation

    const calculateData = () => {

        if (risk !== '' && entry !== '' && stopLoss !== '') {
            let iqt = 0, shr = 0, rewPre = 0, a = 0
            // 
            
            if (long === true) {
                iqt = risk / (entry * exchangeRate - stopLoss * exchangeRate)
                setQuantity(Math.floor(iqt))
            } else {
                if (Number(entry) < Number(stopLoss)) {
                    iqt = risk / (stopLoss * exchangeRate - entry * exchangeRate)
                    // let sq=JSON.stringify(iqt)
                    setQuantity(Math.floor(iqt))
                }
                if (Number(entry) > Number(stopLoss) || stopLoss === '') {
                    iqt = risk / (entry * exchangeRate)
                    setQuantity(Math.floor(iqt))
                }
            }
            if (Number(target) > 0 && Number(target) !== '') {

                if (long === true) {
                    if (Number(target) > Number(entry)) {
                        a = (target * exchangeRate - entry * exchangeRate) * iqt
                    }
                } else {
                    if (Number(entry) > Number(target)) {
                        a = (entry * exchangeRate - target * exchangeRate) * iqt
                    }

                }

                shr = quantity * (entry * exchangeRate)
                rewPre = (a / shr) * 100
                let cm = 0
                for (let i = 0; i < providerCummission.length; i++) {
                    if (providerCummission[i].upToTransValue >= shr && providerCummission[i].aboveTransValue <= shr) {
                        if (providerCummission[i].type == "Percent") {
                            cm = (shr * providerCummission[i].commission) / 100
                        } else {
                            cm = providerCummission[i].commission
                        }
                        break;
                    } else {
                        cm = 0
                    }
                }
                let cfdVal = (shr * margin) / 100
                setErew(a)
                setAllData({ ...allData, cummission: cm, share: shr, cfd: Number(cfdVal).toFixed(2), rewardPercent: rewPre, reward: a })
            }
        }
        else {
            setQuantity('')
        }
        // if (stopLoss==='') {
        // setAllData({ ...allData, quantity: '' })
        // }
    }

    const handleStopLossInput = (e) => {

        if ((Number(e.target.value) >= 0 && e.target.value != '') || e.target.value == '.') {
            if (e.target.value[0] == 0) {
                setStopLossTableData({ ...stopLossTableData, [e.target.name]: e.target.value[1] })
            } else {
                let modifiedString = twoDecimal(e.target.value)
                let n = e.target.value.split('.')
                if (n.length == 2) {
                    if (n[0].length <= 10) {
                        setStopLossTableData({ ...stopLossTableData, [e.target.name]: modifiedString })
                    }
                } else if (e.target.value.length <= 10) {
                    setStopLossTableData({ ...stopLossTableData, [e.target.name]: modifiedString })
                }
            }
        } else if (e.target.value == '') {
            setStopLossTableData({ ...stopLossTableData, [e.target.name]: '' })
        }

    }
    const stopLossTable = () => {

        let exChangeEntery = entry * exchangeRate
        //calculation for first row of stop loss grid
        let r2c1 = 0, r2c2 = 0, r2c3 = 0, r2c4 = 0, r2c5 = 0, r4c1 = 0, r4c2 = 0, r4c3 = 0, r4c4 = 0, r4c5 = 0, r3c1 = 0, r3c2 = 0, r3c3 = 0, r3c4 = 0, r3c5 = 0,
            r5c1 = 0, r5c2 = 0, r5c3 = 0, r5c4 = 0, r5c5 = 0
        if (dValueATR !== '' && dValueATR !== null) {
            setStopLossTableData({ ...stopLossTableData, dValueATR: parseFloat(dValueATR) })
        }
        if (long === true) {

            r2c1 = Number(exChangeEntery) - dValueATR
            r2c2 = Number(exChangeEntery) - dValue2ATR
            r2c3 = (Number(exChangeEntery) - ((Number(exChangeEntery) * dValuePerCent) / 100));
            r2c4 = Number(exChangeEntery) - dValueDollar
            r2c5 = (dStopLossPrices == undefined || dStopLossPrices == '') ? r2c4 : dStopLossPrices
            r4c1 = (Math.abs(((r2c1 - Number(exChangeEntery)) * quantity) - cummission) + Number.EPSILON)
            r4c2 = (Math.abs(((r2c2 - Number(exChangeEntery)) * quantity) - cummission) + Number.EPSILON);
            r4c3 = (Math.abs((((r2c3 - Number(exChangeEntery)) * quantity) - cummission)) + Number.EPSILON);
            r4c4 = (Math.abs(((r2c4 - Number(exChangeEntery)) * quantity) - cummission) + Number.EPSILON);
            r4c5 = (Math.abs(((r2c5 - Number(exChangeEntery)) * quantity) - cummission) + Number.EPSILON);

        } else {
            r2c1 = Number(exChangeEntery) + dValueATR
            r2c2 = Number(exChangeEntery) - dValue2ATR
            r2c3 = (Number(exChangeEntery) - ((Number(exChangeEntery) * dValuePerCent) / 100));
            r2c4 = Number(exChangeEntery) - dValueDollar
            r2c5 = (dStopLossPrices == undefined || dStopLossPrices == '') ? r2c4 : dStopLossPrices
            r4c1 = (Math.abs(((r2c1 - Number(exChangeEntery)) * quantity) - cummission) + Number.EPSILON)
            r4c2 = (Math.abs(((r2c2 - Number(exChangeEntery)) * quantity) - cummission) + Number.EPSILON);
            r4c3 = (Math.abs((((r2c3 - Number(exChangeEntery)) * quantity) - cummission)) + Number.EPSILON);
            r4c4 = (Math.abs(((r2c4 - Number(exChangeEntery)) * quantity) - cummission) + Number.EPSILON);
            r4c5 = (Math.abs(((r2c5 - Number(exChangeEntery)) * quantity) - cummission) + Number.EPSILON);
        }
        let currentbal = balanceDetails?.currentBalance || 0
        if (currentbal != 0 && currentbal != undefined) {
            r3c1 = (r4c1 / currentbal) * 100
            r3c2 = (r4c2 / currentbal) * 100;
            r3c3 = (r4c3 / currentbal) * 100;
            r3c4 = (r4c4 / currentbal) * 100;
            r3c5 = (r4c5 / currentbal) * 100;
        }
        if (r3c1 != '') {
            r5c1 = (rewardPercent / r3c1)
        }
        if (r3c2 != '') {
            r5c2 = (rewardPercent / r3c2)
        }
        if (r3c3 != '') {
            r5c3 = (rewardPercent / r3c3)
        }
        if (r3c4 != '') {
            r5c4 = (rewardPercent / r3c4)
        }
        if (r3c5 != '') {
            r5c5 = (rewardPercent / r3c5)
        }
        setStopLossTableData({ ...stopLossTableData, dRRRPrice: r5c5?.toFixed(2), dRRRDollar: r5c4?.toFixed(2), dRRRPerCent: r5c3?.toFixed(2), dRRR2ATR: r5c2?.toFixed(2), dRRRATR: r5c1?.toFixed(2), dRiskPerPrice: r3c5?.toFixed(2), dRiskPerDollar: r3c4?.toFixed(2), dRiskPerPerCent: r3c3?.toFixed(2), dRiskPer2ATR: r3c2?.toFixed(2), dRiskPerATR: r3c1?.toFixed(2), dRiskDol2ATR: r4c2?.toFixed(2), dRiskDolPerCent: r4c3?.toFixed(2), dRiskDolDollar: r4c4?.toFixed(2), dRiskDolPrice: r4c5?.toFixed(2), dRiskDolATR: r4c1?.toFixed(2), dStopLossDollar: r2c4?.toFixed(2), dStopLossPerCent: r2c3?.toFixed(2), dStopLoss2ATR: r2c2?.toFixed(2), dStopLossATR: Number(r2c1)?.toFixed(2), dValue2ATR: Number(dValueATR) * 2 })
    }
    useEffect(() => {
        if (quantity !== 0 && quantity != '' && quantity !== undefined && Number(entry) > 0) {
            stopLossTable()
        }
    }, [dValueATR, dValue2ATR, entry, quantity, exchangeRate, dStopLossPrices, dValuePerCent, rewardPercent, dValueDollar])
    // useEffect(() => {
    //     setAllData({ ...allData, cfd: (share * margin) / 100 })
    // }, [quantity, entry, share, margin])

    useEffect(() => {
        if (target > 0 && stopLoss > 0 && entry > 0) {
            calculateData()
        }
    }, [stopLoss, risk, entry, long, target, quantity, erew, exchangeRate])


    const getbalanceTrans = () => {
        if (portFolioId !== '' && portFolioId !== undefined) {
            let data = {
                userId: userType?.userId,
                portfolioId: portFolioId
            }
            if (data.userId !== undefined && data.portfolioId !== undefined) {
                try {
                    GetCurrentBalabce(data).then((res) => {
                        if (res?.status === 200) {
                            setBalanceDetails(res.data)
                        }
                    })
                } catch (error) {

                }
            }
            // setBalanceDetails(currentBalanceFun())
            let paramData = {
                portfolioId: portFolioId,
                pageNumber: 1,
                pageSize: 500,
                id: userType?.userId,
            }

            if (paramData.id !== undefined && paramData.portfolioId !== undefined) {
                try {
                    getOpentradeList(paramData).then((res) => {
                        if (res?.status === 200 && res.data.data.length > 0) {
                            let data = res.data.data
                            let pft = 0
                            let bs = 0
                            let bcfd = 0, lev
                            for (let i = 0; i < data.length; i++) {
                                let qty = data[i].qty
                                let transp = data[i].valueInShares
                                try {
                                    // debugger;
                                    // getSearchStockDetails(data[i].stockCode).then((res) => {
                                        getRapidSearchStockDetails(
                                          data[i].stockCode
                                        ).then((res) => {
                                          if (res?.status === 200) {
                                            // let aus = data[i].stockCode.includes('.AX')
                                            let aus =
                                              data[i].stockCode.includes(
                                                "ASX:"
                                              );

                                            let filteredCountry =
                                              countryList.filter(
                                                (value) =>
                                                  value.key.toUpperCase() ==
                                                  data[i].country.toUpperCase()
                                              );
                                            try {
                                                // getExchangeRateAPI(
                                                  getRapidExchangeRateAPI(
                                                filteredCountry[0].value,
                                                data[i].portfolio.currency
                                              ).then((response) => {
                                                  if (response?.status === 200) {
                                                    // console.log(
                                                    //   "resp1",
                                                    //   response?.data?.rates?.[
                                                    //     data[i].portfolio
                                                    //       .currency
                                                    //   ]
                                                    // );
                                                  let exchangeRate =
                                                    response?.data?.rates?.[
                                                      data[i].portfolio.currency
                                                    ] || 1;
                                                  const twoDecimal = (
                                                    value
                                                  ) => {
                                                    var roundedNumber = value;
                                                    var decimalIndex =
                                                      roundedNumber.indexOf(
                                                        "."
                                                      );
                                                    if (decimalIndex !== -1) {
                                                      var decimalPlaces =
                                                        roundedNumber.length -
                                                        decimalIndex -
                                                        1;
                                                      if (decimalPlaces > 3) {
                                                        roundedNumber =
                                                          roundedNumber.slice(
                                                            0,
                                                            decimalIndex + 4
                                                          );
                                                      }
                                                    }
                                                    return roundedNumber;
                                                  };
                                                  if (aus) {
                                                    // const price = res.data["close"];
                                                    const price =
                                                      res.data.quotes[0].price;

                                                    if (
                                                      data[i].short == "true"
                                                    ) {
                                                      let cls =
                                                        price * exchangeRate;
                                                      pft =
                                                        transp -
                                                        cls * qty +
                                                        pft;
                                                    } else {
                                                      let cls =
                                                        price * exchangeRate;
                                                      pft =
                                                        pft +
                                                        cls * qty -
                                                        transp;
                                                    }
                                                    setProfit(
                                                      Number(
                                                        twoDecimal(
                                                          pft.toString()
                                                        )
                                                      )
                                                    );
                                                  } else {
                                                    const price =
                                                      res?.data
                                                        ?.regularMarketPrice
                                                        ?.raw;
                                                    if (
                                                      data[i].short == "true"
                                                    ) {
                                                      let cls =
                                                        price * exchangeRate;
                                                      pft =
                                                        transp -
                                                        cls * qty +
                                                        pft;
                                                    } else {
                                                      let cls =
                                                        price * exchangeRate;
                                                      pft =
                                                        pft +
                                                        (cls * qty - transp);
                                                    }
                                                    setProfit(
                                                      Number(
                                                        twoDecimal(
                                                          pft.toString()
                                                        )
                                                      )
                                                    );
                                                  }
                                                }
                                              });
                                            } catch (error) {}

                                            // setAccountData({ ...accountData, profit: pft.toFixed(2)})
                                          }
                                        });
                                } catch (error) {

                                }
                                bs = bs + data[i].valueInShares
                                bcfd = bcfd + data[i].valueInCFD
                            }
                            lev = (bcfd / bs) * 100
                            setAccountData({ ...accountData, balanceinShares: bs, balanceinCFD: bcfd, leverage: lev })
                        } else {
                            setAccountData({ ...accountData, balanceinShares: 0, balanceinCFD: 0 })
                        }
                    })
                } catch (error) {

                }
            }
        }
    }
    useEffect(() => {
        getbalanceTrans()
    }, [portFolioId, userType?.userId])


    const handleCalculatedData = () => {
        
        setCheckStock(true)
        let vs = (allData.entry * exchangeRate) * quantity

        if (balanceDetails?.currentBalance > vs) {
            if (stockCode !== undefined && stockCode != '') {
                setCheckStock(false)
                setOpenTradeRun(true)
                setStockListPaging({ ...stockListPaging, search: '' })
                setCalculatedData({ ...selectedStock, ...allData, quantity, long, ...prefillValue })
                handleRemoveCalculatedData()
                document.getElementById('open-trade-btn').click()
            }
        } else {
            showToast('Does not have sufficient balance!')
        }
    }
    const handleRemoveCalculatedData = () => {
        setError({ target: true, stopLoss: true })
        setBalanceDetails({
            broker: 0, currentBalance: 0,
            dipositBalanceTill: 0,
            id: 0, portfolioId: 0,
            tradeBalanceTill: 0,
            userId: 0,
            withdrawalBalanceTill: 0
        })
        setProfit(0)
        setAccountData({ profit: 0, balanceinShares: 0, balanceinCFD: 0, leverage: 0 })
        setPrefillValue({ ...prefillValue, stockName: '', currentPrice: '' })
        setStockListPaging({ ...stockListPaging, search: '' })
        setQuantity(0)
        setErew(0)
        setSelectedStockr('')
        setSelectedStock('')
        setStocksList([])
        setShowStockListDropDown(false)
        setAllData({reRender:reRender+1, portFolioId: '', stockCode: '', providerName: '', providerId: '', cummission: 0, entry: '', riskPer: '', share: 0, margin: 0, risk: '', cfd: 0, stopLoss: '', quantity: 0, target: '', reward: 0, rewardPercent: 0 })
        document.querySelectorAll('input').value = 0
        // asd.value=''
        // document.getElementById('myform').reset();
        document.getElementById('closeCalculater').click()
        // }
        // let clsc = document.getElementById('closeCalculater')
        // clsc.click()
    }

    const handleSelectInputStock = (e) => {
        if (portFolioId == '') {
            showToast('Select portfolio first.')
            setShowStockListDropDown(false)
        } else {
            // 
            // setPrefillValue({ ...prefillValue, stockCode: e.target.value })
            setAllData({ ...allData, stockCode: e.target.value })
            setStockListPaging({ ...stockListPaging, search: e.target.value })
            // setAddStockData({ ...addStockData, stockCode: e.target.value })
            setShowStockListDropDown(true)
        }
    }

    const handleSearchSelectStockDetails = (val) => {
        setLoadStockData(true)
        getSearchStockData(val)
        setSelectedStock(val)
        setSelectedStockr(val)
        setShowStockListDropDown(false)
    }



    var selectElement = document.getElementById('selectScroll');
    var page = 1; // Track the page number for pagination
    var isLoading = false; // Track if data is currently being loaded
    // Event handler for scroll event on select element
    selectElement?.addEventListener('scroll', () => {
        // 
        if (selectElement.scrollTop + selectElement.clientHeight >= selectElement.scrollHeight) {
            // User has scrolled to the bottom
            // Load more data if not already loading
            if (!isLoading) {
                loadData()
            }
        }
    });

    // Function to load additional data from the API
    function loadData() {
        isLoading = true; // Set loading state
        setStockListPaging({ ...stockListPaging, PageNumber: 1, PageSize: 100 + PageSize })
    }

    let validationSchema = Yup.object().shape({
        portFolioId: Yup.string().required('Portfolio is required'),
        stopLoss: Yup.string().required('Stop loss is required'),
        risk: Yup.string().required('Risk is required'),
        riskPer: Yup.string().required('Risk (%) is required'),
        target: Yup.string().required('Price Target is required'),
        entry: Yup.string().required('Entry Price is required'),
        stockCode: Yup.string().required('Stock is required'),
    });

    const handleCheckValidation = (e) => {
        //  if (portFolioId == '') {
        //     showToast('Select portfolio first.')
        // } else if (stockCode.length <= 0) {
        //     showToast('Select stock code first.')
        // } else if (entry == '' && entry <= 0) {

        if (entry !== '' && entry > 0 && portFolioId != '' & stockCode.length > 0) {
            if (Number(e.target.value) >= 0) {
                if (e.target.name == 'target') {
                    if (long === true) {
                        if (Number(entry) >= Number(e.target.value)) {
                            showToast('Set higher target price than entry while long trading')

                            setError({ ...error, target: false })
                        } else {
                            setError({ ...error, target: true })
                        }
                    } else {
                        setError({ ...error, target: true })
                    }
                } else {
                    if (long === false) {
                        if (Number(entry) >= Number(e.target.value)) {
                            showToast('Set higher stop loss price than entry while short trading')
                            setError({ ...error, stopLoss: false })
                        } else {
                            setError({ ...error, stopLoss: true })
                        }
                    } else {
                        setError({ ...error, stopLoss: true })
                    }
                }
            }
        }
    }
    useEffect(() => {
        let data = 'USDINR'
        if (portfolioCountryCode !== '' && stockCountryCode !== '') {

        }
    }, [stockCountryCode, portfolioCountryCode])
    // $(document).ready(function () {
    //     $('.restrictedInput').on('keydown', function (event) {
    //         if (
    //             !(event.key === 'Backspace' || event.key === 'Tab' || event.key === 'Enter' || event.key === 'Escape' || event.key === ' ') &&
    //             !event.key.match(/[0-9]|[\d\s\b\t\r\n\f\+\-\.]/) && // Allow digits, spaces, backspace, tab, enter, escape, plus, minus, and dot
    //             !(event.ctrlKey && event.key === 'x') && // Allow Control + x
    //             !(event.ctrlKey && event.key === 'v')
    //         ) {
    //             event.preventDefault();
    //         }
    //     });

    //     $('.restrictedInput').on('input', function () {
    //         // Remove any non-numeric and non-dot characters
    //         $(this).val($(this).val().replace(/[^\d.\-]/g, ''));

    //         // Allow only one dot
    //         const value = $(this).val();
    //         const dotCount = value.split('.').length - 1;
    //         if (dotCount > 1) {
    //             $(this).val(value.substring(0, value.lastIndexOf('.')));
    //         }
    //     });
    // });
    const hideOnBlur = () => {

    }
    $(function () {
        $(document).on('click', function (evt) {

            if ($(evt.target).closest(".dropdown2 > .caption2").length === 0) {
                setShowStockListDropDown(false)
            }
        });
    });
    return (
      <>
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div
                class="modal fade hide backdrop-1"
                data-bs-backdrop="static"
                id="calculator"
                tabindex="-1"
                aria-labelledby="exampleModalLgLabel"
                aria-modal="true"
                role="dialog"
                style={{ display: "none;" }}
              >
                <div class="modal-dialog modal-lg">
                  <div class="modal-content cal-model">
                    <div class="modal-header">
                      <h5 class="modal-title h4" id="exampleModalLgLabel">
                        Calculator
                      </h5>
                      <button
                        type="button"
                        class="btn-close btn-close-provider"
                        data-bs-dismiss="modal"
                        id="closeCalculater"
                        aria-label="Close"
                        onClick={handleRemoveCalculatedData}
                      >
                        ×
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="postion-bx-min mt-2 ">
                            <span class="frm-hd-t">Position Sizing</span>
                          </div>
                          <div class="row">
                            <div class="col-sm-12 mt-1">
                              <div class="postion-min-dta">
                                <Formik
                                  initialValues={allData}
                                  enableReinitialize={true}
                                  validationSchema={validationSchema}
                                  onSubmit={(value) => {
                                    if (
                                      error.stopLoss == true &&
                                      error.target == true
                                    ) {
                                      handleCalculatedData();
                                    }
                                  }}
                                >
                                  {({ errors, touched, values }) => (
                                    <Form>
                                      <div class="row">
                                        <div class="col-sm-6">
                                          <div class="calu-bx-modl mt-1">
                                            <div class="mb-3">
                                              <div className="position-relative mb-2">
                                                <label
                                                  for="formGroupExampleInput"
                                                  class="form-label"
                                                  style={{ color: "#fff" }}
                                                >
                                                  Portfolio Name{" "}
                                                  <span
                                                    style={{ color: "#f00" }}
                                                  >
                                                    *
                                                  </span>
                                                </label>
                                                <select
                                                  class="form-select"
                                                  value={portFolioId}
                                                  onChange={handleInput}
                                                  name="portFolioId"
                                                  type="select"
                                                  id="autoSizingSelectBroker"
                                                >
                                                  <option selected="" value="">
                                                    ---Select---
                                                  </option>
                                                  {brokerList?.map(
                                                    (val, ind) => {
                                                      return (
                                                        <option
                                                          key={ind}
                                                          value={
                                                            val.portfolioId
                                                          }
                                                        >
                                                          {val.portfolioName}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </select>
                                                {errors.portFolioId &&
                                                touched.portFolioId ? (
                                                  <div className="color-msg-err position-absolute">
                                                    {errors.portFolioId}
                                                  </div>
                                                ) : null}
                                              </div>
                                              <div className="dropdown2 mb-2">
                                                <label
                                                  for="formGroupExampleInput"
                                                  class="form-label mt-3"
                                                >
                                                  Stock{" "}
                                                  <span
                                                    style={{ color: "#f00" }}
                                                  >
                                                    *
                                                  </span>
                                                </label>
                                                <div className="position-relative caption2">
                                                  <Field
                                                    autocomplete={"off"}
                                                    name="stockCode"
                                                    class="form-control"
                                                    onClick={() =>
                                                      setShowStockListDropDown(
                                                        !showStockListDropDown
                                                      )
                                                    }
                                                    onBlur={hideOnBlur}
                                                    id="selectScrollInput"
                                                    value={stockCode}
                                                    placeholder="Select Stock"
                                                    onChange={(e) => {
                                                      handleSelectInputStock(e);
                                                    }}
                                                  />
                                                  {errors.stockCode &&
                                                  touched.stockCode ? (
                                                    <div className="color-msg-err position-absolute">
                                                      {errors.stockCode}
                                                    </div>
                                                  ) : null}

                                                  {/* <option selected >Select Stock</option> */}

                                                  {showStockListDropDown && (
                                                    <div
                                                      className="dropdown-search stockOption stockOption_cal"
                                                      id="selectScroll"
                                                    >
                                                      {stocksList.length > 0 &&
                                                      !loadStockData ? (
                                                        stocksList.map(
                                                          (val, ind) => (
                                                            <option
                                                              key={ind}
                                                              onClick={() =>
                                                                !loadStockData &&
                                                                handleSearchSelectStockDetails(
                                                                  val
                                                                )
                                                              }
                                                            >
                                                              {val.stockCode}
                                                            </option>
                                                          )
                                                        )
                                                      ) : (
                                                        <div class="w-100 text-center">
                                                          <h4 class="no-data">
                                                            No data found
                                                          </h4>
                                                        </div>
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                              {/* <select class="form-select" onChange={handleInput} value={selectedStockr} name='stock' type="select"
                                                                                        id="autoSizingSelect"
                                                                                        required="required">
                                                                                        <option selected="">---Select---
                                                                                        </option>
                                                                                        {
                                                                                            stocksList.map((val, ind) => <option key={ind} value={JSON.stringify(val)}>{val.stockCode}</option>)
                                                                                        }
                                                                                    </select> */}
                                              {/* <AsyncLoadingSelect stocksList={stocksList} setSelectedStock={setSelectedStock} /> */}

                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label mt-3"
                                                style={{ color: "#fff" }}
                                              >
                                                Entry Price {stockCountrySymbol}{" "}
                                                <span style={{ color: "#f00" }}>
                                                  *
                                                </span>{" "}
                                              </label>
                                              <Field
                                                type="text"
                                                class="form-control restrictedInput"
                                                onBlur={hideOnBlur}
                                                value={entry}
                                                name="entry"
                                                onChange={handleInput}
                                                id="autoSizingInputEntry"
                                                placeholder="0"
                                              />
                                              {errors.entry && touched.entry ? (
                                                <div className="color-msg-err position-absolute">
                                                  {errors.entry}
                                                </div>
                                              ) : null}
                                              <div class="row mt-4 radio-input">
                                                <label
                                                  for="inputEmail3"
                                                  class="col-sm-4 col-form-label ms-2"
                                                  style={{ color: "#fff" }}
                                                >
                                                  Long / Short
                                                </label>
                                                <div class="col-sm-7 ms-auto">
                                                  <input
                                                    class="form-check-input"
                                                    onClick={handleInputRedio}
                                                    checked={long}
                                                    name="long"
                                                    type="radio"
                                                    id="gridRadios1"
                                                  />
                                                  <label
                                                    class="form-check-label "
                                                    for="gridRadios1"
                                                    style={{ color: "#fff" }}
                                                  >
                                                    Long
                                                  </label>

                                                  <input
                                                    class="form-check-input"
                                                    onClick={handleInputRedio}
                                                    checked={
                                                      long ? false : true
                                                    }
                                                    name="short"
                                                    type="radio"
                                                    id="gridRadios2"
                                                  />
                                                  <label
                                                    class="form-check-label "
                                                    for="gridRadios2"
                                                    style={{ color: "#fff" }}
                                                  >
                                                    Short
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="mb-2">
                                                <label
                                                  for="formGroupExampleInput"
                                                  class="form-label mt-3"
                                                  style={{ color: "#fff" }}
                                                >
                                                  Price Target{" "}
                                                  {stockCountrySymbol}{" "}
                                                  <span
                                                    style={{ color: "#f00" }}
                                                  >
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  type="text"
                                                  class={
                                                    error.target == false
                                                      ? "input-err-color form-control restrictedInput"
                                                      : "form-control restrictedInput"
                                                  }
                                                  name="target"
                                                  onBlur={handleCheckValidation}
                                                  value={target}
                                                  onChange={handleInputtarget}
                                                  id="autoSizingInputPriceTarget"
                                                  placeholder="0"
                                                />
                                                {errors.target &&
                                                touched.target ? (
                                                  <div className="color-msg-err position-absolute">
                                                    {errors.target}
                                                  </div>
                                                ) : null}
                                              </div>

                                              <button
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#cummissionDetails"
                                                class="btn commi-btn-link text-end mt-3 mb-2"
                                              >
                                                Commission Details?
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-sm-6 ">
                                          <div class="calu-bx-modl mt-1">
                                            <div class="col-sm-12 d-flex mb-3">
                                              <div class="col-sm-5 mb-2">
                                                <label
                                                  for="inputEmail3"
                                                  class="form-label"
                                                  style={{ color: "#fff" }}
                                                >
                                                  Risk %{" "}
                                                  <span
                                                    style={{ color: "#f00" }}
                                                  >
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  type="text"
                                                  class="form-control restrictedInput"
                                                  onBlur={hideOnBlur}
                                                  name="riskPer"
                                                  onChange={handleInput}
                                                  value={riskPer}
                                                  id="autoSizingInputRisk"
                                                  placeholder="0"
                                                />
                                                {errors.riskPer &&
                                                touched.riskPer ? (
                                                  <div className="color-msg-err position-absolute">
                                                    {errors.riskPer}
                                                  </div>
                                                ) : null}
                                              </div>
                                              <div class="col-sm-5 mb-2 offset-md-2">
                                                <label
                                                  for="inputEmail3"
                                                  class="form-label"
                                                  style={{ color: "#fff" }}
                                                >
                                                  Risk{" "}
                                                  {selectedPort?.currencySymbol ||
                                                    ""}{" "}
                                                  <span
                                                    style={{ color: "#f00" }}
                                                  >
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  type="text"
                                                  class="form-control restrictedInput"
                                                  onBlur={hideOnBlur}
                                                  name="risk"
                                                  onChange={handleInput}
                                                  value={risk}
                                                  id="autoSizingInputRisk"
                                                  placeholder="0"
                                                />
                                                {errors.risk && touched.risk ? (
                                                  <div className="color-msg-err position-absolute">
                                                    {errors.risk}
                                                  </div>
                                                ) : null}
                                              </div>
                                            </div>

                                            <div class="col-sm-12 mb-3 mt-5px">
                                              <label
                                                for="inputEmail3"
                                                class="form-label"
                                                style={{ color: "#fff" }}
                                              >
                                                Stop Loss {stockCountrySymbol}{" "}
                                                <span style={{ color: "#f00" }}>
                                                  *
                                                </span>
                                              </label>
                                              <Field
                                                type="text"
                                                class={
                                                  error.stopLoss == false
                                                    ? "input-err-color form-control restrictedInput"
                                                    : "form-control restrictedInput"
                                                }
                                                onBlur={handleCheckValidation}
                                                name="stopLoss"
                                                value={stopLoss}
                                                onChange={handleInput}
                                                id="autoSizingInputstopLoss"
                                                placeholder="0"
                                              />
                                              {errors.stopLoss &&
                                              touched.stopLoss ? (
                                                <div className="color-msg-err position-absolute">
                                                  {errors.stopLoss}
                                                </div>
                                              ) : null}
                                            </div>

                                            <div class="col-sm-12 mb-2 mt-12px">
                                              <label
                                                for="inputEmail3"
                                                style={{ color: "#fff" }}
                                                class="form-label"
                                              >
                                                Quantity{" "}
                                              </label>
                                              <input
                                                type="text"
                                                class="form-control"
                                                disabled
                                                name="quantity"
                                                value={quantity}
                                                id="autoSizingInputquantity"
                                                placeholder=""
                                              />
                                            </div>

                                            <div class="col-sm-12 mb-2">
                                              <label
                                                for="inputEmail3"
                                                class="form-label"
                                                style={{ color: "#fff" }}
                                              >
                                                Reward{" "}
                                                {selectedPort?.currencySymbol ||
                                                  ""}
                                              </label>
                                              <input
                                                type="text"
                                                class="form-control"
                                                disabled
                                                name="reward"
                                                value={
                                                  "$ " +
                                                    Number(erew)?.toFixed(2) ==
                                                  "NaN"
                                                    ? 0
                                                    : Number(erew)?.toFixed(2)
                                                }
                                                id="autoSizingInputreward"
                                                placeholder=""
                                              />
                                            </div>

                                            <div class="col-sm-12 mb-2">
                                              <label
                                                for="inputEmail3"
                                                class="form-label"
                                                style={{ color: "#fff" }}
                                              >
                                                Reward %
                                              </label>
                                              <input
                                                type="text"
                                                class="form-control"
                                                disabled
                                                name="rewardPercent"
                                                value={
                                                  Number(
                                                    rewardPercent
                                                  )?.toFixed(2) == "NaN"
                                                    ? 0
                                                    : Number(
                                                        rewardPercent
                                                      )?.toFixed(2) + " %"
                                                }
                                                id="autoSizingInputrewardPercent"
                                                placeholder=""
                                              />
                                            </div>

                                            <button
                                              type="submit"
                                              class="btn commi-btn mt-4 mb-2 ms-auto d-block text-white"
                                            >
                                              Trade this Trade
                                            </button>
                                            <div className="position-relative">
                                              <button
                                                type="button"
                                                data-bs-toggle="modal"
                                                id="open-trade-btn"
                                                data-bs-target="#openTrade"
                                                class="btn open-trade-btn"
                                              ></button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div class="row mt-2"> */}
                        <div class="col-sm-6 ">
                          <div class="postion-bx-min mt-4">
                            <span class="frm-hd-t">Stock Summary</span>

                            <div class="postion-frm mt-1">
                              <form>
                                <div class="col-sm-12 mb-2">
                                  <label
                                    for="inputEmail3"
                                    class="form-label"
                                    style={{ color: "#fff" }}
                                  >
                                    Stock
                                  </label>
                                  <input
                                    type="text"
                                    disabled
                                    class="form-control"
                                    value={stockName}
                                    id="autoSizingInputstockName"
                                    placeholder=""
                                  />
                                </div>

                                <div class="col-sm-12 mb-2">
                                  <label
                                    for="inputEmail3"
                                    class="form-label"
                                    style={{ color: "#fff" }}
                                  >
                                    Margin %
                                  </label>
                                  <input
                                    type="text"
                                    name="margin"
                                    class="form-control"
                                    value={margin}
                                    onChange={handleInput}
                                    id="autoSizingInputmargin"
                                    placeholder="0"
                                  />
                                </div>
                                <div class="col-sm-12 mb-2">
                                  <label
                                    for="inputEmail3"
                                    class="form-label"
                                    style={{ color: "#fff" }}
                                  >
                                    Margin
                                  </label>
                                  <input
                                    type="text"
                                    name="cfd"
                                    class="form-control"
                                    value={cfd}
                                    onChange={handleInput}
                                    id="autoSizingInputcfd"
                                    placeholder="0"
                                  />
                                </div>
                                <div class="col-sm-12 mb-2">
                                  <label
                                    for="inputEmail3"
                                    class="form-label"
                                    style={{ color: "#fff" }}
                                  >
                                    Current Price{" "}
                                    {selectedPort?.currencySymbol || ""}
                                  </label>
                                  <input
                                    type="text"
                                    disabled
                                    class="form-control"
                                    value={
                                      currentPrice > 0
                                        ? Number(currentPrice).toFixed(2)
                                        : currentPrice
                                    }
                                    id="autoSizingInputcurrentPrice"
                                    placeholder=""
                                  />
                                </div>

                                <div class="col-sm-12 mb-2">
                                  <label
                                    for="inputEmail3"
                                    class="form-label"
                                    style={{ color: "#fff" }}
                                  >
                                    Commissions
                                  </label>
                                  <input
                                    type="text"
                                    disabled
                                    class="form-control"
                                    value={cummission?.toFixed(2)}
                                    id="autoSizingInputcummission"
                                    placeholder=""
                                  />
                                </div>

                                <div class="col-sm-12 mb-2">
                                  <label
                                    for="inputEmail3"
                                    class="form-label"
                                    style={{ color: "#fff" }}
                                  >
                                    Share {selectedPort?.currencySymbol || ""}
                                  </label>
                                  <input
                                    type="text"
                                    disabled
                                    class="form-control"
                                    value={share?.toFixed(2)}
                                    id="autoSizingInputshare"
                                    placeholder=""
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 ">
                          <div class="postion-bx-min mt-4">
                            <span class="frm-hd-t">Account Summary</span>

                            <div class="postion-frm mt-1">
                              <form>
                                <div class="col-sm-12 mb-2">
                                  <label
                                    for="inputEmail3"
                                    id="inputEmail3"
                                    class="form-label"
                                    style={{ color: "#fff" }}
                                  >
                                    Current Balance{" "}
                                    {selectedPort?.currencySymbol || ""}
                                  </label>
                                  <input
                                    type="text"
                                    disabled
                                    class="form-control"
                                    value={Number(
                                      balanceDetails?.currentBalance +
                                        accountData.balanceinShares +
                                        profit
                                    )?.toFixed(2)}
                                    id="autoSizingInputbalanceDetails"
                                    placeholder=""
                                  />
                                </div>

                                <div class="col-sm-12 mb-2">
                                  <label
                                    for="inputEmail3"
                                    class="form-label"
                                    style={{ color: "#fff" }}
                                  >
                                    Balance In Margin{" "}
                                    {selectedPort?.currencySymbol || ""}
                                  </label>
                                  <input
                                    type="text"
                                    disabled
                                    class="form-control"
                                    value={accountData?.balanceinCFD.toFixed(2)}
                                    id="autoSizingInputaccountData"
                                    placeholder=""
                                  />
                                </div>

                                <div class="col-sm-12 mb-2">
                                  <label
                                    for="inputEmail3"
                                    class="form-label"
                                    style={{ color: "#fff" }}
                                  >
                                    Balance In Cash{" "}
                                    {selectedPort?.currencySymbol || ""}
                                  </label>
                                  <input
                                    type="text"
                                    disabled
                                    class="form-control"
                                    value={balanceDetails?.currentBalance?.toFixed(
                                      2
                                    )}
                                    id="autoSizingInputbalanceDetails"
                                    placeholder=""
                                  />
                                </div>

                                <div class="col-sm-12 mb-2">
                                  <label for="inputEmail3" class="form-label" style={{color: '#fff'}}>
                                    Profit/ Loss{" "}
                                    {selectedPort?.currencySymbol || ""}
                                  </label>
                                  <input
                                    type="text"
                                    disabled
                                    class="form-control"
                                    value={profit}
                                    id="autoSizingInputprofit"
                                    placeholder=""
                                  />
                                </div>

                                <div class="col-sm-12 mb-2">
                                  <label
                                    for="inputEmail3"
                                    class="form-label"
                                    style={{ color: "#fff" }}
                                  >
                                    Balance in Shares{" "}
                                    {selectedPort?.currencySymbol || ""}
                                  </label>
                                  <input
                                    type="text"
                                    disabled
                                    value={
                                      accountData.balanceinShares > 0
                                        ? accountData.balanceinShares?.toFixed(
                                            2
                                          )
                                        : accountData.balanceinShares
                                    }
                                    class="form-control"
                                    id="autoSizingInputbalanceinShares"
                                    placeholder=""
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12 mt-2 mb-4">
                          <div class="postion-bx-min mt-4">
                            <span class="frm-hd-t frm-hd-t1">StopLoss</span>
                          </div>

                          <div class="table-input">
                            <table class="table table-bordered mt-2 table-input-data">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    class="text-center color-grey"
                                  >
                                    Stop Loss
                                  </th>
                                  <th
                                    scope="col"
                                    class="text-center color-grey"
                                  >
                                    ATR
                                  </th>
                                  <th
                                    scope="col"
                                    class="text-center color-grey"
                                  >
                                    2ATR
                                  </th>
                                  <th
                                    scope="col"
                                    class="text-center color-grey"
                                  >
                                    %{" "}
                                    <span>
                                      <i class="bi bi-caret-down-fill"></i>
                                    </span>
                                    <span>
                                      <i class="bi bi-caret-up-fill"></i>
                                    </span>{" "}
                                  </th>
                                  <th
                                    scope="col"
                                    class="text-center color-grey"
                                  >
                                    {" "}
                                    &nbsp;{" "}
                                  </th>
                                  <th
                                    scope="col"
                                    class="text-center color-grey"
                                  >
                                    Price
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr class="border-bottom-0">
                                  <th class="col-sm-2 white_text">
                                    Stop Loss As
                                  </th>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="dValueATR"
                                      id="autoSizingInputdValueATR"
                                      placeholder=""
                                      value={dValueATR}
                                      onChange={handleStopLossInput}
                                    />
                                  </td>
                                  <td class="col-sm-2 border-bottom-0">
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={dValue2ATR}
                                      disabled
                                      id="autoSizingInputdValue2ATR"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="dValuePerCent"
                                      id="autoSizingInputdValuePerCent"
                                      placeholder=""
                                      value={dValuePerCent}
                                      onChange={handleStopLossInput}
                                    />
                                  </td>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="dValueDollar"
                                      id="autoSizingInputdValueDollar"
                                      placeholder=""
                                      value={dValueDollar}
                                      onChange={handleStopLossInput}
                                    />
                                  </td>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="autoSizingInputdValuePrice"
                                      placeholder=""
                                      value={dValuePrice}
                                      disabled
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th class="col-sm-2 white_text">
                                    Stop Loss Price
                                  </th>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      value={dStopLossATR}
                                      id="autoSizingInputdStopLossATR"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2 border-bottom-0">
                                    <input
                                      type="text"
                                      disabled
                                      value={dStopLoss2ATR}
                                      class="form-control"
                                      id="autoSizingInputdStopLoss2ATR"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      value={dStopLossPerCent}
                                      id="autoSizingInputdStopLossPerCent"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      value={dStopLossDollar}
                                      id="autoSizingInputdStopLossDollar"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="dStopLossPrices"
                                      value={dStopLossPrices}
                                      onChange={handleStopLossInput}
                                      id="autoSizingInputdStopLossPrices"
                                      placeholder=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th className="white_text">Risk %</th>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      value={dRiskPerATR + " %"}
                                      id="autoSizingInputdRiskPerATR"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2 border-bottom-0">
                                    <input
                                      type="text"
                                      disabled
                                      value={dRiskPer2ATR + " %"}
                                      class="form-control"
                                      id="autoSizingInputdRiskPer2ATR"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      value={dRiskPerPerCent + " %"}
                                      id="autoSizingInputdRiskPerPerCent"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      value={dRiskPerDollar + " %"}
                                      id="autoSizingInputdRiskPerDollar"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      value={dRiskPerPrice + " %"}
                                      id="autoSizingInputdRiskPerPrice"
                                      placeholder=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th className="white_text">Risk</th>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      value={" $ " + dRiskDolATR}
                                      id="autoSizingInputdRiskDolATR"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2 border-bottom-0">
                                    <input
                                      type="text"
                                      disabled
                                      value={" $ " + dRiskDol2ATR}
                                      class="form-control"
                                      id="autoSizingInputdRiskDol2ATR"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      value={" $ " + dRiskDolPerCent}
                                      id="autoSizingInputdRiskDolPerCent"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      value={" $ " + dRiskDolDollar}
                                      id="autoSizingInputdRiskDolDollar"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      value={" $ " + dRiskDolPrice}
                                      id="autoSizingInputdRiskDolPrice"
                                      placeholder=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th className="white_text">RRR</th>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      value={dRRRATR}
                                      id="autoSizingInputdRRRATR"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2 border-bottom-0">
                                    <input
                                      type="text"
                                      disabled
                                      value={dRRR2ATR}
                                      class="form-control"
                                      id="autoSizingInputdRRR2ATR"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      value={dRRRPerCent}
                                      id="autoSizingInputdRRRPerCent"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      value={dRRRDollar}
                                      id="autoSizingInputdRRRDollar"
                                      placeholder=""
                                    />
                                  </td>
                                  <td class="col-sm-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      value={dRRRPrice}
                                      id="autoSizingInputdRRRPrice"
                                      placeholder=""
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CummissionDetails cummissionData={providerCummission} />
        {/* <CreateTrade allData={allData} portfololioList={portfololioList} setRunPortFolioAPI={setRunPortFolioAPI} providerList={providerList} /> */}
      </>
    );
}

export default TradeCalculater