import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { getPortfoloio } from "../../../services/PortfolioService";
import { GetAccountHistory } from "../../../services/ProviderServices";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import {
  GetUserTradesList,
  GetUserCloseTradesList,
} from "../../../services/TradeService";
import { getRapidExchangeRateAPI } from "../../../services/commonServices";
import { getRapidSearchStockDetails } from "../../../services/HomeService";
import { toast } from "react-toastify";
import BarChart from "../../NewCharts/BarChart";
import SmallColumn from "../../portfolioSummary/SmallColumn";
import BiggerColumn from "../../portfolioSummary/BiggerColumn";
import TableWorstTop from "../../portfolioSummary/TableWorstTop";
import DatePicker from "react-datepicker";
import BarChart2 from "../../NewCharts/BarChart2";
import BarChart3 from "../../NewCharts/BarChart3";

const Summary = ({ countryList }) => {
  let currentDate = new Date();
  let currentyear = currentDate.getFullYear();
  const [profit, setProfit] = useState(0);
  const [portfolioCreatedDate, setPorfolioCreatedDate] = useState("");
  const [portfolioList, setPortfolioList] = useState([]);
  const [showPortfolioName, setShowPorfolioName] = useState("");
  const [runPortFolioAPI, setRunPortFolioAPI] = useState(true);
  const [userType, setUserType] = useState("");
  const [yearList, setYearList] = useState([]);
  const [isThereCloseData, setIsThereCloseData] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [dateRange, setDateRange] = useState({ from: "", to: "" });
  const [showYearList, setShowYearList] = useState(false);
  const [showMonthList, setShowMonthList] = useState(false);
  const [activeTab, setActiveTab] = React.useState("monthly");
  const [portfolioCurrency, setPortfolioCurrency] = useState("");
  const [initialValue, setInitialValue] = useState({
    providerId: "",
    gridRadios: 0,
    month: "",
    year: "",
  });
  const [mainTab, setMainTab] = useState("open");
  const [disableSave, setDisableSave] = useState(false);
  const [error, setError] = useState();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [currencyName, setCurrencyName] = useState("");
  const [totalProfitRatio, setTotalProfitRatio] = useState(null);
  const [closeTotalProfitRatio, setCloseTotalProfitRatio] = useState(null);
  const [totalLossRatio, setTotalLossRatio] = useState(null);
  const [closeTotalLossRatio, setCloseTotalLossRatio] = useState(null);
  const [totalProfit, setTotalProfit] = useState(null);
  const [closeTotalProfit, setCloseTotalProfit] = useState(null);
  const [totalLoss, setTotalLoss] = useState(null);
  const [closeTotalLoss, setCloseTotalLoss] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [barChartData, setBarChartData] = useState([]);
  const [closeBarChartData, setCloseBarChartData] = useState([]);
  const [pieChartData1, setPieChartData1] = useState(null);
  const [closePieChartData1, setClosePieChartData1] = useState(null);
  const [countHold, setCountHold] = useState(0);
  const [monthlyBar, setMonthlyBar] = useState([]);
  const [monthlyRatio, setMonthlyRatio] = useState([]);
  const [closeMonthlyBar, setCloseMonthlyBar] = useState([]);
  const [closeMonthlyRatio, setCloseMonthlyRatio] = useState([]);
  const [closeCountHold, setCloseCountHold] = useState(0);
  const [formattedRatio, setFormattedRatio] = useState("");
  const [numberOfProfitLoss, setNumberOfProfitLoss] = useState({
    openProfitStockss: 0,
    openLossStocks: 0,
    closeProfitStockss: 0,
    closeLossStocks: 0,
  });
  const [topThreeStocks, setTopThreeStocks] = useState([]);
  const [worstThreeStocks, setWorstThreeStocks] = useState([]);
  const [userBalance, setUserBalance] = useState("");
  const [leverage, setLeverage] = useState({
    openLeverage: 0,
    closeLeverage: 0,
  });
  const [totalDepositWithdrawl, setTotalDepositWithdrawl] = useState({
    totalWithdrawals: 0,
    totalDeposits: 0,
  });
  const [combineStockData, setCombineStockData] = useState([]);
  const [financeYearType, setFinanceYearType] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedSecondDate, setSelectedSecondDate] = useState();
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();

  let validationSchema = Yup.object().shape({
    providerId: Yup.string().required("Provider is required"),
    gridRadios: Yup.string().required(
      "Please select an option (Both/Long/Short)"
    ),
    // month: Yup.string().required("Select a month"),
    // year: Yup.string().required("Select a year"),
  });

  const getTopPerformingStocks = (stockData) => {
    // Calculate profit for each stock and sort in descending order
    const stocksWithProfit = stockData.map((stock) => {
      const profit =
        stock.short === "true"
          ? stock.valueInShares - stock.transactionPrice * stock.qty
          : stock.transactionPrice * stock.qty - stock.valueInShares;

      return { ...stock, profit };
    });

    // Filter only profitable stocks and sort by profit
    const sortedStocks = stocksWithProfit
      .filter((stock) => stock.profit > 0) // Include only stocks with positive profit
      .sort((a, b) => b.profit - a.profit); // Sort by profit in descending order

    // Return top three
    return sortedStocks.slice(0, 3);
  };

  const getWorstPerformingStocks = (stockData) => {
    // Calculate loss for each stock and sort in ascending order of loss
    const stocksWithLoss = stockData.map((stock) => {
      const loss =
        stock.short === "true"
          ? stock.valueInShares - stock.transactionPrice * stock.qty
          : stock.transactionPrice * stock.qty - stock.valueInShares;

      return { ...stock, loss };
    });

    // Filter only losing stocks and sort by loss in ascending order (most negative first)
    const sortedStocks = stocksWithLoss
      .filter((stock) => stock.loss < 0) // Include only stocks with negative profit (loss)
      .sort((a, b) => a.loss - b.loss); // Sort by loss in ascending order (most negative)

    // Return top three worst-performing stocks
    return sortedStocks.slice(0, 3);
  };

  const handleLongShort = (val) => {
    setInitialValue({ ...initialValue, gridRadios: val, stopLossPrice: "" });

    setError({ ...error, stopLoss: true });
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Use Intl.DateTimeFormat with expanded options
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  const handlePortfolioChange = (event) => {
    const selectedId = event.target.value;
    const selectedPortfolio = portfolioList?.find((portfolio) => {
      return String(portfolio?.portfolioId) === selectedId;
    });
    const yupcreateddate = formatDate(selectedPortfolio?.createdDate);
   // console.log("selectedPortfolio", selectedPortfolio);
    if (selectedPortfolio) {
      setPorfolioCreatedDate(yupcreateddate);
      setSelectedPortfolio(selectedPortfolio);
      setShowPorfolioName(selectedPortfolio?.portfolioName);
      setCurrencyName(selectedPortfolio?.currency);
      setPortfolioCurrency(selectedPortfolio?.currencySymbol);
      setInitialValue({
        ...initialValue,
        providerId: selectedId,
      });

      const createdYear = new Date(
        selectedPortfolio?.createdDate
      ).getFullYear();
      const currentYear = new Date().getFullYear();
      const years = Array.from(
        { length: currentYear - createdYear + 1 },
        (_, index) => createdYear + index
      );
      setYearList(years);
     // console.log("Selected Portfolio:", selectedPortfolio);
    }
  };

  const handleChangeFinanceYear = (ev) => {
    //
    setFinanceYearType(ev);

    if (ev == "0") {
      setMinDate(new Date(currentyear, 6, 1));
      setMaxDate(new Date(currentyear + 1, 5, 30));
      setSelectedDate(new Date(currentyear, 6, 1));
      setSelectedSecondDate(new Date(currentyear + 1, 5, 30));
    } else if (ev == "1") {
      setMinDate(new Date(currentyear, 0, 1));
      setMaxDate(new Date(currentyear, 11, 31));
      setSelectedDate(new Date(currentyear, 0, 1));
      setSelectedSecondDate(new Date(currentyear, 11, 31));
    } else if (ev == "2") {
      setMinDate(new Date(currentyear - 1, 6, 1));
      setMaxDate(new Date(currentyear, 5, 30));
      setSelectedDate(new Date(currentyear - 1, 6, 1));
      setSelectedSecondDate(new Date(currentyear, 5, 30));
    } else if (ev == "3") {
      setMinDate(new Date(currentyear - 1, 0, 1));
      setMaxDate(new Date(currentyear - 1, 11, 31));
      setSelectedDate(new Date(currentyear - 1, 0, 1));
      setSelectedSecondDate(new Date(currentyear - 1, 11, 31));
    }
  };

  const calculateGCD = (a, b) => {
    while (b !== 0) {
      const temp = b;
      b = a % b;
      a = temp;
    }
    return a;
  };

  const balanceCheck = async () => {
    let accountprams = {
      portfolioId: selectedPortfolio.portfolioId,
      id: userType?.userId,
      dateFrom: moment(selectedDate).format("MM/DD/YYYY"),
      dateTo: moment(selectedSecondDate).format("MM/DD/YYYY"),
      // dateFrom: dateRange.from,
      // dateTo: dateRange.to,
    };
    let totalWithdrawals = 0;
    let totalDeposits = 0;
    try {
      const response = await GetAccountHistory(accountprams);
      debugger;
      if (response.status === 200) {
        let fetchData = response.data;
        if (!fetchData || fetchData?.length === 0) {
          return 0; // No transactions, no balance
        }
        fetchData?.forEach((transaction) => {
          // if (["sell", "Close Trade"].includes(transaction.type)) {
          //   totalWithdrawals += transaction.amount;
          // } else if (["buy", "Open Trade"].includes(transaction.type)) {
          //   totalDeposits += transaction.amount;
          // }
          if (["Withdrawal"].includes(transaction.type)) {
            totalWithdrawals += transaction.amount;
          } else if (
            ["Deposit", "Starting Balance"].includes(transaction.type)
          ) {
            totalDeposits += transaction.amount;
          }
        });
        // console.log("Total Withdrawals:", totalWithdrawals);
        // console.log("Total Deposits:", totalDeposits);
        setTotalDepositWithdrawl({ totalWithdrawals, totalDeposits });
        // Find the transaction with the latest createdDate
        const latestTransaction = fetchData?.reduce((latest, current) =>
          new Date(current.createdDate) > new Date(latest.createdDate)
            ? current
            : latest
        );

        // The runningBalance of the latest transaction is the user's balance
        return latestTransaction.runningBalance;
      }
    } catch (error) {}
  };

  const getMonthsBetweenDates = (dateFrom, dateTo) => {
    const start = moment(dateFrom, "MM/DD/YYYY");
    const end = moment(dateTo, "MM/DD/YYYY");
    const months = [];

    while (start.isSameOrBefore(end, "month")) {
      months.push(start.format("MMMM")); // Get full month name
      start.add(1, "month");
    }

    return months;
  };

  const bar = [[]];

  const submitForm = async () => {
    debugger;
    setButtonDisable(true);
    setIsLoading(true);
  //  console.log("selectedPortfolio", selectedPortfolio);
    let FinancelYear = {
      // providerId: selectedUserData.providerId,
      portfolioId: selectedPortfolio.portfolioId,
      id: userType?.userId,
      dateFrom: moment(selectedDate).format("MM/DD/YYYY"),
      dateTo: moment(selectedSecondDate).format("MM/DD/YYYY"),
      // dateFrom: dateRange.from,
      // dateTo: dateRange.to,
      short: initialValue?.gridRadios,
    };
    try {
      const months = getMonthsBetweenDates(
        FinancelYear?.dateFrom,
        FinancelYear?.dateTo
      );
      let monthProfits = {};
      let closemonthProfits = {};
      months.forEach((month) => {
        monthProfits[month] = { profit: 0, loss: 0 }; // Initialize profits and losses for each month
        closemonthProfits[month] = { profit: 0, loss: 0 }; // Initialize for close trades
      });

      // const barDatait = generateBarData(months);
      // setMonthlyBar(barDatait);
      // console.log("barDatait", barDatait);
      let barChart1 = [];
      let combine = [];
      const balance = await balanceCheck();
   //   console.log("bb", balance);
      setUserBalance(balance);
      const resTrade = await GetUserTradesList(FinancelYear);
      const resCloseTrade = await GetUserCloseTradesList(FinancelYear);

      if (resTrade?.status == 200) {
        //   console.log("res?.data", resTrade);
        let data = resTrade?.data;
        let pft = 0;
        let loss = 0;
        let bs = 0;
        let bcfd = 0;
        let tradeProfit = 0,
          levs;
        if (data?.length == 0) {
          //  toast.error("No stock found");
          setIsLoading(false);
          setInitialValue({
            ...initialValue,
            providerId: "",
            gridRadios: 0,
            month: "",
            year: "",
          });
          //  combine = [];
          handleChangeFinanceYear(0);
          setTotalLoss(null);
          setTotalProfit(null);
          setTotalLossRatio(null);
          setTotalProfitRatio(null);
          setBarChartData([]);
          setMonthlyBar([]);
        }

        combine = [...combine, ...resTrade?.data];

        const uniqueStockCodes = new Set();

        for (let i = 0; i < data.length; i++) {
          uniqueStockCodes.add(data[i].stockCode);
          let qty = data[i].qty;
          let transp = data[i].valueInShares;
          let stockCodes = data[i].stockCode;
          let tradeDate = moment(data[i].openDate).format("MMMM");
          //   setCountHold(uniqueStockCodes);
          try {
            // getSearchStockDetails(data[i].stockCode).then((res) => {
            const rapidSearchDetailRes = await getRapidSearchStockDetails(
              data[i].stockCode
            );

            if (rapidSearchDetailRes?.status === 200) {
              //  let aus = data[i].stockCode.includes('.AX')
              let aus = data[i].stockCode.includes("ASX:");

              let filteredCountry = countryList?.filter((value) => {
                return value.key.toUpperCase() == data[i].country.toUpperCase();
              });

              try {
                const exchangeRes = await getRapidExchangeRateAPI(
                  filteredCountry[0]?.value,
                  data[i].portfolio.currency
                );

                if (exchangeRes?.status === 200) {
                  const exchangeRate = Number(
                    exchangeRes?.data?.rates?.[data[i].portfolio.currency] || 1
                  );
                  const isASX = data[i].stockCode.includes("ASX:");
                  const price = isASX
                    ? rapidSearchDetailRes?.data?.quotes[0]?.price
                    : rapidSearchDetailRes?.data?.regularMarketPrice?.raw;

                  if (!price) throw new Error("Price not available");

                  const cls = price * exchangeRate;
                  const shortTrade = data[i]?.short === "true";

                  // tradeProfit = shortTrade
                  //   ? transp - cls * qty + tradeProfit
                  //   : tradeProfit + cls * qty - transp;
                  if (shortTrade) {
                    pft = transp - cls * qty + pft;
                    //  tradeProfit = transp - cls * qty + tradeProfit;
                  } else {
                    pft = pft + (cls * qty - transp);
                    // tradeProfit = tradeProfit + (cls * qty - transp);
                  }
                  barChart1.push([stockCodes, pft]);
                  // Update loss if profit is negative

                  //  if (monthProfits[tradeDate] !== undefined) {
                  //    monthProfits[tradeDate] += tradeProfit;
                  //  }
                  if (pft < 0) {
                    loss += Math.abs(pft);
                    pft = 0; // Reset profit to avoid double counting
                  }

                  const total = pft + loss;

                  const profitPercentage = (pft / total) * 100;
                  const lossPercentage = (loss / total) * 100;
                //  console.log("profit-loss", pft);
                  const gcd = calculateGCD(Math.abs(pft), Math.abs(loss));
                  // const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
                  //  const divisor = gcd(profit, loss);
                  //const simplifiedProfit = profit / divisor;
                  // const simplifiedLoss = loss / divisor;
                  const simplifiedProfit = Math.abs(pft) / gcd;
                  const simplifiedLoss = Math.abs(loss) / gcd;
                  const profitLossRatio = `${simplifiedProfit}:${simplifiedLoss}`;
                  const profitLossData = [
                    { x: "Profit", value: simplifiedProfit },
                    { x: "Loss", value: simplifiedLoss },
                  ];
                  if (monthProfits[tradeDate] === undefined) {
                    monthProfits[tradeDate] = { profit: 0, loss: 0 };
                  }
                  monthProfits[tradeDate].profit = pft;
                  monthProfits[tradeDate].loss = -loss;
                  const monthProfitLossRatios = [];
                  const monthProfitsArray = Object.entries(monthProfits).map(
                    ([month, { profit, loss }]) => [month, profit, loss]
                  );

                  const totalMonthProfit = monthProfitsArray.reduce(
                    (sum, [, profit]) => sum + profit,
                    0
                  );
                  const totalMonthLoss = monthProfitsArray.reduce(
                    (sum, [, , loss]) => sum + loss,
                    0
                  );

                  monthProfitsArray?.forEach(([month, profit, loss]) => {
                    const total = profit + Math.abs(loss);

                    const profitRatio =
                      total !== 0 ? (profit / total) * 100 : 0; // Avoid divide-by-zero error
                    const lossRatio =
                      total !== 0 ? (Math.abs(loss) / total) * 100 : 0;

                    // Push [month, profitRatio, lossRatio] to the new array
                    monthProfitLossRatios?.push([
                      month,
                      Number(profitRatio.toFixed(2)),
                      Number(lossRatio.toFixed(2)),
                    ]);
                  });
                  setMonthlyRatio(monthProfitLossRatios);
                  // console.log(
                  //   "tradeprofit",
                  //   monthProfitsArray,
                  //   monthProfitLossRatios,
                  //   totalMonthProfit,
                  //   totalMonthLoss
                  // );
                  setMonthlyBar(monthProfitsArray);
                  setPieChartData1(profitLossData);
                  setProfit(pft);
                  setTotalProfitRatio(profitPercentage);
                  setTotalLossRatio(lossPercentage);
                  setTotalProfit(totalMonthProfit.toFixed(5));
                  setTotalLoss(totalMonthLoss.toFixed(5));
              //    console.log("stockname", stockCodes);

                  // console.log(
                  //   `Profit: ${pft.toFixed(5)}, Loss: ${loss.toFixed(
                  //     5
                  //   )}, Total: ${total.toFixed(5)}`
                  // );
                }
              } catch (error) {
                console.error("Error in profit/loss calculation:", error);
              }
            }
          } catch (error) {}
          bs = bs + data[i].valueInShares;
          bcfd = bcfd + data[i].valueInCFD;
        }
        levs = (bcfd / bs) * 100;
        setLeverage((prevLeverage) => ({
          ...prevLeverage,
          openLeverage: levs,
        }));

        setBarChartData(barChart1);

        setIsLoading(false);
        // setInitialValue({
        //   ...initialValue,
        //   providerId: "",
        //   gridRadios: 0,
        //   // month: "",
        //   // year: "",
        // });
        // handleChangeFinanceYear(0);
      }

      if (resCloseTrade?.status == 200) {
        debugger;
        let barChart2 = [];
        let data = resCloseTrade?.data;
        let pft = 0;
        let loss = 0;
        let bs = 0;
        let bcfd = 0,
          lev;
        if (data?.length == 0) {
          setIsThereCloseData(true);
          setCloseBarChartData([]);
          setCloseTotalProfit(null);
          setClosePieChartData1(null);
          setCloseTotalLoss(null);
          setCloseMonthlyBar([]);
          handleChangeFinanceYear(0);
          //  combine = [];
          // toast.error("No stock found");
        }
        const uniqueStockCodes = new Set();
        combine = [...combine, ...resCloseTrade?.data];

        for (let i = 0; i < data.length; i++) {
          let tradeDate = moment(data[i].openDate).format("MMMM");
          uniqueStockCodes.add(data[i].stockCode);
          setIsThereCloseData(false);
          let qty = data[i].qty;
          let transp = data[i].valueInShares;
          let stockCodes = data[i].stockCode;
          //   setCloseCountHold(uniqueStockCodes);
          try {
            // getSearchStockDetails(data[i].stockCode).then((res) => {
            const rapidSearchDetailRes = await getRapidSearchStockDetails(
              data[i].stockCode
            );

            if (rapidSearchDetailRes?.status === 200) {
              //  let aus = data[i].stockCode.includes('.AX')
              let aus = data[i].stockCode.includes("ASX:");

              let filteredCountry = countryList.filter(
                (value) =>
                  value.key.toUpperCase() == data[i].country.toUpperCase()
              );

              try {
                const exchangeRes = await getRapidExchangeRateAPI(
                  filteredCountry[0]?.value,
                  data[i].portfolio.currency
                );

                if (exchangeRes?.status === 200) {
                  const exchangeRate = Number(
                    exchangeRes?.data?.rates?.[data[i].portfolio.currency] || 1
                  );
                  const isASX = data[i].stockCode.includes("ASX:");
                  const price = isASX
                    ? rapidSearchDetailRes?.data?.quotes[0]?.price
                    : rapidSearchDetailRes?.data?.regularMarketPrice?.raw;

                  if (!price) throw new Error("Price not available");

                  const cls = price * exchangeRate;
                  const shortTrade = data[i]?.short === "true";

                  if (shortTrade) {
                    pft = transp - cls * qty + pft;
                  } else {
                    pft = pft + (cls * qty - transp);
                  }

                  if (shortTrade) {
                    closemonthProfits[tradeDate] =
                      closemonthProfits[tradeDate] !== undefined
                        ? transp - cls * qty + closemonthProfits[tradeDate]
                        : transp - cls * qty;
                  } else {
                    closemonthProfits[tradeDate] =
                      closemonthProfits[tradeDate] !== undefined
                        ? closemonthProfits[tradeDate] + (cls * qty - transp)
                        : cls * qty - transp;
                  }
                  barChart2.push([stockCodes, pft]);
                  // Update loss if profit is negative
                  if (pft < 0) {
                    loss += Math.abs(pft);
                    pft = 0; // Reset profit to avoid double counting
                  }

                  const total = pft + loss;

                  const profitPercentage = (pft / total) * 100;
                  const lossPercentage = (loss / total) * 100;
                //  console.log("profit-loss", profitPercentage, lossPercentage);
                  const gcd = calculateGCD(Math.abs(pft), Math.abs(loss));
                  // const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
                  //  const divisor = gcd(profit, loss);
                  //const simplifiedProfit = profit / divisor;
                  // const simplifiedLoss = loss / divisor;
                  const simplifiedProfit = Math.abs(pft) / gcd;
                  const simplifiedLoss = Math.abs(loss) / gcd;
                  const profitLossRatio = `${simplifiedProfit}:${simplifiedLoss}`;
                  const profitLossData = [
                    { x: "Profit", value: simplifiedProfit },
                    { x: "Loss", value: simplifiedLoss },
                  ];

                  if (typeof closemonthProfits[tradeDate] !== "object") {
                    closemonthProfits[tradeDate] = { profit: 0, loss: 0 };
                  }
                  closemonthProfits[tradeDate].profit = pft;
                  closemonthProfits[tradeDate].loss = -loss;
                  const closemonthProfitLossRatios = [];
                  const monthProfitsArray = Object.entries(
                    closemonthProfits
                  ).map(([month, { profit, loss }]) => [month, profit, loss]);

                  const totalMonthProfit = monthProfitsArray.reduce(
                    (sum, [, profit]) => sum + profit,
                    0
                  );
                  const totalMonthLoss = monthProfitsArray.reduce(
                    (sum, [, , loss]) => sum + loss,
                    0
                  );
                  monthProfitsArray?.forEach(([month, profit, loss]) => {
                    const total = profit + Math.abs(loss);

                    const profitRatio =
                      total !== 0 ? (profit / total) * 100 : 0; // Avoid divide-by-zero error
                    const lossRatio =
                      total !== 0 ? (Math.abs(loss) / total) * 100 : 0;

                    // Push [month, profitRatio, lossRatio] to the new array
                    closemonthProfitLossRatios?.push([
                      month,
                      Number(profitRatio.toFixed(2)),
                      Number(lossRatio.toFixed(2)),
                    ]);
                  });
                  // console.log(
                  //   "tradeprofit2",
                  //   monthProfitsArray,
                  //   closemonthProfitLossRatios
                  // );
                  setCloseMonthlyRatio(closemonthProfitLossRatios);
                  setCloseMonthlyBar(monthProfitsArray);
                  setCloseBarChartData(barChart2);
                  setClosePieChartData1(profitLossData);
                  setCloseTotalProfitRatio(profitPercentage);
                  setCloseTotalLossRatio(lossPercentage);
                  setCloseTotalProfit(totalMonthProfit.toFixed(5));
                  setCloseTotalLoss(totalMonthLoss.toFixed(5));
                 // console.log("stockname", stockCodes);
                }
              } catch (error) {
                console.error("Error in profit/loss calculation:", error);
              }
            }
          } catch (error) {}
          bs = bs + data[i].valueInShares;
          bcfd = bcfd + data[i].valueInCFD;
        }
        lev = (bcfd / bs) * 100;
        setLeverage((prevLeverage) => ({
          ...prevLeverage,
          closeLeverage: lev,
        }));
      }
   //   console.log("Combined Data:", combine);
      if (combine?.length === 0) {
        setTopThreeStocks([]);
        setWorstThreeStocks([]);
        toast.error("stock not found");
        return;
      }

      setCombineStockData(combine);
      const topThreeStocks = await getTopPerformingStocks(combine);
      const worstThreeStocks = await getWorstPerformingStocks(combine);
      setWorstThreeStocks(worstThreeStocks);
      setTopThreeStocks(topThreeStocks);
     // console.log("topThreeStocks", topThreeStocks, worstThreeStocks);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    // console.log("pieChartData1", pieChartData1);
    // console.log(
    //   "closePieChartData1",
    //   leverage.openLeverage,
    //   leverage.closeLeverage
    // );
  }, [
    pieChartData1,
    closePieChartData1,
    leverage,
    numberOfProfitLoss,
    leverage.openLeverage,
    leverage.closeLeverage,
  ]);

  useEffect(() => {
    // console.log(
    //   "profit profitratio lossratio profittotal losstotal",
    //   profit,
    //   totalProfitRatio,
    //   totalLossRatio,
    //   totalProfit,
    //   totalLoss,
    //   closeTotalProfit
    // );
  }, [
    profit,
    totalLossRatio,
    totalProfitRatio,
    totalLoss,
    totalProfit,
    closeTotalProfitRatio,
    closeTotalLossRatio,
    topThreeStocks,
    worstThreeStocks,
  ]);

  useEffect(() => {
    const positiveStocks = new Set();
    const negativeStocks = new Set();
    const closepositiveStocks = new Set();
    const closenegativeStocks = new Set();
    debugger;
    if (mainTab === "open") {
      barChartData?.forEach((item) => {
        if (item[1] > 0) {
          positiveStocks.add(item[0]); // Add stock name to positive set if profit
        } else if (item[1] < 0) {
          negativeStocks.add(item[0]); // Add stock name to negative set if loss
        }
      });
    } else {
      closeBarChartData?.forEach((item) => {
        if (item[1] > 0) {
          closepositiveStocks.add(item[0]); // Add stock name to positive set if profit
        } else if (item[1] < 0) {
          closenegativeStocks.add(item[0]); // Add stock name to negative set if loss
        }
      });
    }

    // Update the state with unique stock counts
    setNumberOfProfitLoss({
      openProfitStockss: positiveStocks.size || 0,
      openLossStocks: negativeStocks.size || 0,
      closeProfitStockss: closepositiveStocks.size || 0,
      closeLossStocks: closenegativeStocks.size || 0,
    });
    var it = positiveStocks.size + negativeStocks.size;
    var out = closepositiveStocks.size + closenegativeStocks.size;
    setCountHold(it);
    setCloseCountHold(out);
  }, [barChartData, setNumberOfProfitLoss, closeBarChartData, mainTab]);

  useEffect(() => {
    let nm = localStorage.getItem("userDetails");
    setUserType(JSON.parse(nm));
  }, []);

  useEffect(() => {
    const profitfixed = localStorage.getItem("profit");
    const lossfixed = localStorage.getItem("loss");
    setFormattedRatio({
      profit: profitfixed,
      loss: lossfixed,
    });
  }, []);

  useEffect(() => {
  //  console.log("countHold", combineStockData);
  }, [countHold, closeCountHold, combineStockData]);

  useEffect(() => {
    // console.log(
    //   "selectedPortfolio",
    //   selectedPortfolio,
    //   showPortfolioName,
    //   currencyName
    // );
  }, [
    selectedPortfolio,
    showPortfolioName,
    currencyName,
    portfolioCreatedDate,
  ]);

  useEffect(() => {
    // console.log("yearList", yearList);
  }, [yearList, monthlyBar, closeMonthlyBar, monthlyRatio, closeMonthlyRatio]);

  useEffect(() => {
    // console.log("dateRange", dateRange);
  }, [dateRange]);

  useEffect(() => {
   // console.log(barChartData, closeBarChartData, userBalance, "barChart");
  }, [barChartData, userBalance, totalDepositWithdrawl, closeBarChartData]);

  useEffect(() => {
    let timeFrem = JSON.parse(localStorage.getItem("timeFrem"));

    if (timeFrem?.fYear !== null && timeFrem?.fYear !== undefined) {
      setFinanceYearType(timeFrem.fYear);
      handleChangeFinanceYear(timeFrem.fYear);
    } else {
      handleChangeFinanceYear(1);
    }
  }, []);

  useEffect(() => {
    if (userType.userId !== undefined && userType?.userId.length > 0) {
      try {
        getPortfoloio(userType?.userId).then((res) => {
          if (res?.status === 200) {
            //console.log("ddddd", res?.data);
            setPortfolioList(res.data);
            setRunPortFolioAPI(false);
            if (res.data.length == 0) {
              localStorage.clear();
              const params = Object.fromEntries(
                new URLSearchParams(window.location.search)
              );
              function caseInsensitiveGet(obj, key) {
                const lowercaseKey = key.toLowerCase();
                for (let prop in obj) {
                  if (
                    obj.hasOwnProperty(prop) &&
                    prop.toLowerCase() === lowercaseKey
                  ) {
                    return obj[prop];
                  }
                }
                return undefined;
              }
              const id = caseInsensitiveGet(params, "userId");
              let rol = "";

              if (id == "5" || id == "6" || id == "10") {
                rol = "admin";
              } else {
                rol = "user";
              }
              let data = {
                userId: id,
                role: rol?.toLowerCase(),
              };
              localStorage.setItem("userDetails", JSON.stringify(data));
            }
          }
        });
      } catch (error) {}
    }
  }, [runPortFolioAPI === true, userType]);

  return (
    <>
      <div className="row">
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 mt-2">
                <div className="postion-bx-min mt-5">
                  <div
                    className="postion-frm mt-1 table-input1"
                    style={{ borderTop: "1px solid #cbd0dd" }}
                  >
                    <Formik
                      enableReinitialize={true}
                      initialValues={initialValue}
                      validationSchema={validationSchema}
                      onSubmit={(value) => {
                      //  console.log("value", value);
                        submitForm(value);
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form className="row">
                          <div className="col-sm-6">
                            <div className=" mt-4 h-78per">
                              <span className="frm-hd-t">Search</span>

                              <div className="postion-frm mt-2 h-100">
                                <div>
                                  <div className="row mb-3">
                                    <h2
                                      htmlFor=""
                                      style={{
                                        fontSize: "18px",
                                        marginRight: "11px",
                                      }}
                                      className="col-lg-2 col-form-label"
                                    >
                                      Portfolio
                                    </h2>

                                    <div className="col-lg-9">
                                      <select
                                        className="form-select"
                                        type="select"
                                        name="providerId"
                                        value={initialValue?.providerId}
                                        id="autoSizingSelect"
                                        required="required"
                                        onChange={handlePortfolioChange}
                                      >
                                        <option value>
                                          ---Select Portfolio---
                                        </option>
                                        {portfolioList?.map((val, ind) => {
                                          return (
                                            <option
                                              key={ind}
                                              value={val.portfolioId}
                                            >
                                              {val.portfolioName}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {errors.providerId &&
                                        touched.providerId && (
                                          <div className="text-danger">
                                            {errors.providerId}
                                          </div>
                                        )}
                                    </div>
                                  </div>

                                  <div className="row mb-3 radio-input">
                                    {/* <!-- <label for="inputEmail3" className="col-sm-3 col-form-label">long / short</label> --> */}
                                    <div className=" col-lg-10 ms-auto">
                                      <div
                                        class="col-sm-12 radio-input mb-2 mt-1"
                                        style={{ marginLeft: "10px" }}
                                      >
                                        <input
                                          class="form-check-input"
                                          style={{ "font-size": " 13px;" }}
                                          checked={
                                            initialValue?.gridRadios === 2
                                              ? true
                                              : false
                                          }
                                          type="radio"
                                          onClick={() => handleLongShort(2)}
                                          name="gridRadios"
                                          required=""
                                          id="gridRadios1"
                                          value="option1"
                                        />
                                        <label
                                          class="form-check-label "
                                          for="gridRadios1"
                                          style={{ color: "#000" }}
                                        >
                                          {" "}
                                          Long{" "}
                                        </label>
                                        <input
                                          class="form-check-input"
                                          style={{ "font-size": " 13px;" }}
                                          type="radio"
                                          onClick={() => handleLongShort(1)}
                                          checked={
                                            initialValue?.gridRadios === 1
                                              ? true
                                              : false
                                          }
                                          name="gridRadios"
                                          required=""
                                          id="gridRadios2"
                                          value="option2"
                                        />
                                        <label
                                          class="form-check-label "
                                          for="gridRadios2"
                                          style={{ color: "#000" }}
                                        >
                                          {" "}
                                          Short{" "}
                                        </label>
                                        <input
                                          class="form-check-input"
                                          style={{ "font-size": " 13px;" }}
                                          checked={
                                            initialValue?.gridRadios === 0
                                              ? true
                                              : false
                                          }
                                          type="radio"
                                          onClick={() => handleLongShort(0)}
                                          name="gridRadios"
                                          required=""
                                          id="gridRadios1"
                                          value="option1"
                                        />
                                        <label
                                          class="form-check-label "
                                          for="gridRadios1"
                                          style={{ color: "#000" }}
                                        >
                                          {" "}
                                          Both{" "}
                                        </label>
                                      </div>
                                      {errors.gridRadios &&
                                        touched.gridRadios && (
                                          <div className="text-danger">
                                            {errors.gridRadios}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="postion-bx-min mt-4">
                              <span className="frm-hd-t">Time Frame</span>
                              <div
                                className="postion-frm mt-2"
                                style={{ paddingBottom: "18px" }}
                              >
                                <div>
                                  <div className="row mb-3">
                                    <h2
                                      htmlFor="inputEmail3"
                                      className="col-lg-3 col-form-label"
                                      style={{ fontSize: "18px" }}
                                    >
                                      Period{" "}
                                    </h2>
                                    <div
                                      className="col-lg-9"
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "10px",
                                      }}
                                    >
                                      <div className="row">
                                        <div className="col-lg-12 ">
                                          <select
                                            className="form-select time_port mb-2"
                                            value={financeYearType}
                                            onChange={(e) =>
                                              handleChangeFinanceYear(
                                                e.target.value
                                              )
                                            }
                                            aria-label=".form-select-lg example"
                                          >
                                            <option selected value={0}>
                                              This Financial Year
                                            </option>
                                            <option value={1}>
                                              This Calendar Year
                                            </option>
                                            <option value={2}>
                                              Last Financial Year
                                            </option>
                                            <option value={3}>
                                              Last Calendar Year
                                            </option>
                                          </select>
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                          <DatePicker
                                            className="date-picker ps-2"
                                            dateFormat="MM / yyyy"
                                            selected={selectedDate}
                                            onChange={(date) =>
                                              setSelectedDate(date)
                                            }
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            calendarClassName="custom-calendar"
                                          />
                                        </div>

                                        <div className="col-lg-6 mb-3">
                                          <DatePicker
                                            className="date-picker ps-2"
                                            selected={selectedSecondDate}
                                            dateFormat="MM / yyyy"
                                            onChange={(date) =>
                                              setSelectedSecondDate(date)
                                            }
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            calendarClassName="custom-calendar"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row mb-2 ">
                                    <button
                                      type="submit"
                                      //disabled={buttonDisable}
                                      style={{
                                        width: "25%",
                                        marginLeft: "73%",
                                      }}
                                      class="btn"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>

                    {isLoading ? (
                      <div class="container-fluid">
                        <div class="container">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="preloader1">
                                {/* <div class="loader loader-inner-1"> */}
                                <div class=" loader-inner-2">
                                  <div class="loader loader-inner-3"></div>
                                </div>
                                <p>Analysis in process please wait</p>
                                {/* </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          {userBalance !== "0" && userBalance !== "" ? (
                            <SmallColumn
                              showPortfolioName={showPortfolioName}
                              currencyName={currencyName}
                              portfolioCurrency={portfolioCurrency}
                              countHold={countHold}
                              closeCountHold={closeCountHold}
                              portfolioCreatedDate={portfolioCreatedDate}
                              mainTab={mainTab}
                            />
                          ) : (
                            ""
                          )}

                          {userBalance !== "0" && userBalance !== "" ? (
                            <BiggerColumn
                              mainTab={mainTab}
                              totalProfit={totalProfit}
                              totalLoss={totalLoss}
                              closeTotalProfit={closeTotalProfit}
                              closeTotalLoss={closeTotalLoss}
                              userBalance={userBalance}
                              totalDepositWithdrawl={totalDepositWithdrawl}
                              leverage={leverage}
                              numberOfProfitLoss={numberOfProfitLoss}
                              currency={portfolioCurrency}
                            />
                          ) : (
                            ""
                          )}
                          <div class="">
                            <div
                              className="tbh-tabs-graph w-full"
                              style={{ display: "flex", gap: "5px" }}
                            >
                              <div
                                class="w-100 open-close-stocks-tab-h"
                                style={{ marginTop: "10px" }}
                              >
                                <div
                                  className="about-data-right tabs-sec"
                                  style={{
                                    height: "50px",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <ul
                                    className="nav nav-pills nav-justified mb-3"
                                    id="main-tab"
                                    role="tablist"
                                  >
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className={`nav-link ${
                                          mainTab === "open" ? "active" : ""
                                        }`}
                                        onClick={() => setMainTab("open")}
                                      >
                                        Open Stocks
                                      </button>
                                    </li>
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className={`nav-link ${
                                          mainTab === "close" ? "active" : ""
                                        }`}
                                        onClick={() => setMainTab("close")}
                                      >
                                        Closed Stocks
                                      </button>
                                    </li>
                                  </ul>
                                </div>

                                {mainTab === "open" ? (
                                  <div class="about-data-right tabs-sec">
                                    <ul
                                      class="nav nav-pills nav-justified mb-3 "
                                      id="pills-tab"
                                      role="tablist"
                                    >
                                      <li class="nav-item" role="presentation">
                                        <button
                                          className={`nav-link ${
                                            activeTab === "monthly"
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            setActiveTab("monthly")
                                          }
                                        >
                                          Months P/L
                                        </button>
                                      </li>
                                      <li class="nav-item" role="presentation">
                                        <button
                                          className={`nav-link ${
                                            activeTab === "ratio"
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => setActiveTab("ratio")}
                                        >
                                          P/L Ratio
                                        </button>
                                      </li>

                                      <li class="nav-item" role="presentation">
                                        <button
                                          className={`nav-link ${
                                            activeTab === "average"
                                              ? "active"
                                              : ""
                                          }`}
                                          type="button"
                                          onClick={() =>
                                            setActiveTab("average")
                                          }
                                        >
                                          Average P/L
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                ) : (
                                  <div class="about-data-right tabs-sec">
                                    <ul
                                      class="nav nav-pills nav-justified mb-3 "
                                      id="pills-tab"
                                      role="tablist"
                                    >
                                      <li class="nav-item" role="presentation">
                                        <button
                                          className={`nav-link ${
                                            activeTab === "monthly"
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            setActiveTab("monthly")
                                          }
                                        >
                                          Months P/L
                                        </button>
                                      </li>
                                      <li class="nav-item" role="presentation">
                                        <button
                                          className={`nav-link ${
                                            activeTab === "ratio"
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => setActiveTab("ratio")}
                                        >
                                          P/L Ratio
                                        </button>
                                      </li>

                                      <li class="nav-item" role="presentation">
                                        <button
                                          className={`nav-link ${
                                            activeTab === "average"
                                              ? "active"
                                              : ""
                                          }`}
                                          type="button"
                                          onClick={() =>
                                            setActiveTab("average")
                                          }
                                        >
                                          Average P/L
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                )}

                                <div
                                  class="tab-content"
                                  id="pills-tabContentnew"
                                >
                                  {activeTab === "monthly" && (
                                    <div>
                                      <div className="about-content-bx top-know-price-bx top-know-price-bx-1">
                                        {mainTab === "open" ? (
                                          <>
                                            {monthlyBar.length !== 0 ? (
                                              <BarChart2
                                                id="firbaa"
                                                data={monthlyBar}
                                                currency={portfolioCurrency}
                                                setNumberOfProfitLoss={
                                                  setNumberOfProfitLoss
                                                }
                                              />
                                            ) : (
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                  width: "100%",
                                                }}
                                              >
                                                No Stock Found
                                              </div>
                                            )}
                                          </>
                                        ) : closeMonthlyBar?.length !== 0 ? (
                                          <BarChart2
                                            id="second"
                                            data={closeMonthlyBar}
                                            currency={portfolioCurrency}
                                            setNumberOfProfitLoss={
                                              setNumberOfProfitLoss
                                            }
                                          />
                                        ) : (
                                          <div
                                            style={{
                                              textAlign: "center",
                                              width: "100%",
                                            }}
                                          >
                                            No Stock Found
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {activeTab === "ratio" && (
                                    <div>
                                      {/* <p style={{ color: "white" }}>average</p>
                                      <p style={{ color: "white" }}>average</p> */}
                                      {mainTab === "open" ? (
                                        <>
                                          {totalProfitRatio != null &&
                                          totalLossRatio != null ? (
                                            <>
                                              {" "}
                                              <BarChart3
                                                currency={portfolioCurrency}
                                                data={monthlyRatio}
                                                type={"profit-loss"}
                                                id={"thirdpie"}
                                              />
                                              {/* <PieChart
                                                currency={portfolioCurrency}
                                                data={pieChartData1}
                                                type={"profit-loss"}
                                                id={"thirdpie"}
                                              /> */}
                                            </>
                                          ) : (
                                            <div
                                              style={{
                                                textAlign: "center",
                                                width: "100%",
                                              }}
                                            >
                                              No Stock Found
                                            </div>
                                          )}
                                        </>
                                      ) : closePieChartData1 !== null ? (
                                        <BarChart3
                                          currency={portfolioCurrency}
                                          data={closeMonthlyRatio}
                                          type={"profit-loss"}
                                          id={"fourth"}
                                        />
                                      ) : (
                                        // <PieChart
                                        //   currency={portfolioCurrency}
                                        //   data={closePieChartData1}
                                        //   type={"profit-loss"}
                                        //   id={"fourthpie"}
                                        // />
                                        <div
                                          style={{
                                            textAlign: "center",
                                            width: "100%",
                                          }}
                                        >
                                          No Stock Found
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {activeTab === "average" && (
                                    <div>
                                      {/* <p style={{ color: "white" }}>average</p>
                                      <p style={{ color: "white" }}>average</p> */}
                                      {mainTab === "open" ? (
                                        <>
                                          {" "}
                                          {barChartData?.length != 0 ? (
                                            <BarChart
                                              id="average"
                                              data={barChartData}
                                              currency={portfolioCurrency}
                                              setNumberOfProfitLoss={
                                                setNumberOfProfitLoss
                                              }
                                            />
                                          ) : (
                                            <div
                                              style={{
                                                textAlign: "center",
                                                width: "100%",
                                              }}
                                            >
                                              No Stock Found
                                            </div>
                                          )}
                                        </>
                                      ) : closeBarChartData?.length !== 0 ? (
                                        <BarChart
                                          id="average2"
                                          data={closeBarChartData}
                                          currency={portfolioCurrency}
                                          setNumberOfProfitLoss={
                                            setNumberOfProfitLoss
                                          }
                                        />
                                      ) : (
                                        <div
                                          style={{
                                            textAlign: "center",
                                            width: "100%",
                                          }}
                                        >
                                          No Stock Found
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            {topThreeStocks?.length !== 0 &&
                              worstThreeStocks?.length !== 0 && (
                                <div className="tbh-below-tabs-table">
                                  <TableWorstTop
                                    topThreeStocks={topThreeStocks}
                                    worstThreeStocks={worstThreeStocks}
                                    currency={portfolioCurrency}
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Summary;
