import React from "react";

const TableWorstTop = ({ topThreeStocks, worstThreeStocks, currency }) => {
  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #cbd0dd",
          borderRadius: "10px",
          padding: "10px 15px",
          width: "50%",
        }}
      >
        {" "}
        <p
          style={{
            marginBottom: "0px",
            color: "grey",
            // Use `fontWeight` instead of `fontStyle` for bold text
            fontSize: "15px",
          }}
        >
          3 Top Performing Stock
        </p>
        <div class="table-responsive text-center">
          <table class="table table-input1 table-bordered mt-3 text-center white_text">
            <thead>
              <tr>
                <th
                  className="color-grey"
                  style={{ color: "#000" }}
                  scope="col"
                >
                  Stock
                </th>
                <th
                  className="color-grey"
                  style={{ color: "#000" }}
                  scope="col"
                >
                  Buy
                </th>
                <th
                  className="color-grey"
                  style={{ color: "#000" }}
                  scope="col"
                >
                  Sell
                </th>
                <th
                  className="color-grey"
                  style={{ color: "#000" }}
                  scope="col"
                >
                  Profit
                </th>
              </tr>
            </thead>
            <tbody>
              {topThreeStocks?.map((item, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td style={{ color: "#000" }}>{item?.stockCode}</td>
                      <td style={{ color: "#000" }}>
                        {currency}
                        {item?.valueInShares}
                      </td>
                      <td style={{ color: "#000" }}>
                        {currency}
                        {item?.transactionPrice}
                      </td>
                      <td style={{ color: "#000" }}>
                        {currency}
                        {item?.profit.toFixed(2)}
                      </td>
                    </tr>
                  </>
                );
              })}

              {/* <tr>
                <td style={{ color: "#000" }}>20MICRONS.NS</td>
               
                <td style={{ color: "#000" }}>$100</td>
                <td style={{ color: "#000" }}>$77.41</td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #cbd0dd",
          borderRadius: "10px",
          padding: "10px 15px",
          width: "50%",
        }}
      >
        {" "}
        <p
          style={{
            marginBottom: "0px",
            color: "grey",
            // Use `fontWeight` instead of `fontStyle` for bold text
            fontSize: "15px",
          }}
        >
          3 Worst Performing Stock
        </p>
        <div class="table-responsive text-center">
          <table class="table table-input1 table-bordered mt-3 text-center white_text">
            <thead>
              <tr>
                <th
                  className="color-grey"
                  style={{ color: "#000" }}
                  scope="col"
                >
                  Stock
                </th>
                <th
                  className="color-grey"
                  style={{ color: "#000" }}
                  scope="col"
                >
                  Buy
                </th>
                <th
                  className="color-grey"
                  style={{ color: "#000" }}
                  scope="col"
                >
                  Sell
                </th>
                <th
                  className="color-grey"
                  style={{ color: "#000" }}
                  scope="col"
                >
                  Loss
                </th>
              </tr>
            </thead>
            <tbody>
              {worstThreeStocks.map((item, index) => {
                return (
                  <tr>
                    <td style={{ color: "#000" }}>{item?.stockCode}</td>
                    <td style={{ color: "#000" }}>
                      {currency}
                      {item?.valueInShares}
                    </td>
                    <td style={{ color: "#000" }}>
                      {currency}
                      {item?.transactionPrice}
                    </td>
                    <td style={{ color: "#000" }}>
                      {currency}
                      {item?.loss.toFixed(2)}
                    </td>
                  </tr>
                );
              })}
              {/* <tr>
                <td style={{ color: "#000" }}>20MICRONS.NS</td>
                <td style={{ color: "#000" }}>$22.59</td>
                <td style={{ color: "#000" }}>$10</td>
                <td style={{ color: "#000" }}>$-77.41</td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TableWorstTop;
