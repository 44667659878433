import React, { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from "yup";
import { createStrategyAPI, deleteStrategy, editStrategy, getStrategy } from '../../../services/StrategyService'

const AddEditStrategy = ({userType, strategyInput,setSerategyInput,editTrue,setRunAgainStrategyAPI}) => {

    const [buttonDisable,setButtonDisable]=useState(false)
    const { Name, Notes, EntryStrategy, ExitStrategy, ScaleInOutStrategy,reRender} =strategyInput
    let validationSchema = Yup.object().shape({
        Name: Yup.string().required('Name is required'),
        EntryStrategy: Yup.string().required('Entry strategy is required'),
        ExitStrategy: Yup.string().required('Exit strategy is required'),
        ScaleInOutStrategy: Yup.string().required('Scale in out strategy is required'),
    });
    let clickClise=document.getElementById('handleRemoveInputData')
    
    const onSubmitEditStretegy = (data) => {
        setButtonDisable(true)
        try {
            editStrategy(data).then((res) => {
                if (res?.status === 200) {
                    setSerategyInput({reRender:reRender+1, UserId: userType.role=='user'? userType?.userId: 0, Name: '', Notes: '', EntryStrategy: '', ExitStrategy: '', ScaleInOutStrategy: '' })
                    setRunAgainStrategyAPI(true)
                    setButtonDisable(false)
                    clickClise.click()
                }
            })
        } catch (error) {
            
        }
    }
    const onSubmitCreateStretegy = (data) => {
        setButtonDisable(true)
        try {
            createStrategyAPI(data).then((res) => {
                if (res?.status === 200) {
                    setSerategyInput({reRender:reRender+1, UserId:userType.role=='user'? userType?.userId: 0, Name: '', Notes: '', EntryStrategy: '', ExitStrategy: '', ScaleInOutStrategy: '' })
                    setRunAgainStrategyAPI(true)
                    setButtonDisable(false)
                    clickClise.click()
                }
            })
        } catch (error) {
            
        }
    }
    const handleChangestretegyInput=(e)=>{
        setSerategyInput({...strategyInput,[e.target.name]:e.target.value})
    }
    const handleRemoveInputData=()=>{
        setSerategyInput({reRender:reRender+1, UserId: userType.role=='user'? userType?.userId: 0, Name: '', Notes: '', EntryStrategy: '', ExitStrategy: '', ScaleInOutStrategy: '' })
    }
    const handleBlurStratagyInput=()=>{

    }
  return (
    <>
      <div
        className="modal fade show backdrop z-indez-2000"
        id="addEditStrategy"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="exampleModalLgLabel"
        style={{ display: "none" }}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-md  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title h4" id="exampleModalLgLabel">
                {editTrue == true ? "Update" : "Add"} Strategy
              </h5>
              <button
                type="button"
                className="btn-close btn-close-provider"
                id="handleRemoveInputData"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleRemoveInputData}
              >
                ×
              </button>
            </div>

            <div className="modal-body blackout-bx">
              {/* <!-------modeal-----> */}

              <div className="row">
                <Formik
                  initialValues={strategyInput}
                  enableReinitialize
                  validationSchema={validationSchema}
                  onSubmit={(value) => {
                    //  UserId: userType.role=='user'? userType?.userId
                    value.UserId =
                      userType.role == "user" ? userType?.userId : "0";
                    if (editTrue === true) {
                      onSubmitEditStretegy(value);
                    } else {
                      onSubmitCreateStretegy(value);
                    }
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="postion-bx-min mt-2 ">
                            <span class="frm-hd-t">Trading System Details</span>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-sm-12">
                          <div class="postion-min-dta postion-frm">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="col-sm-12">
                                  <div class="row mb-2">
                                    <label
                                      for="inputEmail3"
                                      class="col-sm-12 col-form-label"
                                      style={{ color: "#fff" }}
                                    >
                                      Strategy Name{" "}
                                      <span style={{ color: "#f00" }}>*</span>
                                    </label>
                                    <div class="col-sm- ms-auto">
                                      <Field
                                        onChange={handleChangestretegyInput}
                                        onBlur={handleBlurStratagyInput}
                                        type="text"
                                        name="Name"
                                        value={Name}
                                        className="form-control"
                                        id="formGroupExampleInput"
                                      />
                                      {errors.Name && touched.Name ? (
                                        <div className="color-msg-err">
                                          {errors.Name}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="col-sm-12">
                                  <div class="row mb-2">
                                    <label
                                      for="inputEmail3"
                                      class="col-sm-12 col-form-label"
                                      style={{ color: "#fff" }}
                                    >
                                      Entry Strategy{" "}
                                      <span style={{ color: "#f00" }}>*</span>
                                    </label>
                                    <div class="col-sm-12 ms-auto">
                                      <Field
                                        onChange={handleChangestretegyInput}
                                        onBlur={handleBlurStratagyInput}
                                        type="text"
                                        name="EntryStrategy"
                                        value={EntryStrategy}
                                        className="form-control"
                                        id="formGroupExampleInput"
                                      />
                                      {errors.EntryStrategy &&
                                      touched.EntryStrategy ? (
                                        <div className="color-msg-err">
                                          {errors.EntryStrategy}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="col-sm-12">
                                  <div class="row mb-2">
                                    <label
                                      for="inputEmail3"
                                      class="col-sm-12 col-form-label"
                                      style={{ color: "#fff" }}
                                    >
                                      Exit Strategy{" "}
                                      <span style={{ color: "#f00" }}>*</span>
                                    </label>
                                    <div class="col-sm-12 ms-auto">
                                      <Field
                                        onChange={handleChangestretegyInput}
                                        onBlur={handleBlurStratagyInput}
                                        type="text"
                                        name="ExitStrategy"
                                        value={ExitStrategy}
                                        className="form-control"
                                        id="formGroupExampleInput"
                                      />
                                      {errors.ExitStrategy &&
                                      touched.ExitStrategy ? (
                                        <div className="color-msg-err">
                                          {errors.ExitStrategy}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="col-sm-12">
                                  <div class="row mb-2">
                                    <label
                                      for="inputEmail3"
                                      class="col-sm-12 col-form-label"
                                      style={{ color: "#fff" }}
                                    >
                                      Scale In / Out Strategy{" "}
                                      <span style={{ color: "#f00" }}>*</span>
                                    </label>
                                    <div class="col-sm-12 ms-auto">
                                      <Field
                                        onChange={handleChangestretegyInput}
                                        onBlur={handleBlurStratagyInput}
                                        type="text"
                                        name="ScaleInOutStrategy"
                                        value={ScaleInOutStrategy}
                                        className="form-control"
                                        id="formGroupExampleInput"
                                      />
                                      {errors.ScaleInOutStrategy &&
                                      touched.ScaleInOutStrategy ? (
                                        <div className="color-msg-err">
                                          {errors.ScaleInOutStrategy}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12">
                                <div class="col-sm-12">
                                  <div class="row mb-2">
                                    <label
                                      for="inputEmail3"
                                      class="col-sm-12 col-form-label"
                                      style={{ color: "#fff" }}
                                    >
                                      Comments
                                    </label>
                                    <div class="col-sm-12 ms-auto">
                                      <Field
                                        onChange={handleChangestretegyInput}
                                        onBlur={handleBlurStratagyInput}
                                        type="text"
                                        as="textarea"
                                        name="Notes"
                                        value={Notes}
                                        className="form-control"
                                        id="formGroupExampleInput"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="d-grid gap-2 d-md-block mt-2">
                                <button class="btn " type="submit">
                                  {editTrue === true ? "Update" : "Save"}
                                </button>
                                <button
                                  class="btn ms-3"
                                  type="button"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={handleRemoveInputData}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditStrategy