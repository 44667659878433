import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { createChart } from "lightweight-charts";
import LineChart from "../../Trade/Model/charts/LineChart";
import { Link, useNavigate } from "react-router-dom";
import { FaExpand } from "react-icons/fa";
import { GoThreeBars } from "react-icons/go";
import Select from "react-select";
import { Formik, Field, Form, useFormikContext } from "formik";
import { GetProviderstocks } from "../../../services/ProviderServices";
import {
  getRapidSearchStockDetails,
  getSearchStockDetails,
} from "../../../services/HomeService";
import CountryCurrencyList from "../../commonComponent/CountryCurrencyList";
import {
  BsFillArrowUpRightCircleFill,
  BsArrowDownRightCircleFill,
} from "react-icons/bs";
import WatchList from "./WatchList";
import {
  AddOpenTradeAPI,
  AddWatchList,
  getCryptoIcons,
  getCryptoSymbols,
  getStockNews,
  getWatchList,
  removeWatchList,
  getRapidStockNews,
} from "../../../services/TradeService";
import moment from "moment/moment";
import Pagination from "../../commonComponent/Pagination";
import { getStrategy } from "../../../services/StrategyService";
import { toast } from "react-toastify";
import coinSymbol from "../../commonComponent/ConinSymbol";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import * as Yup from "yup";
import DeleteConfirmation from "../../commonComponent/DeleteConfirmation";
import {
  getExchangeRateAPI,
  getRapidExchangeRateAPI,
} from "../../../services/commonServices";
import TradeModel from "../../Trade/Model/TradeModel";

// const fs = require('fs');

let validationSchemaStopLoss = Yup.object().shape({
  PortfolioID: Yup.string().required("Portfolio is required"),
  ProviderID: Yup.string().required("Provider is required"),
  StockCode: Yup.string().required("Stock type is required"),
  TransFee: Yup.string().required("At Price is required"),
  ValueInShares: Yup.string().required("Value In Stock is required"),
  quantity: Yup.string().required("Quantity is required"),
  StopLossPrice: Yup.string().required("Stop Price is required"),
});

let validationSchema = Yup.object().shape({
  PortfolioID: Yup.string().required("Portfolio is required"),
  ProviderID: Yup.string().required("Provider is required"),
  StockCode: Yup.string().required("Stock type  is required"),
  TransFee: Yup.string().required("At Price is required"),
  ValueInShares: Yup.string().required("Value In Stock is required"),
  quantity: Yup.string().required("Quantity is required"),
  GSLOPrice: Yup.string().required("GSLO is required"),
});
const SymbleListing = ({ val, handleSelectedStock, tradeInput, askprices }) => {
  const [askp, setAskp] = useState(0);
  let codeAr = val?.stockCode.split("_");
  // console.log("codeAr", codeAr, codeAr[2]);
  if (codeAr[0] && codeAr[0].includes("ASX:")) {
    codeAr[0] = codeAr[0].replace("ASX:", "");
  }
  let getCoinIcon = coinSymbol.filter((value) => codeAr[0] == value.asset_id);
  useEffect(() => {
    let stockArr = val?.stockCode.split("_");
    // try {
    //     // getExchangeRateAPI(stockArr[2], 'USD').then((respons) => {
    //         getExchangeRateAPI(stockArr[2], stockArr[3]).then((respons) => {
    //         const bidPrice = respons.data["Realtime Currency Exchange Rate"]["8. Bid Price"];
    //         const askPrice = respons.data["Realtime Currency Exchange Rate"]["9. Ask Price"];

    //         const twoDecimal = (value) => {
    //             var roundedNumber = value;
    //             var decimalIndex = roundedNumber.indexOf(".");
    //             if (decimalIndex !== -1) {
    //                 var decimalPlaces = roundedNumber.length - decimalIndex - 1;
    //                 if (decimalPlaces > 3) {
    //                     roundedNumber = roundedNumber.slice(0, decimalIndex + 4);
    //                 }
    //             }
    //             const result = roundedNumber.toString().replace(/(\.[0-9]*[1-9])0+$/, '$1');
    //             return result
    //         }
    //         setAskp(twoDecimal(askPrice))

    //     })
    // } catch (error) {

    // }
  }, [val?.stockCode]);

  return (
    <>
      <li
        className={
          tradeInput?.StockCode == val?.stockCode
            ? "crypto_side_selected cursore-pointer"
            : "crypto_side_selected1 cursore-pointer"
        }
        onClick={() => handleSelectedStock(val)}
      >
        <div class="nav_link data-bx-min d-flex">
          <i>
            <img
              src={getCoinIcon[0]?.url || "assets/image/crypto.png"}
              class="img-fluid"
            />
          </i>
          <span class="flex-shrink-0 white_text">{codeAr[0]}</span>
          {/* <button class="btn ms-auto d-block flex-shrink-0 btn-p btn-light-green profit-loss-button">
            ASK-{askp}$ */}
            {/* <img src="image/download-icon.svg" class="img-fluid text-end" /> */}
          {/* </button> */}
        </div>
      </li>
    </>
  );
};

const MarketOverview = ({
  userType,
  countryList,
  marketOverviewTruw,
  setMarketOverviewTruw,
  portfololioList,
  providerList,
}) => {
  const [cryptoList, setCryptoList] = useState([]);
  const [chartType, setChartType] = useState("2");
  const [buySell, setBuySell] = useState("buy");
  const [checkStop, setCheckStop] = useState("0");
  const [tradeInput, setTradeInput] = useState({
    AtRiskDollar: 0,
    AtRiskPercent: 0,
    FromTransPriceDollar: 0,
    type: "TRADES",
    FromTransPricePercent: 0,
    GSLOTradingFee: 0,
    GSLOCost: 0,
    GSLOPrice: "",
    StopLossPrice: 0,
    Comments: "",
    ValueInShares: "",
    ValueInCFD: 0,
    TransCost: 0,
    TransFee: 0,
    bid_size: 0,
    bid_price: 0,
    ask_size: "",
    ask_price: "",
    OpenDate: new Date(),
    Short: "buy",
    Margin: "",
    Share_Sector: "Stock",
    StockName: "",
    StockCode: "",
    UserId: userType.userId,
    ProviderID: "",
    PortfolioID: "",
    quantity: "",
    brockerId: "",
    country: "",
    symbol: "",
    stockId: "",
  });
 // console.log("userType", userType, portfololioList, tradeInput);
  const {
    stockId,
    PortfolioID,
    type,
    TransFee,
    Short,
    ValueInShares,
    quantity,
    StopLossPrice,
    GSLOPrice,
    ProviderID,
    symbol,
    Share_Sector,
    brockerId,
    Strategy,
    StockCode,
  } = tradeInput;
  // console.log("symbol", symbol);
  const [tradeCode, setTradeCode] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [checkInput, setCheckInput] = useState(false);
  const [toggleSide, setToggleSide] = useState(false);
  const [selectBroker, setSelectBroker] = useState("");
  const [providerStockList, setProviderStockList] = useState([]);
  const [cryptoDetails, setCryptodetails] = useState([]);
  const [checkAskPrLo, setCheckAskPrLo] = useState("same");
  const [checkBidPrLo, setCheckBidPrLo] = useState("same");
  const [checkPricePrLo, setCheckPricePrLo] = useState("same");
  const [strategyList, setStrategyList] = useState([]);
  const [cryptoSelectValue, setCryptoSelectValue] = useState();
  const [newsTradeCode, setNewsTradeCode] = useState("");
  const [mycode, setMyCode] = useState(null);
  const [stockpageData, setStockPageData] = useState({
    id: "",
    PageNumber: 1,
    PageSize: 200,
    search: "",
  });
  const [watchListAdded, setWatchListAdded] = useState({
    status: false,
    id: "",
  });
  const [watchList, setWatchList] = useState([]);
  const [watchListRun, setWatchListRun] = useState(true);
  const [paginationWatch, setPaginationWatch] = useState({
    currentPageWatch: 1,
    pageNumberWatch: 1,
    pageSizeWatch: 10,
    totalPagesWatch: 0,
  });
  const { pageNumberWatch, pageSizeWatch, currentPageWatch, totalPagesWatch } =
    paginationWatch;
  const { search } = stockpageData;
  const [newsList, setNewsList] = useState([]);
  const [newsListCount, setNewsListCount] = useState(5);
  const [newsLoading, setNewsLoading] = useState(true);
  const Navigate = useNavigate();
  const [deleteConfirmData, setDeleteConfirmData] = useState("");
  const [typeMsg, setTypeMsg] = useState("");
  // const searchStocks = async (data) => {
  //     let a = 20
  //     try {
  //         const response = await axios({
  //             method: 'get',
  //             url: `https://rest.coinapi.io/v1/quotes/latest?limit=${a}`,
  //             mode: 'cors',
  //             headers: { 'X-CoinAPI-Key': '6AE6BEA7-932F-4087-BEB0-1F39CB915396' },
  //         });
  //         if (response.status === 200) {
  //
  //             // setCryptoList(response.data)
  //             let n = []
  //             for (let i = 0; i < response.data.length; i++) {
  //                 try {
  //                     const response = await axios({
  //                         method: 'get',
  //                         url: `https://rest.coinapi.io/v1/assets/icons/32/`,
  //                         mode: 'cors',
  //                         headers: { 'X-CoinAPI-Key': '6AE6BEA7-932F-4087-BEB0-1F39CB915396' },
  //                     });
  //                     if (response.status === 200) {
  //
  //                         setCryptoList(response.data)
  //                         // return response
  //                     }
  //                     else if (response.status === 401) {
  //                         alert('Not authorized.')
  //                     } else if (response.status >= 500) {
  //                         alert('Something went wrong.')
  //                     }
  //                     //
  //                 } catch (error) {
  //                 }
  //             }
  //             // return response
  //         }
  //         else if (response.status === 401) {
  //             alert('Not authorized.')
  //         } else if (response.status >= 500) {
  //             alert('Something went wrong.')
  //         }
  //         //
  //     } catch (error) {
  //     }
  // }
  useEffect(() => {
    setSelectBroker(providerList[0]?.providerId);
    setTradeInput({
      ...tradeInput,
      PortfolioID: portfololioList[0]?.portfolioId,
      ProviderID: providerList[0]?.providerId,
      providerName: providerList[0]?.providerName,
    });
    //  providerName
  }, [providerList, portfololioList]);

  const twoDecimal = (value) => {
    var roundedNumber = value;
    var decimalIndex = roundedNumber.indexOf(".");
    if (decimalIndex !== -1) {
      var decimalPlaces = roundedNumber.length - decimalIndex - 1;
      if (decimalPlaces > 5) {
        roundedNumber = roundedNumber.slice(0, decimalIndex + 6);
      }
    }
    return roundedNumber;
  };
  const handleChengeTradeInput = (e) => {
    if (e.target.name == "quantity") {
      if (e.target.value >= 0) {
        if (
          TransFee !== "" &&
          TransFee !== undefined &&
          TransFee !== 0 &&
          e.target.value.length
        ) {
          let roundQty = twoDecimal(e.target.value);
          let vs = TransFee * roundQty;
          let n = e.target.value.split(".");
          if (n.length == 2) {
            if (n[0].length <= 10) {
              setTradeInput({
                ...tradeInput,
                [e.target.name]: roundQty,
                ValueInShares: twoDecimal(vs.toString()),
              });
            }
          } else if (n.length == 1 && e.target.value.length <= 10) {
            setTradeInput({
              ...tradeInput,
              [e.target.name]: roundQty,
              ValueInShares: twoDecimal(vs.toString()),
            });
          }
        } else {
          toast.error("Please select stock!");
        }
      }
    } else if (e.target.name == "ValueInShares") {
      if (e.target.value >= 0) {
        if (TransFee !== "" && TransFee !== undefined && TransFee !== 0) {
          let roundVs = twoDecimal(e.target.value);
          let qty = roundVs / TransFee;
          let n = qty;
          let vLen = e.target.value.split(".");
          if (vLen.length == 2) {
            if (vLen[0].length <= 10) {
              setTradeInput({
                ...tradeInput,
                [e.target.name]: roundVs,
                quantity: twoDecimal(n.toString()),
              });
            }
          } else if (vLen.length == 1 && e.target.value.length <= 10) {
            setTradeInput({
              ...tradeInput,
              [e.target.name]: roundVs,
              quantity: twoDecimal(n.toString()),
            });
          }
        } else {
          toast.error("Please select stock!");
        }
      }
    } else if (e.target.name == "TransFee") {
      if (e.target.value > 0) {
        if (
          ValueInShares !== "" &&
          ValueInShares !== undefined &&
          ValueInShares !== 0
        ) {
          if (e.target.value > 0) {
            let qty = ValueInShares / e.target.value;
            let n = qty;
            let vLen = e.target.value.split(".");
            let value = twoDecimal(e.target.value);
            if (vLen.length == 2) {
              if (vLen[0].length <= 10) {
                setTradeInput({
                  ...tradeInput,
                  [e.target.name]: value,
                  quantity: twoDecimal(twoDecimal(n.toString())),
                });
              }
            } else if (vLen.length == 1 && e.target.value.length <= 10) {
              setTradeInput({
                ...tradeInput,
                [e.target.name]: value,
                quantity: twoDecimal(twoDecimal(n.toString())),
              });
            }
          } else {
            setTradeInput({
              ...tradeInput,
              [e.target.name]: e.target.value,
              quantity: 0,
            });
          }
        } else if (
          ValueInShares == "" &&
          ValueInShares == undefined &&
          ValueInShares == 0 &&
          quantity !== "" &&
          quantity !== undefined &&
          quantity !== 0
        ) {
          let vs = quantity * e.target.value;
          let vLen = e.target.value.split(".");
          if (vLen.length == 2) {
            if (vLen[0].length <= 10) {
              setTradeInput({
                ...tradeInput,
                [e.target.name]: e.target.value,
                ValueInShares: vs,
              });
            }
          } else if (vLen.length == 1 && e.target.value.length <= 10) {
            setTradeInput({
              ...tradeInput,
              [e.target.name]: e.target.value,
              ValueInShares: vs,
            });
          }
        } else {
          toast.error("Please select stock!");
        }
      } else if (e.target.value == "") {
        setTradeInput({
          ...tradeInput,
          [e.target.name]: e.target.value,
          quantity: "",
          ValueInShares: "",
        });
      }
    } else if (e.target.name == "stockCode") {
      let nm = stockpageData;
      nm.search = e.target.value;
      setTradeInput({ ...tradeInput, [e.target.name]: e.target.value });
      setStockPageData({
        ...nm,
      });
      if (nm.id !== undefined) {
        getProviderStockList(nm);
      }
    } else if (e.target.name == "StopLossPrice") {
      let roundedStp = twoDecimal(e.target.value);
      if (Number(e.target.value) >= 0 || e.target.value == ".") {
        // buy is short
        // short stop price should be more
        if (Short == "buy") {
          if (TransFee < Number(roundedStp)) {
            let vLen = e.target.value.split(".");
            if (vLen.length == 2) {
              if (vLen[0].length <= 10) {
                setTradeInput({ ...tradeInput, [e.target.name]: roundedStp });
              }
            } else if (vLen.length == 1 && e.target.value.length <= 10) {
              setTradeInput({ ...tradeInput, [e.target.name]: roundedStp });
            }
          } else {
            toast.error(
              "Set higher stop price than at price while short trading"
            );
          }
        } else {
          if (Number(roundedStp) > TransFee) {
            toast.error(
              "Set lower stop price than at price while long trading"
            );
          }
          let vLen = e.target.value.split(".");
          if (vLen.length == 2) {
            if (vLen[0].length <= 10) {
              setTradeInput({ ...tradeInput, [e.target.name]: roundedStp });
            }
          } else if (vLen.length == 1 && e.target.value.length <= 10) {
            setTradeInput({ ...tradeInput, [e.target.name]: roundedStp });
          }
        }
      }
    } else if (e.target.name == "PortfolioID") {
      setTradeInput({
        ...tradeInput,
        [e.target.name]: e.target.value,
        ValueInShares: "",
        quantity: "",
        StopLossPrice: "",
      });
    } else if (e.target.name == "GSLOPrice") {
      let roundedStp = twoDecimal(e.target.value);
      if (Number(e.target.value) >= 0 || e.target.value == ".") {
          let vLen = e.target.value.split(".");
          if (vLen.length == 2) {
            if (vLen[0].length <= 10) {
              setTradeInput({ ...tradeInput, [e.target.name]: roundedStp });
            }
          } else if (vLen.length == 1 && e.target.value.length <= 10) {
            setTradeInput({ ...tradeInput, [e.target.name]: roundedStp });
          }
     
      }
    } else {
      setTradeInput({ ...tradeInput, [e.target.name]: e.target.value });
    }
  };
  const handleChangeBroker = (e) => {
    setSelectBroker(e.target.value);
    let a = e.target.value.split("-");
    let pName = providerList.filter((val) => {
      if (val.providerId == Number(e.target.value)) {
        return val;
      }
    });
    setTradeInput({
      ...tradeInput,
      ProviderID: Number(e.target.value),
      providerName: pName[0]?.providerName,
    });
  };

  const getProviderStockList = () => {
    let nm = stockpageData;

    nm.id = ProviderID;
    if (nm?.id !== undefined && nm?.id > 0) {
      try {
        GetProviderstocks(nm).then((res) => {
          if (res?.status === 200) {
            let data = res.data.data;
            setProviderStockList(data);
            // setTradeInput({ ...tradeInput, stockId: data[0].stockId })
            // if(providerStockList.length>0){
            //
            // }
          }
        });
      } catch (error) {}
    }
  };
  useEffect(() => {
    getProviderStockList();
  }, [
    ProviderID,
    stockpageData.id,
    stockpageData.PageNumber,
    stockpageData.PageSize,
  ]);

  // getting crypto details
  let s = 0;
  const handleStockData = (value) => {
    // debugger;
    if (value !== undefined) {
      let selectedStockCountry = countryList.filter(
        (val) => val.key.toUpperCase() == value.country.toUpperCase()
      );
      let symbol = CountryCurrencyList.filter(
        (val) => val.code == selectedStockCountry[0]?.value
      );
      let codeArr = value.stockCode.split("_");
      // debugger;
      let newstockCodeArr = [];
      if (
        codeArr[0] === "USDC" ||
        codeArr[0] === "USDT" ||
        codeArr[0] === "USDB"
      ) {
        // debugger
        newstockCodeArr = "USD";
      } else {
        newstockCodeArr = codeArr[0];
      }
      // console.log(
      //   "codeArr",
      //   value.stockCode,
      //   value.stockCode.split("_"),
      //   codeArr[codeArr.length - 2]
      // );
      const yup = codeArr[0];
   //   console.log("handlestockdata", codeArr[0], yup);
      setMyCode(yup);
      let selectedPortFolioCurencylocal =
        localStorage.getItem("currencySymbol");
      selectedPortFolioCurencylocal = JSON.parse(selectedPortFolioCurencylocal);
      //  console.log(
      //    "selectedPortFolioCurencylocal",
      //    selectedPortFolioCurencylocal
      //  );
      try {
        // debugger;
        getRapidSearchStockDetails(value.stockCode).then((res) => {
          getRapidExchangeRateAPI(
            selectedStockCountry[0]?.value,
            selectedPortFolioCurencylocal.code
          ).then((respons) => {
            // getExchangeRateAPI(codeArr[2], codeArr[3]).then((respons) => {
          //    console.log("inside the response", respons, res?.data);
            if (respons?.status === 200) {
              debugger
                let aus = value.stockCode.includes("ASX:");
           //   console.log("aus", aus);
              if (aus) {
                debugger
                const openPrice = res?.data?.quotes[0]?.open || 0;
                const bidPrice = res?.data?.quotes[0]?.close || 0;
                const askPrice = res?.data?.quotes[0]?.high || 0;
                const lowPrice = res?.data?.quotes[0]?.low || 0;
                const dividendRate = 0;
                const dividendYeild = 0
                const twoDecimal = (value) => {
                  var roundedNumber = value;
                  var decimalIndex = roundedNumber.indexOf(".");
                  if (decimalIndex !== -1) {
                    var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                    if (decimalPlaces > 3) {
                      roundedNumber = roundedNumber.slice(0, decimalIndex + 4);
                    }
                  }
                  return roundedNumber;
                };

                let data = {
                  bidPrice: bidPrice,
                  askPrice: askPrice,
                };
                setCryptoSelectValue(data);
                // console.log("yupstockid", value)
                setTradeInput({
                  ...tradeInput,
                  bid_size: 0,
                  bid_price: bidPrice,
                  ask_size: 0,
                  ask_price: askPrice,
                  TransFee: askPrice,
                  StockCode: value.stockCode,
                  StockName: value.stockCode,
                  Margin: value.margin,
                  country: value.country,
                  symbol: codeArr[0],
                  stockId: value.stockId,
                });
                setNewsTradeCode(value.stockCode);
                setCryptodetails([
                  ...cryptoDetails,
                  {
                    s: s + 1,
                    askPrice: askPrice,
                    bidPrice: bidPrice,
                    dividendRate: dividendRate,
                    dividendYeild: dividendYeild,
                    openPrice: openPrice,
                    lowPrice: lowPrice,

                  },
                ]);
              } else {
                 const openPrice = res?.data?.regularMarketOpen?.raw || 0;
                const bidPrice =
                  res?.data?.regularMarketPreviousClose?.raw || 0;
                ;
                const askPrice = res?.data?.regularMarketDayHigh?.raw || 0;
                ;
                const lowPrice = res?.data?.regularMarketDayLow?.raw || 0;
                const dividendRate = res?.data?.dividendRate?.raw || 0;
                const dividendYeild = res?.data?.dividendYield?.raw || 0;


                 // console.log("HHHHHHH", askPrice);
                  const twoDecimal = (value) => {
                    var roundedNumber = value;
                    var decimalIndex = roundedNumber.indexOf(".");
                    if (decimalIndex !== -1) {
                      var decimalPlaces =
                        roundedNumber.length - decimalIndex - 1;
                      if (decimalPlaces > 3) {
                        roundedNumber = roundedNumber.slice(
                          0,
                          decimalIndex + 4
                        );
                      }
                    }
                    return roundedNumber;
                  };

                  let data = {
                    bidPrice: bidPrice,
                    askPrice: askPrice,
                  };
                  setCryptoSelectValue(data);
                  // console.log("yupstockid", value)
                  setTradeInput({
                    ...tradeInput,
                    bid_size: 0,
                    bid_price: bidPrice,
                    ask_size: 0,
                    ask_price: askPrice,
                    TransFee: askPrice,
                    StockCode: value.stockCode,
                    StockName: value.stockCode,
                    Margin: value.margin,
                    country: value.country,
                    symbol: codeArr[0],
                    stockId: value.stockId,
                  });
                  setNewsTradeCode(value.stockCode);
                  setCryptodetails([
                    ...cryptoDetails,
                    {
                      s: s + 1,
                      askPrice: askPrice,
                      bidPrice: bidPrice,
                      dividendRate: dividendRate,
                      openPrice: openPrice,
                      dividendYeild: dividendYeild,
                      lowPrice: lowPrice,
                    },
                  ]);
              }
              
            
            } else if (respons.data?.status === 400) {
              let newstockCodeArr = [];
              if (codeArr[3] === "USD") {
                // debugger
                newstockCodeArr = "USDT";
              } else {
                newstockCodeArr = codeArr[3];
              }
              // getBinanceExchangeRateAPI(codeArr[2], newstockCodeArr).then(
              //   (respons) => {
              //     // console.log("inside the response");
              //     const bidPrice = Number(respons.data.price || 0);
              //     const askPrice = Number(respons.data.price || 0);
              //     const twoDecimal = (value) => {
              //       var roundedNumber = value;
              //       var decimalIndex = roundedNumber.indexOf(".");
              //       if (decimalIndex !== -1) {
              //         var decimalPlaces = roundedNumber.length - decimalIndex - 1;
              //         if (decimalPlaces > 3) {
              //           roundedNumber = roundedNumber.slice(0, decimalIndex + 4);
              //         }
              //       }
              //       return roundedNumber;
              //     };

              //     let data = {
              //       bidPrice: bidPrice,
              //       askPrice: askPrice,
              //     };
              //     setCryptoSelectValue(data);
              //     // console.log("yupstockid", value)
              //     setTradeInput({
              //       ...tradeInput,
              //       bid_size: 0,
              //       bid_price: bidPrice,
              //       ask_size: 0,
              //       ask_price: askPrice,
              //       TransFee: askPrice,
              //       StockCode: value.stockCode,
              //       StockName: value.stockCode,
              //       Margin: value.margin,
              //       country: value.country,
              //       symbol: codeArr[codeArr.length - 2],
              //       stockId: value.stockId,
              //     });
              //     setNewsTradeCode(value.stockCode);
              //     setCryptodetails([
              //       ...cryptoDetails,
              //       {
              //         s: s + 1,
              //         askPrice: askPrice,
              //         bidPrice: bidPrice,
              //       },
              //     ]);
              //   }
              // );
            }
          });
        });
        // getExchangeRateAPI(codeArr[2], 'USD').then((respons) => {

        // console.log("tradeInput", newsTradeCode);
      } catch (error) {}
      // document.getElementById('pills-know-tab-overView').click()

      // setInitialState({ ...initialState, transactionPrice: res.data[0]?.close, stockName: value.stockName, margin: value.margin, stockCode: value.stockCode })
    }
  };
  useEffect(() => {
    if (
      (stockId == "" || stockId == undefined || stockId == null) &&
      providerStockList.length > 0
    ) {
      handleStockData(providerStockList[0]);
    }
  }, [providerStockList]);
  const handleSelectedStock = (val) => {
    if (watchList.length > 0) {
      // debugger;
      let n = watchList.filter((value) => value.stockCode == val.stockCode);

      if (n.length > 0) {
        setWatchListAdded({ status: true, id: n[0].watchListId });
      } else {
        setWatchListAdded({ status: false, id: "" });
      }
    }
    // console.log("beforeval", val);
    handleStockData(val);
    const element = document.getElementById("pills-know-tab-overView");
    if (element) {
      element.click();
    } else {
      console.warn('Element with ID "pills-know-tab-overView" not found.');
    }
  };

  const handleChangeTradeType = (val) => {
    if (val === "buy") {
      setTradeInput({
        ...tradeInput,
        TransFee: cryptoSelectValue?.askPrice || "",
        ValueInShares: "",
        quantity: "",
        StopLossPrice: "",
        GSLOPrice: "",
        Short: val,
      });
    } else if (val === "sell") {
      setTradeInput({
        ...tradeInput,
        TransFee: cryptoSelectValue?.bidPrice || "",
        ValueInShares: "",
        quantity: "",
        StopLossPrice: "",
        GSLOPrice: "",
        Short: val,
      });
    }
    setCheckInput(false);
    setCheckInput(false);
    setBuySell(val);
  };

  const handletradeBuySell = () => {
    if (checkStop == "0") {
      if (
        TransFee !== "" &&
        ValueInShares !== "" &&
        quantity !== "" &&
        GSLOPrice !== ""
      ) {
        openTradeFun();
        setCheckInput(false);
      } else {
        setCheckInput(true);
      }
    } else if (checkStop == "1") {
      if (
        TransFee !== "" &&
        ValueInShares !== "" &&
        quantity !== "" &&
        StopLossPrice !== ""
      ) {
        openTradeFun();
        setCheckInput(false);
      } else {
        setCheckInput(true);
      }
    }
  };

  const handleCloseMarketoverView = () => {
    // setMarketOverviewTruw(false)
    setCheckInput(false);
    setBuySell("buy");
    setSelectBroker(providerList[0]?.providerId);
    setTradeInput({
      ...tradeInput,
      PortfolioID: portfololioList[0]?.portfolioId,
      ProviderID: providerList[0]?.providerId,
      providerName: providerList[0]?.providerName,
      AtRiskDollar: 0,
      AtRiskPercent: 0,
      FromTransPriceDollar: 0,
      FromTransPricePercent: 0,
      GSLOTradingFee: 0,
      GSLOCost: 0,
      GSLOPrice: "",
      StopLossPrice: 0,
      Comments: "",
      ValueInShares: "",
      ValueInCFD: 0,
      TransCost: 0,
      OpenDate: new Date(),
      Short: "buy",
      Share_Sector: "Stock",
      UserId: userType.userId,
      quantity: "",
      StopLossPrice: 0,
      type: "TRADES",
    });
    setStockPageData({
      ...stockpageData,
      search: "",
    });
    setCheckStop("0");
    const element = document.getElementById("pills-know-tab-overView");
    if (element) {
      element.click();
    } else {
      console.warn('Element with ID "pills-know-tab-overView" not found.');
    }
  };

  // useEffect(() => {
  //     try {
  //         getCryptoSymbols('[COINFIELD_SPOT_ETH_USD]').then((res) => {
  //             if (res?.status === 200) {
  //
  //             }
  //         })
  //     } catch (error) {

  //     }
  // }, [])

  //  Select Crypto from watchlist
  const handleSelectCrypto = (val) => {
    if (val.watchListId) {
      setWatchListAdded({ status: true, id: val.watchListId });
    } else {
      setWatchListAdded({ status: false, id: "" });
    }
    let s = providerList.filter((item) => {
      if (item.providerName == val.providerName) return item;
    });

    let codeArr = val.stockCode.split("_");
    //console.log("anothercodeArr", codeArr);
    //  console.log("above anotherecodearray val", val);
    setTradeInput({
      ...tradeInput,
      symbol: codeArr[0],
      stockId: val?.stockId,
      StockCode: val?.stockCode,
      country: val?.country,
      ProviderID: s[0]?.providerId,
    });
    setSelectBroker(s[0]?.providerId);
    const element = document.getElementById("pills-know-tab");
    if (element) {
      element.click();
    } else {
      console.warn('Element with ID "pills-know-tab" not found.');
    }
    // document.getElementById("pills-know-tab").click();
    // providerName
  };

  // WatchList start
  useEffect(() => {
    // debugger;
    let data = {
      id: userType.userId,
      pageNumber: currentPageWatch,
      pageSize: pageSizeWatch,
    };
    if (data.id !== undefined) {
      try {
        getWatchList(data).then((res) => {
          if (res?.status === 200) {
            setWatchList(res.data.data);
            setWatchListRun(false);
            setPaginationWatch({
              ...paginationWatch,
              totalPagesWatch: res.data.totalPages,
            });
          }
        });
      } catch (error) {}
    }
  }, [
    watchListRun === true,
    userType,
    watchListRun,
    currentPageWatch,
    pageSizeWatch,
  ]);

  const handleRemoveWatchListConfirm = (id) => {
    try {
      removeWatchList(id).then((res) => {
        if (res?.status === 200) {
          setWatchListRun(true);
        }
      });
    } catch (error) {}
  };

  const handleAddWatchList = () => {
    let data = {
      userId: userType?.userId,
      country: tradeInput.country,
      providerName: tradeInput.providerName,
      stockId: tradeInput.stockId,
      stockCode: tradeInput.StockCode,
      stockName: tradeInput.StockCode,
    };

    try {
      AddWatchList(data).then((res) => {
        if (res?.status === 200) {
          setWatchListRun(true);
        }
      });
    } catch (error) {}
  };

  const handlePageChangeWatch = (newPage) => {
    setPaginationWatch({
      ...paginationWatch,
      currentPageWatch: currentPageWatch + newPage,
    });
    // Perform data fetching or other actions when page changes
  };

  useEffect(() => {
    if (watchList.length > 0) {
      // debugger;
      let n = watchList.filter(
        (value) => value.stockCode == tradeInput.StockCode
      );
      if (n.length > 0) {
        setWatchListAdded({ status: true, id: n[0].watchListId });
      } else {
        setWatchListAdded({ status: false, id: "" });
      }
    } else {
      setWatchListAdded({ status: false, id: "" });
    }
  }, [watchList]);
  // WatchList End

  // getting strategyList
  const getTradingSystemList = () => {
    if (userType?.userId !== undefined) {
      try {
        getStrategy(userType?.userId).then((res) => {
          if (res?.status === 200) {
            let optionsStrategy = [];
            let data = res.data;

            for (let i = 0; i < data.length; i++) {
              optionsStrategy.push({
                value: data[i].tradingSystemId,
                label: data[i].name,
              });
            }
            setStrategyList(data);
            // setRunAgainbAPI(false)
          }
        });
      } catch (error) {}
    }
  };

  useEffect(() => {
    getTradingSystemList();
  }, [userType]);

  const openTradeFun = () => {
    let apiData = {
      portfolioID: Number(tradeInput.PortfolioID),
      userId: userType?.userId,
      strategy: tradeInput?.strategy,
      // type: tradeInput?.type,
      country: tradeInput.country,
      providerID: Number(tradeInput.ProviderID),
      stockCode: tradeInput.StockCode,
      stockName: tradeInput.StockName,
      share_Sector: tradeInput.Share_Sector,
      margin: tradeInput.Margin.toString(),
      short: tradeInput.Short == "buy" ? "false" : "true",
      openDate: new Date(),
      qty: tradeInput.quantity.toString(),
      transFee: Number(tradeInput.TransFee),
      transCost: Number(tradeInput.TransCost),
      valueInCFD: Number(tradeInput.ValueInCFD),
      valueInShares: tradeInput.ValueInShares.toString(),
      comments: tradeInput.Comments,
      stopLossPrice: Number(tradeInput.StopLossPrice),
      gsloPrice: Number(tradeInput.GSLOPrice),
      gsloCost: Number(tradeInput.GSLOCost),
      gsloTradingFee: Number(tradeInput.GSLOTradingFee),
      fromTransPricePercent: Number(tradeInput.FromTransPricePercent),
      fromTransPriceDollar: Number(tradeInput.FromTransPriceDollar),
      atRiskPercent: Number(tradeInput.AtRiskPercent),
      atRiskDollar: Number(tradeInput.AtRiskDollar),
      // bid_size: tradeInput.bid_size.toString(),
      // bid_price: tradeInput.bid_price.toString(),
      // ask_size: tradeInput.ask_size.toString(),
      // ask_price: tradeInput.ask_price.toString(),
    };

    try {
   //   console.log("apidata", apiData);
      AddOpenTradeAPI(apiData).then((res) => {
        if (res.status === 200) {
          setTradeInput({
            ...tradeInput,
            strategy: "",
            atRiskDollar: 0,
            atRiskPercent: 0,
            fromTransPriceDollar: 0,
            fromTransPricePercent: 0,
            gsloTradingFee: 0,
            gsloCost: 0,
            gsloPrice: 0,
            stopLossPrice: 0,
            comments: "",
            valueInShares: "",
            valueInCFD: 0,
            transCost: 0,
            bid_size: 0,
            // bid_price: 0,
            // ask_size: "",
            // ask_price: "",
            openDate: new Date(),
            short: "buy",
            share_Sector: "Stock",
            userId: userType.userId,
            qty: "",
          });
          let optmClose = document.getElementById("openTradeModelClose");
          optmClose.click();
        }
      });
    } catch (error) {}
  };

  const [showSideBar, setShowSideBar] = useState(true);
  const [stockDate, setStockDate] = useState("1M");
  const chartRefMarket = useRef();
  let tvScriptLoadingPromise;

  useEffect(() => {
    chartRefMarket.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(
      () => chartRefMarket.current && chartRefMarket.current()
    );
    function createWidget() {
      debugger
      if (
        document.getElementById("tradingview_ac040_market") &&
        "TradingView" in window
      ) {
        if (symbol && typeof symbol === "string" && symbol.endsWith(".NS")) {
          let it = symbol.slice(0, -3);
         // console.log("itttsdsf", it)
        setTradeInput((prev) => ({
          ...prev,
          symbol: it,
        }));
       }
       // console.log("widget", symbol, stockDate);
        const widget = (window.tvWidget = new window.TradingView.widget({
          autosize: true,
          symbol: `${symbol}`,
          interval: `${stockDate}`,
          timezone: "Etc/UTC",
          theme: "light",
          style: chartType,
          locale: "en",
          toolbar_bg: "#f1f3f6",
          // enable_publishing: true,
          // withdateranges: true,
          // range: "YTD",
          hide_top_toolbar: true,
          hide_side_toolbar: showSideBar,
          allow_symbol_change: true,
          save_image: false,
          hide_legend: true,
          // details: true,
          // studies: [
          //     "ElliottWave@tv-basicstudies",
          // ],
          // show_popup_button: true,
          container_id: "tradingview_ac040_market",
        }));
        return () => {
          widget.remove();
        };
      }
    }
  }, [chartType, showSideBar, stockDate, symbol]);

  // document.querySelector('.tv-header__link').remove();

  const handleSelectDate = (n) => {
    setStockDate(n);
  };

  const getNews = async (count) => {
    let splitedCode = newsTradeCode.split("_");
    // console.log("getNews", newsTradeCode);
    // console.log("yppp", mycode);
    if (splitedCode[0] != undefined) {
      try {
        debugger;
        // getStockNews(symbol).then((res) => {
        if (symbol === "") {
          getRapidStockNews(mycode).then((res) => {
            setNewsList(res?.data || []);
          });
        } else {
          getRapidStockNews(symbol).then((res) => {
            setNewsList(res?.data || []);
          });
        }
      } catch (error) {
        console.error(error);
      }
      setNewsLoading(false);
    }
    setNewsLoading(false);
  };
  let newsLength = 5;

  const handleShowMore = () => {
    setNewsListCount(newsListCount + 5);
    getNews(newsListCount + 5);
  };
  const handleChangeStopLossType = (e) => {
    if (e.target.value == "0") {
      setTradeInput({
        ...tradeInput,
        StopLossPrice: 0,
        GSLOPrice: "",
        // brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId
      });
    } else {
      setTradeInput({
        ...tradeInput,
        StopLossPrice: "",
        GSLOPrice: 0,
        // brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId
      });
    }
    setTradeInput({
      ...tradeInput,
      StopLossPrice: "",
      GSLOPrice: "",
      // brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId
    });
    setCheckStop(e.target.value);
  };
  const deleteConfirm = () => {
    handleRemoveWatchListConfirm();
  };
  const handleRemoveWatchList = (id) => {
    setDeleteConfirmData(id);
    setTypeMsg("Delete This WatchList");
  };
  const handleBlurStoploss = () => {
    //sell = long
    //long stopprice should be less
    if (Short == "sell") {
      if (TransFee <= Number(StopLossPrice)) {
        toast.error("Set lower stop price than at price while Long trading");
      }
    }
  };
  function removeTrailingZeros(number) {
    // Convert the number to a string and use a regular expression
    // to remove trailing zeros and the decimal point if it's followed by only zeros.
    const result = number.toString().replace(/(\.[0-9]*[1-9])0+$/, "$1");

    return result;
  }

  useEffect(() => {
    console.log("Updated tradeInput:", tradeInput);
    console.log("Updated newsTradeCode:", newsTradeCode);
  }, [tradeInput, newsTradeCode]);

  // console.log(
  //   "crytoDetail",
  //   cryptoDetails,
  //  tradeInput.bid_price, tradeInput.ask_price
  // );
  // console.log("crytoDetail1");

  //console.log("toggle", toggleSide);

  return (
    <div
      className="modal fade hide backdrop-1"
      id="MarketsOverview"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="exampleModalLgLabel"
      aria-modal="true"
      role="dialog"
      style={{ display: "none" }}
    >
      <div class="modal-dialog modal-lg about-data-lg">
        <div class="modal-content cal-model">
          <div class="modal-header">
            <h5 class="modal-title h4" id="exampleModalLgLabel">
              Market Overview ({" "}
              <span className="coin-name">{tradeInput?.symbol}</span> )
            </h5>
            <button
              type="button"
              className=" ms-auto button-padding"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="openTradeModelClose"
              onClick={handleCloseMarketoverView}
            >
              ×
            </button>
          </div>
          <div class="modal-body">
            {/* <!-- ==data start ====  --> */}
            <div class="d-flex column_gap-12">
              {/* <!-- ===left side bar==== --> */}
              <div class=" lef_side_bar left-bar-height-issue flex-shrink-0">
                {/* <!-- <div class="search mb-3">
                                                    <i class="bi bi-search" type="submit"></i>
                                                    <input type="text" class="form-control" placeholder="Search Now">
                                                </div> --> */}
                <div class="a">
                  {/* <!-- <header class="header" id="header">
                                                        <div class="header_toggle"> <i class='bx bx-menu' id="header-toggle"></i> </div>
                                                        <div class="header_img"> <img src="https://i.imgur.com/hczKIze.jpg" alt=""> </div>
                                                    </header> --> */}
                  <div
                    class={toggleSide ? "l-navbar show1" : "l-navbar"}
                    id="nav-bar"
                  >
                    <nav class="nav">
                      <div>
                        <div class="nav_list">
                          <ul class="list-group list-group-flush ">
                            <li>
                              <div href="#" class="nav_logo_main">
                                <header
                                  class={
                                    toggleSide
                                      ? "header gap-1_9 nav-link data-bx-min d-flex body-pd "
                                      : "gap-1_9 header data-bx-min d-flex nav-link"
                                  }
                                  id="header"
                                >
                                  <div class="header_toggle">
                                    {" "}
                                    <i
                                      class={
                                        toggleSide
                                          ? "bx bx-menu bx-x toggle-font white_text"
                                          : "bx bx-menu toggle-font white_text"
                                      }
                                      onClick={() => setToggleSide(!toggleSide)}
                                      id="header-toggle"
                                    ></i>{" "}
                                  </div>
                                  {/* <!-- <div class="header_img"> <img src="https://i.imgur.com/hczKIze.jpg" alt=""> </div> --> */}
                                  <div class="search w-81">
                                    <i
                                      class="bi bi-search "
                                      type="submit"
                                      onClick={getProviderStockList}
                                    ></i>
                                    <input
                                      type="text"
                                      class="form-control w-100"
                                      value={search}
                                      placeholder="Search Now"
                                      onKeyUp={(e) =>
                                        (e.key == "Enter" ||
                                          e.target.value.length == 0) &&
                                        getProviderStockList()
                                      }
                                      onChange={(e) =>
                                        setStockPageData({
                                          ...stockpageData,
                                          search: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </header>
                              </div>
                            </li>
                            {toggleSide && (
                              <li>
                                <div class="nav_link data-bx-min d-flex">
                                  {/* <i><img src="assets/image/1inch.png" class="img-fluid" /></i> */}
                                  <select
                                    class="form-select"
                                    value={selectBroker}
                                    aria-label="Default select example"
                                    onChange={handleChangeBroker}
                                  >
                                    <option selected>Select Provider</option>
                                    {providerList.map((val, ind) => {
                                      return (
                                        <option
                                          key={ind}
                                          value={val.providerId}
                                        >
                                          {val.providerName}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </li>
                            )}

                            {providerStockList.length > 0 ? (
                              providerStockList.map((val, ind) => {
                                return (
                                  <SymbleListing
                                    askprices={
                                      cryptoDetails[cryptoDetails?.length - 1]
                                        ?.askPrice || 0
                                    }
                                    val={val}
                                    handleSelectedStock={handleSelectedStock}
                                    tradeInput={tradeInput}
                                  />
                                );
                              })
                            ) : (
                              <p className="text-center">Record not found</p>
                            )}

                            {/* <li>
                                                            <div class="nav_link data-bx-min d-flex">
                                                                <i><img src="assets/image/1inch.png" class="img-fluid" /></i>
                                                                <span class="flex-shrink-0 w-151">0x (zrx) ₹19.47 </span>
                                                                <button class="btn ms-auto d-block flex-shrink-0 btn-p profit-loss-button">2.17 %  <img src="image/download-icon.svg" class="img-fluid text-end" /> </button>
                                                            </div>
                                                        </li> */}
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
                {/* <!--Container Main start--> */}
              </div>
              {/* <!-- ===left side bar==== --> */}

              <div class="w-100">
                <div class="about-data-right tabs-sec">
                  <ul
                    class="nav nav-pills nav-justified mb-3 "
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link button-link-overview height-36px active"
                        id="pills-know-tab-overView"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-know"
                        type="button"
                        role="tab"
                        aria-controls="pills-know"
                        aria-selected="false"
                      >
                        Overview
                      </button>
                    </li>
                    <li
                      class="nav-item"
                      onClick={() => getNews(newsLength)}
                      role="presentation"
                    >
                      <button
                        class="nav-link button-link-overview  height-36px"
                        id="pills-about-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-about"
                        type="button"
                        role="tab"
                        aria-controls="pills-about"
                        aria-selected="true"
                      >
                        News
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link button-link-overview height-36px"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#Watchlist_tab"
                        type="button"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        Watchlist
                      </button>
                    </li>
                  </ul>
                </div>

                <div class="about-data-min">
                  <div class="tab-content" id="pills-tabContentnew">
                    {/* OverVCiew Section start */}
                    <div
                      class="tab-pane fade show active"
                      id="pills-know"
                      role="tabpanel"
                      aria-labelledby="pills-know-tab"
                    >
                      {/* <!-- ==== know more ===== --> */}

                      <div class="row rowModed p-left-row">
                        <div class="lef_side_bar">
                          {/* <h5 class="mt-1 mb-1">TRADING STOCKS ?</h5> */}

                          <div class="col-lg-12 mt-3">
                            <div class=" about-content-bx top-know-price-bx top-know-price-bx-1">
                              <div class="row">
                                <div class="col-lg-6">
                                  <div class="know-prd-bx">
                                    <h6>Open Price</h6>
                                    <span
                                      class={
                                        checkAskPrLo == "up"
                                          ? "know-price-1 colorGreen"
                                          : checkAskPrLo == "down"
                                          ? "know-price-1 colorRed"
                                          : "know-price-1"
                                      }
                                    >
                                      {" "}
                                      ${" "}
                                      {removeTrailingZeros(
                                        cryptoDetails[cryptoDetails?.length - 1]
                                          ?.openPrice || 0
                                      )}{" "}
                                      {checkAskPrLo == "up" ? (
                                        <BsFillArrowUpRightCircleFill />
                                      ) : checkAskPrLo == "down" ? (
                                        <BsArrowDownRightCircleFill />
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                    {/* <span class="float-end know-price-2"><img src="assets/image/down-aero.svg" class="img-fluid"/> ₹6.538</span>   */}
                                  </div>
                                </div>

                                <div class="col-lg-6">
                                  <div class="know-prd-bx">
                                    <h6>Close Price</h6>
                                    <span
                                      class={
                                        checkAskPrLo == "up"
                                          ? "know-price-1 colorGreen"
                                          : checkAskPrLo == "down"
                                          ? "know-price-1 colorRed"
                                          : "know-price-1"
                                      }
                                    >
                                      {" "}
                                      ${" "}
                                      {removeTrailingZeros(
                                        cryptoDetails[cryptoDetails?.length - 1]
                                          ?.bidPrice ||
                                          tradeInput?.bid_price ||
                                          0
                                      )}{" "}
                                      {checkAskPrLo == "up" ? (
                                        <BsFillArrowUpRightCircleFill />
                                      ) : checkAskPrLo == "down" ? (
                                        <BsArrowDownRightCircleFill />
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                    {/* <span class="float-end know-price-2"><img src="assets/image/down-aero.svg" class="img-fluid"/> ₹6.538</span>   */}
                                  </div>
                                </div>

                                <div class="col-lg-6">
                                  <div class="know-prd-bx">
                                    <h6>High Price</h6>
                                    <span
                                      class={
                                        checkAskPrLo == "up"
                                          ? "know-price-1 colorGreen"
                                          : checkAskPrLo == "down"
                                          ? "know-price-1 colorRed"
                                          : "know-price-1"
                                      }
                                    >
                                      {" "}
                                      ${" "}
                                      {cryptoDetails[cryptoDetails?.length - 1]
                                        ?.askPrice || 0}{" "}
                                      {checkAskPrLo == "up" ? (
                                        <BsFillArrowUpRightCircleFill />
                                      ) : checkAskPrLo == "down" ? (
                                        <BsArrowDownRightCircleFill />
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                    {/* <span class="float-end know-price-2"><img src="assets/image/down-aero.svg" class="img-fluid"/> ₹6.538</span>   */}
                                  </div>
                                </div>

                                <div class="col-lg-6">
                                  <div class="know-prd-bx border-0">
                                    <h6>Low Price</h6>
                                    <span
                                      class={
                                        checkBidPrLo == "up"
                                          ? "know-price-1 colorGreen"
                                          : checkBidPrLo == "down"
                                          ? "know-price-1 colorRed"
                                          : "know-price-1"
                                      }
                                    >
                                      {" "}
                                      {removeTrailingZeros(
                                        cryptoDetails[cryptoDetails?.length - 1]
                                          ?.lowPrice
                                          ? '$ ' + removeTrailingZeros(
                                              cryptoDetails[
                                                cryptoDetails?.length - 1
                                              ]?.lowPrice
                                            )
                                          : "$ 0"
                                      )}{" "}
                                      {checkBidPrLo == "up" ? (
                                        <BsFillArrowUpRightCircleFill />
                                      ) : checkBidPrLo == "down" ? (
                                        <BsArrowDownRightCircleFill />
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                    {/* <span class="float-end know-price-2"><img src="assets/image/down-aero.svg" class="img-fluid"/> ₹6.538</span>   */}
                                  </div>
                                </div>

                                <div class="col-lg-6">
                                  <div class="know-prd-bx border-0">
                                    <h6>Dividend Rate</h6>
                                    <span
                                      class={
                                        checkBidPrLo == "up"
                                          ? "know-price-1 colorGreen"
                                          : checkBidPrLo == "down"
                                          ? "know-price-1 colorRed"
                                          : "know-price-1"
                                      }
                                    >
                                      {" "}
                                     {removeTrailingZeros(
                                        cryptoDetails[cryptoDetails?.length - 1]
                                          ?.dividendRate
                                          ? "$ "+ removeTrailingZeros(
                                              cryptoDetails[
                                                cryptoDetails?.length - 1
                                              ]?.dividendRate
                                            )
                                          : "N/A"
                                      )}{" "}
                                      {checkBidPrLo == "up" ? (
                                        <BsFillArrowUpRightCircleFill />
                                      ) : checkBidPrLo == "down" ? (
                                        <BsArrowDownRightCircleFill />
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                    {/* <span class="float-end know-price-2"><img src="assets/image/down-aero.svg" class="img-fluid"/> ₹6.538</span>   */}
                                  </div>
                                </div>

                                <div class="col-lg-6">
                                  <div class="know-prd-bx border-0">
                                    <h6>Dividend Yield</h6>
                                    <span
                                      class={
                                        checkBidPrLo == "up"
                                          ? "know-price-1 colorGreen"
                                          : checkBidPrLo == "down"
                                          ? "know-price-1 colorRed"
                                          : "know-price-1"
                                      }
                                    >
                                      {" "}
                                      {removeTrailingZeros(
                                        cryptoDetails[cryptoDetails?.length - 1]
                                          ?.dividendYeild
                                          ? removeTrailingZeros(
                                              cryptoDetails[
                                                cryptoDetails?.length - 1
                                              ]?.dividendYeild
                                            )+"%"
                                          : "N/A"
                                      )}{" "}
                                      {checkBidPrLo == "up" ? (
                                        <BsFillArrowUpRightCircleFill />
                                      ) : checkBidPrLo == "down" ? (
                                        <BsArrowDownRightCircleFill />
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                    {/* <span class="float-end know-price-2"><img src="assets/image/down-aero.svg" class="img-fluid"/> ₹6.538</span>   */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="postion-min-dta postion-frm mt-3 tread-vol-bx border-redious-top-left">
                            <div class="row">
                              <div class="col-10  border-bottom">
                                <ul
                                  class="nav nav-pills d-flex"
                                  id="pills-tab"
                                  role="tablist"
                                >
                                  <li class="nav-item" role="presentation">
                                    <button
                                      class="nav-link active"
                                      id="pills-home-tab"
                                      data-bs-toggle="pill"
                                      onClick={() => setChartType("2")}
                                      data-bs-target="#lineChart"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-home"
                                      aria-selected="true"
                                    >
                                      Line Chart
                                    </button>
                                  </li>
                                  <li class="nav-item" role="presentation">
                                    <button
                                      class="nav-link"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#barChart"
                                      onClick={() => setChartType("1")}
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Candle Chart
                                    </button>
                                  </li>
                                  <li class="nav-item" role="presentation">
                                    <button
                                      class="nav-link"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#barChart"
                                      onClick={() => setChartType("0")}
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Bar Chart
                                    </button>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-2">
                                <div className="d-flex justify-content-end">
                                  {watchListAdded.status ? (
                                    <button
                                      class="btn padding-btn-dtd watch w-100"
                                      title="Remove to WatchList"
                                      onClick={() =>
                                        handleRemoveWatchList(watchListAdded.id)
                                      }
                                    >
                                      <img
                                        src="assets/image/watchlist.png"
                                        class="img-fluid"
                                        style={{ "max-width": "34px" }}
                                        alt=""
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      class="btn padding-btn-dtd watchWhite w-100"
                                      title="Add to WatchList"
                                      onClick={handleAddWatchList}
                                    >
                                      <img
                                        src="assets/image/watchwhite.png"
                                        class="img-fluid"
                                        style={{ "max-width": "34px" }}
                                        alt=""
                                      />
                                    </button>
                                  )}
                                  <Link
                                    to={`/chart?tradeCode=${symbol}`}
                                    state={{ symbol }}
                                    target="_blank"
                                    className="candle-chart-link ms-auto"
                                  >
                                    <button
                                      className="btn expend-btn"
                                      title="View In Full Screen"
                                    >
                                      <FaExpand />
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="">
                                <div class="tab-content" id="pills-tabContent">
                                  <div
                                    class=""
                                    id="lineChart"
                                    role="tabpanel"
                                    aria-labelledby="pills-home-tab"
                                  >
                                    <div class="row">
                                      <div class="col-md-12 mt-1">
                                        {/* <p ref={chartRefMarketCry}></p> */}
                                        <div className="tradingview-widget-container-crypto">
                                          <button
                                            className={
                                              showSideBar
                                                ? "show-widgth-button show-widgth-button-hide"
                                                : "show-widgth-button show-widgth-button-show"
                                            }
                                            onClick={() =>
                                              showSideBar
                                                ? setShowSideBar(false)
                                                : setShowSideBar(true)
                                            }
                                          >
                                            {showSideBar ? (
                                              <IoIosArrowForward />
                                            ) : (
                                              <IoIosArrowBack />
                                            )}
                                          </button>
                                          <div
                                            id="tradingview_ac040_market"
                                            className="tradingview_5327b-css-crypto"
                                          />
                                          <p ref={chartRefMarket}></p>
                                          <div class="col-sm-12 ">
                                            <img
                                              src="assets/image/trading-view.png"
                                              style={{
                                                width: "35px",
                                                marginRight: "3px",
                                                marginBottom: "3px",
                                              }}
                                              alt="img"
                                            />
                                            <span
                                              style={{
                                                textDecoration: "underline",
                                                textDecorationColor: "#ff8038",
                                              }}
                                              className=""
                                            >
                                              <Link
                                                to={`https://www.tradingview.com`}
                                                target="_blank"
                                              >
                                                <span
                                                  style={{
                                                    cssText:
                                                      "color: #ff8038 !important;",
                                                  }}
                                                >
                                                  Chart From TradingView.com
                                                </span>
                                              </Link>
                                            </span>
                                            <ul class="slec-dta-ch">
                                              <li
                                                className={
                                                  stockDate === "1M" &&
                                                  "day-active white"
                                                }
                                                onClick={() =>
                                                  handleSelectDate("1M")
                                                }
                                              >
                                                <Link
                                                  className={
                                                    stockDate === "1M" &&
                                                    "day-active white"
                                                  }
                                                >
                                                  1m
                                                </Link>
                                              </li>
                                              <li
                                                className={
                                                  stockDate === "5M" &&
                                                  "day-active white"
                                                }
                                                onClick={() =>
                                                  handleSelectDate("5M")
                                                }
                                              >
                                                <Link
                                                  className={
                                                    stockDate === "5M" &&
                                                    "day-active white"
                                                  }
                                                >
                                                  5M
                                                </Link>
                                              </li>
                                              <li
                                                className={
                                                  stockDate === "30D" &&
                                                  "day-active white"
                                                }
                                                onClick={() =>
                                                  handleSelectDate("30D")
                                                }
                                              >
                                                <Link
                                                  className={
                                                    stockDate === "30D" &&
                                                    "day-active white"
                                                  }
                                                >
                                                  30M
                                                </Link>
                                              </li>
                                              <li
                                                className={
                                                  stockDate === "60D" &&
                                                  "day-active white"
                                                }
                                                onClick={() =>
                                                  handleSelectDate("60D")
                                                }
                                              >
                                                <Link
                                                  className={
                                                    stockDate === "60D" &&
                                                    "day-active white"
                                                  }
                                                >
                                                  1H
                                                </Link>
                                              </li>
                                              <li
                                                className={
                                                  stockDate === "1D" &&
                                                  "day-active white"
                                                }
                                                onClick={() =>
                                                  handleSelectDate("1D")
                                                }
                                              >
                                                <Link
                                                  className={
                                                    stockDate === "1D" &&
                                                    "day-active white"
                                                  }
                                                >
                                                  1D
                                                </Link>
                                              </li>
                                              <li
                                                className={
                                                  stockDate === "1W" &&
                                                  "day-active white"
                                                }
                                                onClick={() =>
                                                  handleSelectDate("1W")
                                                }
                                              >
                                                <Link
                                                  className={
                                                    stockDate === "1W" &&
                                                    "day-active white"
                                                  }
                                                >
                                                  1W
                                                </Link>
                                              </li>

                                              {/* <li><Link onclick={handleSelectDate}>max</Link></li>
									<li><Link onclick={handleSelectDate}>Sip</Link></li> */}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div class="tab-pane fade" id="barChart" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                                        <div class="row">
                                                                            <div class="col-lg-12 border-bottom ">
                                                                                <div class="data-bx-min mt-3 mb-2">
                                                                                    <span><img src="assets/image/zrx.png" class="img-fluid" /> 0x (zrx) ₹19.47 </span>

                                                                                    <button class="btn ms-auto d-block price-btn">2.17 %  <img src="assets/image/download-icon.svg" class="img-fluid text-end" /> </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 mt-3">
                            {/* <p ref={chartRefMarketCry}></p> */}
                            {/* <LineChart data={data} /> */}
                          </div>

                          <div class="col-lg-12 mt-5">
                            <div class="postion-min-dta postion-frm focusCss mt-3 tread-vol-bx border-redious-top-left">
                              <div class="row">
                                <div class="col-8  border-bottom">
                                  <ul
                                    class="nav nav-pills "
                                    id="pills-tab"
                                    role="tablist"
                                  >
                                    <li class="nav-item" role="presentation">
                                      <button
                                        class={
                                          buySell == "buy"
                                            ? "nav-link active active-buy"
                                            : "nav-link"
                                        }
                                        onClick={() =>
                                          handleChangeTradeType("buy")
                                        }
                                        id="pills-home-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#buyTrade"
                                        type="button"
                                        role="tab"
                                        aria-controls="buyTrade"
                                        aria-selected="true"
                                      >
                                        Short
                                        {/* Buy */}
                                      </button>
                                    </li>
                                    <li class="nav-item " role="presentation">
                                      <button
                                        class={
                                          buySell == "sell"
                                            ? "nav-link active active-sell"
                                            : "nav-link"
                                        }
                                        onClick={() =>
                                          handleChangeTradeType("sell")
                                        }
                                        id="pills-profile-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#sellTrade"
                                        type="button"
                                        role="tab"
                                        aria-controls="sellTrade"
                                        aria-selected="false"
                                      >
                                        {/* Sell */}
                                        Long
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                                <div class="col-4">
                                  <select
                                    type="text"
                                    class="w-100 custom-select select-limit"
                                    value={checkStop}
                                    onChange={handleChangeStopLossType}
                                    id="formGroupExampleInput"
                                    placeholder="0"
                                    autocomplete="off"
                                  >
                                    <option value={"0"}>GSLO Price</option>
                                    <option value={"1"}>Stop Limit</option>
                                    {/* <option value={"2"}>Market</option> */}
                                  </select>
                                </div>
                              </div>

                              <div class="tab-content" id="pills-tabContent">
                                <Formik
                                  initialValues={tradeInput}
                                  enableReinitialize={true}
                                  validationSchema={
                                    checkStop == "1"
                                      ? validationSchemaStopLoss
                                      : validationSchema
                                  }
                                  onSubmit={(value) => {
                                    handletradeBuySell();
                                  }}
                                >
                                  {({ errors, touched, values }) => (
                                    <Form>
                                      <div class="row">
                                        <div class="">
                                          <div class=" mt-4">
                                            <div class="row">
                                              <div class="col-lg-6 mb-4">
                                                <div
                                                  class={
                                                    "know-commper-bx-min min-overview d-flex"
                                                  }
                                                >
                                                  <label
                                                    for="formGroupExampleInput"
                                                    class="form-label label_grey_input"
                                                  >
                                                    Portfolio{" "}
                                                    <span
                                                      style={{ color: "#f00" }}
                                                    >
                                                      *
                                                    </span>
                                                  </label>
                                                  {/* <label>Select trading System</label> */}
                                                  <span className="border-2px"></span>
                                                  <Field
                                                    name="PortfolioID"
                                                    as="select"
                                                    value={PortfolioID}
                                                    className={
                                                      "form-select input_border w-50 height-39"
                                                    }
                                                    onChange={
                                                      handleChengeTradeInput
                                                    }
                                                  >
                                                    <option
                                                      value=""
                                                      style={{
                                                        visibility: "hidden;",
                                                      }}
                                                    >
                                                      Select Portfolio
                                                    </option>
                                                    {portfololioList?.map(
                                                      (val, ind) => {
                                                        return (
                                                          <option
                                                            key={ind}
                                                            value={
                                                              val.portfolioId
                                                            }
                                                          >
                                                            {val.portfolioName}
                                                          </option>
                                                        );
                                                      }
                                                    )}
                                                  </Field>
                                                </div>
                                                {errors.PortfolioID &&
                                                touched.PortfolioID ? (
                                                  <div className="color-msg-err">
                                                    {errors.PortfolioID}
                                                  </div>
                                                ) : null}
                                                {/* <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span> */}
                                              </div>
                                              <div class="col-lg-6 mb-4">
                                                <div
                                                  class={
                                                    checkInput == true &&
                                                    Share_Sector == ""
                                                      ? "know-commper-bx-min min-overview d-flex input-err-color "
                                                      : "know-commper-bx-min min-overview d-flex"
                                                  }
                                                >
                                                  <label
                                                    for="formGroupExampleInput width_40"
                                                    class="form-label label_grey_input"
                                                  >
                                                    Trade Type{" "}
                                                    <span
                                                      style={{ color: "#f00" }}
                                                    >
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="border-2px"></span>
                                                  <Field
                                                    name="Share_Sector"
                                                    as="select"
                                                    value={Share_Sector}
                                                    className={
                                                      checkInput == true &&
                                                      Share_Sector == ""
                                                        ? "form-select input_border input-err-color w-50 height-39"
                                                        : "form-select input_border w-50 height-39"
                                                    }
                                                    onChange={
                                                      handleChengeTradeInput
                                                    }
                                                  >
                                                    {/* <option value="0" style={{ visibility: "hidden;" }}>Select Share</option> */}
                                                    <option value="SPOT">
                                                      SPOT
                                                    </option>
                                                    <option value="FUTURE">
                                                      FUTURE
                                                    </option>
                                                    <option value="PERPETUAL">
                                                      PERPETUAL
                                                    </option>
                                                    <option value="DEFI">
                                                      DEFI
                                                    </option>
                                                    <option value="TETHER">
                                                      TETHER
                                                    </option>
                                                    <option value="TETHERUS">
                                                      TETHERUS
                                                    </option>
                                                    <option value="INDEX">
                                                      INDEX
                                                    </option>
                                                    <option value="PREMIUM">
                                                      PREMIUM
                                                    </option>
                                                  </Field>
                                                </div>
                                              </div>

                                              <div class="col-lg-6 mb-4">
                                                <div
                                                  class={
                                                    checkInput == true &&
                                                    Strategy == ""
                                                      ? "know-commper-bx-min min-overview d-flex input-err-color "
                                                      : "know-commper-bx-min min-overview d-flex"
                                                  }
                                                >
                                                  <label
                                                    for="formGroupExampleInput"
                                                    class="form-label label_grey_input"
                                                  >
                                                    Strategy{" "}
                                                    <span
                                                      style={{ color: "#f00" }}
                                                    >
                                                      *
                                                    </span>
                                                  </label>

                                                  <span className="border-2px"></span>
                                                  <Field
                                                    name="Strategy"
                                                    as="select"
                                                    value={Strategy}
                                                    className={
                                                      checkInput == true &&
                                                      Strategy == ""
                                                        ? "form-select  input_border input-err-color w-50 height-39"
                                                        : "form-select input_border w-50 height-39"
                                                    }
                                                    onChange={
                                                      handleChengeTradeInput
                                                    }
                                                  >
                                                    <option
                                                      value=""
                                                      style={{
                                                        visibility: "hidden;",
                                                      }}
                                                    >
                                                      Select Trading System
                                                    </option>
                                                    {strategyList?.map(
                                                      (val, ind) => {
                                                        return (
                                                          <option
                                                            key={ind}
                                                            value={
                                                              val.tradingSystemId
                                                            }
                                                          >
                                                            {val.name}
                                                          </option>
                                                        );
                                                      }
                                                    )}
                                                  </Field>
                                                </div>
                                                {errors.Strategy &&
                                                touched.Strategy ? (
                                                  <div className="color-msg-err">
                                                    {errors.Strategy}
                                                  </div>
                                                ) : null}
                                              </div>
                                              <div class="col-lg-6 mb-4">
                                                <div
                                                  class={
                                                    checkInput == true &&
                                                    TransFee == ""
                                                      ? "know-commper-bx-min min-overview d-flex input-err-color "
                                                      : "know-commper-bx-min min-overview d-flex"
                                                  }
                                                >
                                                  <label
                                                    for="formGroupExampleInput "
                                                    class="form-label label_grey_input"
                                                  >
                                                    At Price{" "}
                                                    <span
                                                      style={{ color: "#f00" }}
                                                    >
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="border-2px"></span>
                                                  <input
                                                    type="text"
                                                    name="TransFee"
                                                    class="form-control w-50 height-39"
                                                    value={TransFee}
                                                    onChange={
                                                      handleChengeTradeInput
                                                    }
                                                    id="formGroupExampleInput"
                                                    placeholder="0"
                                                    autocomplete="off"
                                                  />
                                                </div>
                                                {errors.TransFee &&
                                                touched.TransFee ? (
                                                  <div className="color-msg-err">
                                                    {errors.TransFee}
                                                  </div>
                                                ) : null}
                                              </div>

                                              <div class="col-lg-6 mb-4">
                                                <div
                                                  class={
                                                    checkInput == true &&
                                                    ValueInShares == ""
                                                      ? "know-commper-bx-min min-overview d-flex input-err-color "
                                                      : "know-commper-bx-min min-overview d-flex"
                                                  }
                                                >
                                                  <label
                                                    for="formGroupExampleInput"
                                                    class="form-label label_grey_input"
                                                  >
                                                    {/* Amount ${" "} */}
                                                    Shares Value
                                                    <span
                                                      style={{ color: "#f00" }}
                                                    >
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="border-2px"></span>
                                                  <input
                                                    type="text"
                                                    class="form-control w-50 height-39"
                                                    name="ValueInShares"
                                                    value={ValueInShares}
                                                    onChange={
                                                      handleChengeTradeInput
                                                    }
                                                    id="formGroupExampleInput"
                                                    placeholder="0"
                                                    autocomplete="off"
                                                  />
                                                </div>
                                                {errors.ValueInShares &&
                                                touched.ValueInShares ? (
                                                  <div className="color-msg-err">
                                                    {errors.ValueInShares}
                                                  </div>
                                                ) : null}
                                                {/* <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span> */}
                                              </div>

                                              <div class="col-lg-6 mb-4">
                                                <div
                                                  class={
                                                    checkInput == true &&
                                                    quantity == ""
                                                      ? "know-commper-bx-min min-overview d-flex input-err-color"
                                                      : "know-commper-bx-min min-overview d-flex"
                                                  }
                                                >
                                                  <label
                                                    for="formGroupExampleInput"
                                                    class="form-label label_grey_input"
                                                  >
                                                    Quantity{" "}
                                                    <span
                                                      style={{ color: "#f00" }}
                                                    >
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="border-2px"></span>
                                                  <input
                                                    type="text"
                                                    class="form-control w-50 height-39 "
                                                    name="quantity"
                                                    value={quantity}
                                                    onChange={
                                                      handleChengeTradeInput
                                                    }
                                                    id="formGroupExampleInput"
                                                    placeholder="0"
                                                    autocomplete="off"
                                                  />
                                                </div>
                                                {errors.quantity &&
                                                touched.quantity ? (
                                                  <div className="color-msg-err">
                                                    {errors.quantity}
                                                  </div>
                                                ) : null}
                                              </div>
                                            </div>
                                            <div class="row">
                                              {checkStop == "1" && (
                                                <div class="col-lg-6 mb-4">
                                                  <div
                                                    class={
                                                      checkInput == true &&
                                                      StopLossPrice == ""
                                                        ? "know-commper-bx-min min-overview d-flex input-err-color"
                                                        : "know-commper-bx-min min-overview d-flex"
                                                    }
                                                  >
                                                    <label
                                                      for="formGroupExampleInput"
                                                      class="form-label label_grey_input"
                                                    >
                                                      Stop Price{" "}
                                                      <span
                                                        style={{
                                                          color: "#f00",
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    </label>
                                                    <span className="border-2px"></span>
                                                    <input
                                                      type="text"
                                                      class="form-control w-50 height-39"
                                                      name="StopLossPrice"
                                                      onBlur={
                                                        handleBlurStoploss
                                                      }
                                                      value={StopLossPrice}
                                                      onChange={
                                                        handleChengeTradeInput
                                                      }
                                                      id="formGroupExampleInput"
                                                      placeholder="0"
                                                      autocomplete="off"
                                                    />
                                                  </div>
                                                  {errors.StopLossPrice &&
                                                  touched.StopLossPrice ? (
                                                    <div className="color-msg-err">
                                                      {errors.StopLossPrice}
                                                    </div>
                                                  ) : null}

                                                  {/* <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span> */}
                                                </div>
                                              )}
                                              {checkStop == "0" && (
                                                <div class="col-lg-6 mb-4">
                                                  <div
                                                    class={
                                                      checkInput == true &&
                                                      GSLOPrice == ""
                                                        ? "know-commper-bx-min min-overview d-flex input-err-color"
                                                        : "know-commper-bx-min min-overview d-flex"
                                                    }
                                                  >
                                                    <label
                                                      for="formGroupExampleInput"
                                                      class="form-label label_grey_input"
                                                    >
                                                      GSLO Price{" "}
                                                      <span
                                                        style={{
                                                          color: "#f00",
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    </label>
                                                    <span className="border-2px"></span>
                                                    <input
                                                      type="text"
                                                      class="form-control w-50 height-39"
                                                      name="GSLOPrice"
                                                      onBlur={
                                                        handleBlurStoploss
                                                      }
                                                      value={GSLOPrice}
                                                      onChange={
                                                        handleChengeTradeInput
                                                      }
                                                      id="formGroupExampleInput"
                                                      placeholder="0"
                                                      autocomplete="off"
                                                    />
                                                  </div>
                                                  {errors.GSLOPrice &&
                                                  touched.GSLOPrice ? (
                                                    <div className="color-msg-err">
                                                      {errors.GSLOPrice}
                                                    </div>
                                                  ) : null}

                                                  {/* <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span> */}
                                                </div>
                                              )}
                                            </div>
                                            <div class="row ">
                                              <div className="col-md-6 mb-4">
                                                {/* <label>
                                                  Select type{" "}
                                                  <span
                                                    style={{ color: "#f00" }}
                                                  >
                                                    *
                                                  </span>
                                                </label> */}
                                                {/* <div className="d-flex justify-content-between">
                                                  <div class="form-check ">
                                                    <input
                                                      class="form-check-input tradeType"
                                                      type="radio"
                                                      value=""
                                                      id="flexCheckCheckedDisabled"
                                                      checked={type == "TRADES"}
                                                      onClick={() =>
                                                        setTradeInput({
                                                          ...tradeInput,
                                                          type: "TRADES",
                                                        })
                                                      }
                                                    />
                                                    <label
                                                      class="form-check-label"
                                                      for="flexCheckCheckedDisabled"
                                                    >
                                                      TRADES
                                                    </label>
                                                  </div>
                                                  <div class="form-check">
                                                    <input
                                                      class="form-check-input tradeType"
                                                      type="radio"
                                                      value=""
                                                      id="flexCheckCheckedDisabled"
                                                      checked={type == "TRENDS"}
                                                      onClick={() =>
                                                        setTradeInput({
                                                          ...tradeInput,
                                                          type: "TRENDS",
                                                        })
                                                      }
                                                    />
                                                    <label
                                                      class="form-check-label"
                                                      for="flexCheckCheckedDisabled"
                                                    >
                                                      TRENDS
                                                    </label>
                                                  </div>
                                                  <div class="form-check">
                                                    <input
                                                      class="form-check-input tradeType"
                                                      type="radio"
                                                      value=""
                                                      id="flexCheckCheckedDisabled"
                                                      checked={type == "HODL"}
                                                      onClick={() =>
                                                        setTradeInput({
                                                          ...tradeInput,
                                                          type: "HODL",
                                                        })
                                                      }
                                                    />
                                                    <label
                                                      class="form-check-label"
                                                      for="flexCheckCheckedDisabled"
                                                    >
                                                      HODL
                                                    </label>
                                                  </div>
                                                </div> */}
                                              </div>
                                            </div>
                                          </div>

                                          {/* <div class="compare-bx mt-4 mb-4">
                                                                                <span class="comere-icon-bx">
                                                                                    <i class="bi bi-arrow-down-up"></i>
                                                                                </span>
                                                                            </div> */}

                                          <button
                                            class={
                                              buySell == "buy"
                                                ? "btn col-lg-12 buyButton "
                                                : "btn col-lg-12 sellButton"
                                            }
                                            type="submit"
                                          >
                                            {buySell == "buy"
                                              ? "Trade"
                                              : "Trade"}
                                          </button>
                                        </div>
                                      </div>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                              {/* 
                                                            <div class=" about-content-bx top-know-price-bx">
                                                                <div class="know-commper-bx mt-5 mb-5">
                                                                    <div class="row">
                                                                        <div class="col-lg-6">
                                                                            <div class="know-commper-bx-min min-overview d-flex">
                                                                                <label for="formGroupExampleInput" class="form-label">Quantity</label>
                                                                                <span className='border-2'></span>
                                                                                <input type="text" class="form-control" id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="know-commper-bx-min min-overview d-flex">
                                                                                <label for="formGroupExampleInput" class="form-label">Quantity</label>
                                                                                <span className='border-2'></span>
                                                                                <input type="text" class="form-control" id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="compare-bx mt-4 mb-4">
                                                                    <span class="comere-icon-bx">
                                                                        <i class="bi bi-arrow-down-up"></i>
                                                                    </span>
                                                                </div>

                                                                <div class="know-commper-bx mt-5 mb-5">
                                                                    <div class="row">
                                                                        <div class="col-lg-8">
                                                                            <div class="know-commper-bx-min min-overview d-flex">
                                                                                <label for="formGroupExampleInput" class="form-label">Price</label>
                                                                                <input type="text" class="form-control" id="formGroupExampleInput" placeholder="6.544" />
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-4 pt-3">
                                                                            <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <button class="btn col-lg-12">Trade Now</button>


                                                            </div>
                                                            */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- ==== know more ===== --> */}
                    </div>
                    {/* OverVCiew Section End */}

                    {/* About Section stART */}
                    <div
                      class="tab-pane fade "
                      id="pills-about"
                      role="tabpanel"
                      aria-labelledby="pills-about-tab"
                    >
                      <div class="row">
                        <div class="lef_side_bar">
                          {/* <div class="col-ms-12 mt-1">
                                                        <div class="about-content-bx mt-1">
                                                            <h5>trading stocks ?</h5>

                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                                                        </div>



                                                    </div> */}

                          {/* <div class="col-lg-12">
                                                        <div class="about-content-bx mt-4 about-data-tag">
                                                            <ul>
                                                                <a href=""><li>BTC to INR</li></a>

                                                                <a href=""><li>ETH to INR</li></a>

                                                                <a href=""><li>DASH to INR</li></a>

                                                                <a href=""><li>DASH to INR</li></a>


                                                                <a href=""><li>BTC to INR</li></a>

                                                                <a href=""><li>ETH to INR</li></a>

                                                                <a href=""><li>DASH to INR</li></a>

                                                                <a href=""><li>DASH to INR</li></a>

                                                                <a href=""><li>BTC to INR</li></a>

                                                                <a href=""><li>ETH to INR</li></a>

                                                                <a href=""><li>DASH to INR</li></a>

                                                                <a href=""><li>DASH to INR</li></a>
                                                            </ul>
                                                        </div>
                                                    </div> */}

                          {/* compair section Compare with start */}
                          {/* <div class="col-lg-12">
                                                        <div class="about-content-bx mt-4 abouut-select-data">
                                                            <div class="row mb-3">
                                                                <label for="inputEmail3" class="col-sm-2 col-form-label">Compare with</label>
                                                                <div class="col-sm-6">
                                                                    <select class="form-select" type="select" id="autoSizingSelect" required="required">
                                                                        <option selected="">---Select---</option>
                                                                        <option value="1"> <img src="assets/image/1inch.png" class="img-fluid" />   One</option>
                                                                        <option value="2">Two</option>
                                                                        <option value="3">Three</option>
                                                                    </select>
                                                                </div>
                                                            </div>


                                                            <div class="table-responsive">
                                                                <div class="table-input text-center">
                                                                    <table class="table table-bordered mt-2  table-bordered ">

                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" class="text-center"></th>
                                                                                <th scope="col" class="text-center">Balance in CFD</th>
                                                                                <th scope="col" class="text-center">Balance in Hand</th>

                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr>
                                                                                <th class="col-sm-2 ">Price</th>
                                                                                <td class="col-sm-2 border-end about-table-dat1">₹6.5898</td>
                                                                                <td class="col-sm-2 about-table-dat2">7d Price Difference %</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <th class="col-sm-2">Price</th>
                                                                                <td class="col-sm-2 border-end about-table-dat1">0.29%</td>
                                                                                <td class="col-sm-2 about-table-dat2">7d Price Difference %</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <th class="col-sm-2">Price</th>
                                                                                <td class="col-sm-2 border-end about-table-dat1">5.86%</td>
                                                                                <td class="col-sm-2 about-table-dat2">7d Price Difference %</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <th class="col-sm-2">Price</th>
                                                                                <td class="col-sm-2 border-end about-table-dat1">₹83,495 Cr</td>
                                                                                <td class="col-sm-2 about-table-dat2">7d Price Difference %</td>
                                                                            </tr>


                                                                            <tr>
                                                                                <th class="col-sm-2">Price</th>
                                                                                <td class="col-sm-2 border-end about-table-dat1">₹1,848 Cr</td>
                                                                                <td class="col-sm-2 about-table-dat2">7d Price Difference %</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <th class="col-sm-2">Price</th>
                                                                                <td class="col-sm-2 border-end about-table-dat1">₹1,848 Cr</td>
                                                                                <td class="col-sm-2 about-table-dat2">7d Price Difference %</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div> */}

                          {/* compair section Compare with End */}

                          <div class="col-lg-12">
                            <div class="about-content-bx mt-4 abouut-select-data">
                              <h5>News Feed</h5>
                              <div class="row">
                                {newsList?.map((val, ind) => {
                                  const unixTimestamp =
                                    val?.providerPublishTime;
                                  const dateTime = moment.unix(unixTimestamp); // Convert Unix timestamp to a Moment object
                                  const timeAgo = dateTime.fromNow();
                                  //   const dateString = val.time_published;
                                  //   const n = moment(
                                  //     dateString,
                                  //     "YYYY-MM-DDTHH:mm:ss"
                                  //   ); // Adjust the date format
                                  //     const timeAgo = n.fromNow();
                                  const banner_image =
                                    val.thumbnail?.resolutions[0]?.url;
                                  // to={val.url}
                                  return (
                                    <Link to={val.link} target="_blank">
                                      <div class="col-sm-12 summary-news-div">
                                        <div class="new-bx">
                                          <div class="d-flex position-relative">
                                            <img
                                              src={banner_image}
                                              class="flex-shrink-0 h-127 img-fluid me-3 col-lg-2"
                                              alt="..."
                                            />
                                            <div>
                                              <h5 class="mt-0">{val?.title}</h5>
                                              <span>{timeAgo} .</span>
                                              <spn className="sroce-nm">
                                                {val?.publisher}
                                              </spn>
                                              {/* <p className='summary-news'>{val.summary}</p> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  );
                                })}

                                {/* <span className='showMore' onClick={handleShowMore}>Show More{!newsLoading && '...'}
                                                                    <h5 className='excel_msg_pending d-inline loading-main-dot'>
                                                                        {
                                                                            newsLoading &&
                                                                            <div className='loading-Dot-stock_news'>
                                                                                <div class="dot11">.</div>
                                                                                <div class="dot21">.</div>
                                                                                <div class="dot31">.</div>
                                                                            </div>
                                                                        }
                                                                    </h5></span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* About Section End */}

                    {/* Statistics Section stART */}
                    <div
                      class="tab-pane fade"
                      id="pills-contact"
                      role="tabpanel"
                      aria-labelledby="pills-contact-tab"
                    >
                      {/* <!-- ======== price -bx ======= --> */}
                      <div class="row">
                        <div class="lef_side_bar pricing-bx-data">
                          {/* <div  class="col-lg-8 mt-4">
                                                           <div class="dropdown">
                                                                      <button class="btn btn-secondary dropdown-toggle col-lg-10" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                       Crypto Returns Calculator
                                                                      </button>
                                                                      <ul class="dropdown-menu col-lg-10" aria-labelledby="dropdownMenuButton1">
                                                                           <div class="row">
                                                                                 <div class="col-lg-12">
                                                                                  <div class="min-data-drop-down">
                                                                                     <h5>Dogecoin</h5>


                                                                                      <p>bought on  <span> <input type="date" name="date"/>  </span>  </p>

                                                                                      <div class="drop_price ">
                                                                                           <input type="number" name="" class="form-control col-lg-6" placeholder="₹ 1000" max="10" min="0"/>
                                                                                      </div>

                                                                                  </div>


                                                                                 </div>  
                                                                           </div>
                                                                      </ul>
                                                                </div>

                                                           </div> */}

                          <div class="row mt-4">
                            {/* <!--  <div class="col-lg-6">
                                                                          <img src="assets/image/coin-banner-17244d36919a04f56e5932e67a9968d3 (1).png" class="img-fluid">
                                                                     </div> --> */}

                            <div class="col-lg-12">
                              <div class="price-data-mr">
                                <h6>Dogecoin Price Today</h6>

                                <div class="table-responsive">
                                  <div class="table-input ">
                                    <table class="table table-bordered mt-2 table-input-data">
                                      <thead>
                                        <tr>
                                          <th scope="col">Dogecoin Price</th>
                                          <th scope="col border-end-0">
                                            ₹6.65
                                          </th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        <tr>
                                          <th class="col-sm-2">Price Change</th>
                                          <td class="col-sm-2 border-end-0">
                                            <span class="price-data-mr-green">
                                              1.40%
                                            </span>
                                          </td>
                                        </tr>

                                        <tr>
                                          <th class="col-sm-2">Price Change</th>
                                          <td class="col-sm-2 border-end-0">
                                            4.85%
                                          </td>
                                        </tr>

                                        <tr>
                                          <th class="col-sm-2">Market Rank</th>
                                          <td class="col-sm-2 border-end-0">
                                            #8
                                          </td>
                                        </tr>

                                        <tr>
                                          <th class="col-sm-2">Market Cap</th>
                                          <td class="col-sm-2 border-end-0">
                                            ₹83,495 Cr.
                                          </td>
                                        </tr>

                                        <tr>
                                          <th class="col-sm-2">Trade Volume</th>
                                          <td class="col-sm-2 border-end-0">
                                            ₹1,848 Cr
                                          </td>
                                        </tr>

                                        <tr>
                                          <th class="col-sm-2">
                                            Circulating Supply
                                          </th>
                                          <td class="col-sm-2 border-end-0">
                                            13,928 Cr. DOGE
                                          </td>
                                        </tr>

                                        <tr>
                                          <th class="col-sm-2">
                                            Trading Activity
                                          </th>
                                          <td class="col-sm-2 border-end-0">
                                            {" "}
                                            <span class="price-data-mr-green">
                                              {" "}
                                              70% Buy
                                            </span>{" "}
                                            30% Sell
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- ======== price -bx ======= --> */}
                    </div>
                    {/* Statistics Section End */}

                    {/* Watch list Start */}
                    <div
                      class="tab-pane fade"
                      id="Watchlist_tab"
                      role="tabpanel"
                      aria-labelledby="pills-contact-tab"
                    >
                      {/* <!-- ======== price -bx ======= --> */}
                      <div class="row">
                        <div class="lef_side_bar pricing-bx-data">
                          <div class="col-sm-12">
                            <div class="postion-bx-min">
                              {/* <span class="frm-hd-t">Watchlist</span> */}
                              <div
                                class="postion-frm mt-1 table-input1-watchlist border-top-1 table-input1"
                                style={{ "border-top": "1px solid #cbd0dd;" }}
                              >
                                {/* <span class="padding-ds">Watchlist</span> */}
                                <div class="table-responsive text-center">
                                  <table class="table table-bordered mt-3 text-center">
                                    <thead>
                                      <tr>
                                        <th scope="col" className="color-grey">
                                          Date
                                        </th>
                                        <th scope="col" className="color-grey">
                                          Stock Name
                                        </th>
                                        <th scope="col" className="color-grey">
                                          Stock Code
                                        </th>

                                        <th scope="col" className="color-grey">
                                          Chart
                                        </th>
                                        <th scope="col" className="color-grey">
                                          Delete
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {watchList.map((val, ind) => {
                                        let aus = val.stockCode.split("_");
                                        // console.log("au aus", aus);
                                        // console.log("val", val.stockCode);
                                        return (
                                          <tr key={ind} className="white_text">
                                            <th scope="row">
                                              {moment(val.createdDate).format(
                                                "DD MMM YYYY"
                                              )}{" "}
                                            </th>
                                            <td
                                              className="cursore-pointer"
                                              onClick={() =>
                                                handleSelectCrypto(val)
                                              }
                                            >
                                              {val.stockName}
                                            </td>
                                            <td>{aus[0]}</td>
                                            <td>
                                              <button
                                                class="btn padding-btn-dtd open-btn w-100"
                                                title="Open"
                                                data-bs-toggle="modal"
                                                data-bs-target="#StockView"
                                                onClick={() =>
                                                  setTradeCode(aus[0])
                                                }
                                              >
                                                {" "}
                                                <img
                                                  src="assets/image/folder.png"
                                                  class="img-fluid"
                                                  alt=""
                                                />{" "}
                                              </button>{" "}
                                            </td>
                                            {/* <td><button class="btn padding-btn-dtd delete-btn w-100" title='Remove' onClick={() => handleRemoveWatchList(val.watchListId)}> <img src="assets/image/delete.png" class="img-fluid" alt='' /> </button> </td> */}
                                            <td>
                                              <button
                                                class="btn padding-btn-dtd delete-admin"
                                                title="Delete"
                                                onClick={() =>
                                                  handleRemoveWatchListConfirm(
                                                    val.watchListId
                                                  )
                                                }
                                              >
                                                {" "}
                                                <i class="bi bi-trash3-fill"></i>{" "}
                                              </button>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                  <Pagination
                                    currentPage={currentPageWatch}
                                    totalPages={totalPagesWatch}
                                    onPageChange={handlePageChangeWatch}
                                    setPagination={setPaginationWatch}
                                    pageNumber={pageNumberWatch}
                                    pageSize={pageSizeWatch}
                                    pagination={paginationWatch}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- ======== price -bx ======= --> */}
                    </div>
                    {/* WatchList end */}
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- ==data start ====  --> */}
          </div>
        </div>
      </div>
      <TradeModel tradeCode={tradeCode} />
      {/* <DeleteConfirmation deleteConfirm={deleteConfirm} type={typeMsg} /> */}
    </div>
  );
};

export default MarketOverview;
