import axios from 'axios';
import process from "process";
import NotificationToast from '../components/commonComponent/NotificationToast';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_BASE_URL
const API_KEY = process.env.REACT_APP_API_KEY
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,OPTIONS',
    'Authorization':API_KEY
}

export const createPortfoloio = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Trades/AddPortfolio`,
            mode: 'cors',
            headers,
            data:JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            // 
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}


export const editPortfoloio = async (data,id) => {
    // 
    try {
        const response = await axios({
            method: 'PUT',
            url: `${API_URL}/Trades/UpdatePortfolio?PortfolioId=${id}`,
            mode: 'cors',
            headers,
            data:JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}


export const getPortfoloio = async (id) => {
    // 
    try {
        const response = await axios({
            method: 'get',
            url: `${API_URL}/Trades/GetPortfolio?UserId=${id}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
        
    }
}

export const deletePortfoloio = async (data) => {
    //
    debugger
     console.log("dfsf",data)
    
    try {
        const response = await axios({
          method: "DELETE",
          url: `${API_URL}/Trades/DeletePortfolio?id=${data?.portfolioId}`,
          mode: "cors",
          headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}