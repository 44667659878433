import React, { useEffect, useRef } from "react";
import anychart from "anychart";

const BarChart3 = ({ data, id, currency }) => {
  const chartRef = useRef(null); // Reference for the chart instance

  useEffect(() => {
    if (!data || data.length === 0) {
      console.warn("No data provided for the chart");
      return;
    }

    // Check if the chart is already initialized
    if (chartRef.current) {
      // Update the chart with new data
      const dataSet = anychart.data.set(data);
      chartRef.current.removeAllSeries(); // Remove old series
      chartRef.current.bar(dataSet.mapAs({ x: 0, value: 1 })).name("Profit");
      chartRef.current.bar(dataSet.mapAs({ x: 0, value: 2 })).name("Loss");
      return;
    }

    // Initialize the chart
    const dataSet = anychart.data.set(data);

    // Create the chart
    const chart = anychart.bar();
    chart.animation(true);
    chart.padding([10, 40, 5, 20]);
    // chart.title("Top 10 Products by Revenue");
    chart.yScale().minimum(0);
    chart.yScale().stackMode("value");
    chart.yAxis().labels().format("{%Value}{groupsSeparator: }");
    chart.xAxis().title("Months");
    chart.yAxis().title("Profit/Loss Ratio");

    // Add series
    chart
      .bar(dataSet.mapAs({ x: 0, value: 1 }))
      .name("Profit")
      .color("#00df75");
    chart
      .bar(dataSet.mapAs({ x: 0, value: 2 }))
      .name("Loss")
      .color("#ff685c");

    // Enable legend and tooltips
    chart.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0]);
    chart.interactivity().hoverMode("by-x");
    chart.tooltip().format(function () {
      const profit = this.getData("value");
      const loss = 100 - this.getData("value");
      const ratio = `${profit}:${Number(loss.toFixed(2))}`;
      return `Profit: ${profit}\nLoss: ${Number(loss.toFixed(2))}\nRatio: ${ratio}`;
    });

    // Save the chart instance in the ref
    chartRef.current = chart;

    // Set container ID and draw the chart
    chart.container(id);
    chart.draw();

    // Cleanup function to dispose of the chart
    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
        chartRef.current = null;
      }
    };
  }, [data, id]);

  return <div id={id} style={{ width: "100%", height: "500px" }}></div>;
};

export default BarChart3;
