import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
	return (
		<>
			<footer>
				<div className="container">
					<div className="row">
						<div className="col-lg-9">
							<img src="assets/image/footer-logo.jpg" className="img-fluid" alt=''/>
							<p className="pt-4">@Copyright 2022 by tradinglounge.com</p>
						</div>
						<div className="col-lg-3 ms-auto d-block">
							<p className="pt-3">Find us on</p>
							<div className="social pt-2">
								<Link to="#"><i className="bi bi-facebook"></i></Link>
								<Link to="#"><i className="bi bi-twitter"></i></Link>
								<Link to="#"><i className="bi bi-linkedin"></i></Link>
								<Link to="#"><i className="bi bi-youtube"></i></Link>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer